import { useSelector } from "react-redux"
import { resetSelectedRow } from "../../store/actions/misc"
import { MuiDialog } from "../../utils/MuiDialog"
import MuiFormMap from "../../utils/MuiFormMap"
import { DialogTitle, styled } from "@mui/material"
import { useTranslation } from "react-i18next"
import { darkBlueGradient } from "../../gradients"

const CustomDialogTitle = styled(DialogTitle)({
  fontSize: "24px",
  fontWeight: "bold",
  color: "white",
  background: darkBlueGradient,
  padding: "16px",
  borderRadius: "4px",
  marginBottom: 16,
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
  fontFamily: "Poppins",
  overflowX: "hidden",
  transition: "transform 0.2s",
})
export function CrudTableDisplayRow({
  additionalData,
  columns,
  updateFn,
  createFn,
}) {
  const { selectedRow } = useSelector((state) => state.misc)
  const { t } = useTranslation()
  if (!selectedRow) return <></>
  const isEdit = Object.keys(selectedRow).length ? true : false

  return (
    <MuiDialog
      title={
        <CustomDialogTitle>
          {isEdit ? t("modifyRow") : t("createNewRow")}
        </CustomDialogTitle>
      }
      forceOpen={selectedRow ? true : false}
      reset={resetSelectedRow}
      Content={
        <MuiFormMap
          sm={12}
          formDataInit={selectedRow}
          editMode={isEdit}
          additionalData={!isEdit && additionalData}
          columns={columns}
          isPaper={true}
          onSubmit={async (data) => {
            if (isEdit) await updateFn(selectedRow._id, data)
            else await createFn(data)
            resetSelectedRow()
          }}
        />
      }
    />
  )
}
