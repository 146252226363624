import { store } from "../store"

export const establishmentSiteModel = () => {
  const { siteModel } = store?.getState()?.establishment.establishment

  return siteModel
}

export const checkEstablishmentSiteModel = (siteModelToCheck) => {
  const { siteModel } = store?.getState()?.establishment.establishment

  return siteModel === siteModelToCheck

  // return {
  //   clothing: true,
  //   restaurant: true,
  // }[siteModel]
}
