import { Avatar, Stack, Tooltip, useMediaQuery } from "@mui/material"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { supportedLanguages } from "utils/i18nUtilities"
import { changeLocales } from "../utils/i18nUtilities"

function LanguageSwitcher({ sx }) {
  const { i18n } = useTranslation()

  useEffect(() => {
    changeLocales(i18n.language)
  }, [])
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("sm"))
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      sx={{ width: "fit-content", margin: "auto", ...sx }}
    >
      {supportedLanguages.map((language) => (
        <Tooltip
          key={language.code}
          onClick={() => changeLocales(language.code)}
          className="custom-hover-10"
          style={{ cursor: "pointer" }}
          title={language.name}
        >
          <Avatar
            sx={{
              width: matchesXs ? 30 : 35,
              height: matchesXs ? 30 : 35, // Set smaller height for mobile
            }}
            src={language.flag}
            alt={language.name}
          />
        </Tooltip>
      ))}
    </Stack>
  )
}

export default LanguageSwitcher
