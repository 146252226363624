import { Unstable_NumberInput as BaseNumberInput } from "@mui/base/Unstable_NumberInput"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import {
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { styled } from "@mui/system"
import * as React from "react"
import { reduxFormDataHook, useProcessInputData } from "../reactCustomHooks"

const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
  return (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInput,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: <AddIcon fontSize="small" />,
          className: "increment",
        },
        decrementButton: {
          children: <RemoveIcon fontSize="small" />,
        },
      }}
      {...props}
      ref={ref}
    />
  )
})

export default function MuiNumberInput({ column }) {
  const { accessorKey, required, value, onChange, onChange2, t } =
    reduxFormDataHook(column)

  const handleDecrease = (e) => {
    if (value > 1) {
      onChange2( value - 1)
    }
  }

  const handleIncrease = (e) => {
    if (value < 100) {
      onChange2( value + 1)
    }
  }

  React.useEffect(() => {
    onChange2( 1)
  }, [])

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{ width: "100%" }}
    >
      <InputLabel>{t(accessorKey)}</InputLabel>

      <IconButton
        className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m"
        name={accessorKey}
        onClick={handleDecrease}
      >
        <RemoveIcon />
      </IconButton>
      {/* <Typography variant="body1">{value}</Typography> */}
      <TextField
        className="btn-num-product-down"
        // type="number"
        sx={{ width: 50 }}
        // onChange={onChange}
        name={accessorKey}
        value={value}
      />
      <IconButton
        className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m"
        name={accessorKey}
        onClick={handleIncrease}
      >
        <AddIcon />
      </IconButton>
    </Stack>
  )
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <InputLabel>{t(accessorKey)}</InputLabel>

      <NumberInput
        name={accessorKey}
        required={required}
        value={value}
        onChange={onChange}
        min={1}
        max={99}
      />
    </Stack>
  )
}

const blue = {
  100: "#daecff",
  200: "#b6daff",
  300: "#66b2ff",
  400: "#3399ff",
  500: "#007fff",
  600: "#0072e5",
  700: "#0059B2",
  800: "#004c99",
}

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
}

const StyledInputRoot = styled("div")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[500]};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`
)

const StyledInput = styled("input")(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.375;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.5)" : "rgba(0,0,0, 0.05)"
  };
  border-radius: 8px;
  margin: 0 8px;
  padding: 10px 12px;
  outline: 0;
  min-width: 0;
  width: 4rem;
  text-align: center;

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[700] : blue[200]
    };
  }

  &:focus-visible {
    outline: 0;
  }
`
)

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  line-height: 1.5;
  border: 1px solid;
  border-radius: 999px;
  border-color: ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
  background: ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
  color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
  width: 32px;
  height: 32px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    cursor: pointer;
    background: ${theme.palette.mode === "dark" ? blue[700] : blue[500]};
    border-color: ${theme.palette.mode === "dark" ? blue[500] : blue[400]};
    color: ${grey[50]};
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
`
)
