import { useTheme } from "@emotion/react"
import ChatIcon from "@mui/icons-material/Chat"
import CloseIcon from "@mui/icons-material/Close"
import RoomServiceIcon from "@mui/icons-material/RoomService"
import TableBarIcon from "@mui/icons-material/TableBar"
import {
  Avatar,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import dayjs from "dayjs"
import { useSelector } from "react-redux"
import { useAutoClosePopup } from "../../../../../../components/AutoClosePopup"
import { AvatarPath } from "../../../../../../components/ImagePath"
import { logoPath } from "../../../../../../config"
import {
  deleteOrderReducer,
  updateOrderClient,
} from "../../../../../../store/actions/order"
import Bill from "./Bill"
import PreparationStepper from "./PreparationStepper"
import TypographyTrad from "../../../../../../utils/TypographyTrad."
import { MuiDialog } from "../../../../../../utils/MuiDialog"
import ClaimForm from "./ClaimForm"

export function DialogRender({ order, handleOpen }) {
  const theme = useTheme()
  const queryParams = new URLSearchParams(location.search)
  const tableNumber = queryParams.get("tableNumber")

  const { establishment } = useSelector((state) => state.establishment)
  const autoClosePopup = useAutoClosePopup()

  const waiter = order.waiter

  var step
  switch (order.status) {
    case "waiting":
      step = 0
      break
    case "confirmed":
      step = 1
      break
    case "preparing":
      step = 2
      break
    case "served":
      step = 3
      break
    case "payed":
      step = 4
      break

    default:
      break
  }

  const handlePay = () => {
    updateOrderClient(order._id, { readyToPay: true })
  }

  if (order.status === "payed") {
    deleteOrderReducer()
    autoClosePopup({
      content: (
        <>
          <Typography variant="h4">Thank you</Typography>
          <Typography variant="h5">See you soon</Typography>
          {/* <Divider sx={{ my: 2 }} /> */}
          <div>
            <img
              style={{ margin: "auto" }}
              className="custom-hover-10"
              src={logoPath}
              alt="Smart MenuQR logo"
              width={250}
            />
          </div>
        </>
      ),
    })
    handleOpen()
  }

  // if (order.status === "payed")
  //   return (
  //     <Stack sx={{ p: 5, textAlign: "center" }} spacing={2}>
  //       <Typography variant="h4">Thank you</Typography>
  //       <Typography variant="h5">See you soon</Typography>
  //       <Divider sx={{ my: 2 }} />
  //       <img
  //         style={{ margin: "auto" }}
  //         className="custom-hover-10"
  //         src={logoPath}
  //         alt="Smart MenuQR logo"
  //         width={250}
  //       />
  //     </Stack>
  //   )

  return (
    <div>
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleOpen}
        style={{ position: "absolute", top: 10, right: 20 }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <AvatarPath sx={{ width: 75, height: 75 }} src={establishment.logo} />
          <h1 className="logo me-auto me-lg-0">
            <span style={{ color: "#cda45e" }}>{establishment.name}</span>
          </h1>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>{PayoutDetails()}</DialogContent>
      <Divider />
      <div>
        {VotreCommande()}
        <Divider />
        <Stack sx={{ my: 1 }} direction="row" justifyContent="space-around">
          {/* <a style={{ color: "#FFFFFF" }} href="#contact"> */}
          <Stack className="pointerCursor" direction="row" alignItems="center">
            <ChatIcon
              sx={{
                fontSize: 50,
                color: theme === "light" ? "black" : "#FFFFFF",
              }}
            />
            <MuiDialog title="claim" Content={ClaimForm}>
              <TypographyTrad>claim</TypographyTrad>
            </MuiDialog>
          </Stack>
          {/* </a> */}
          <Bill
            order={order}
            handlePay={handlePay}
            items={order?.cart}
            total={order?.price?.toFixed(3)}
          />
        </Stack>
      </div>
      {/* <DialogActions>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{ width: "100%", p: 2, px: 1 }}
                >
                  <OrangeButton onClick={handleCartClose} color="primary">
                    Continue Shopping
                  </OrangeButton>
                  {cart.length !== 0 && (
                    <OrangeButton onClick={handleCreateOrder} color="primary">
                      Checkout
                    </OrangeButton>
                  )}
                </Stack>
              </DialogActions> */}
    </div>
  )
  function PayoutDetails() {
    return (
      <Stack spacing={3}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <AvatarPath sx={{ width: 60, height: 60 }} src={waiter?.avatar} />
          <Typography>{waiter?.firstName + " " + waiter?.lastName}</Typography>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <Avatar
              sx={{
                width: 50,
                height: 50,
                color: "white",
                backgroundImage:
                  "linear-gradient( 95deg,#C2A068 0%,#866041 100%)",
              }}
            >
              <TableBarIcon />
            </Avatar>

            <Typography variant="body2">Table: {tableNumber} </Typography>
          </Stack>
          <Typography variant="body2">
            {dayjs().format("HH:mm DD/MM/YYYY")}
          </Typography>
        </Stack>
        {/* <Divider sx={{ my: 2 }} /> */}
        <PreparationStepper step={step} />
      </Stack>
    )
  }

  function VotreCommande() {
    return (
      <DialogContent>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%", mb: 2 }}
        >
          <Avatar
            sx={{
              color: "white",
              width: 60,
              height: 60,
              backgroundImage:
                "linear-gradient( 95deg,#C2A068 0%,#866041 100%)",
            }}
          >
            <RoomServiceIcon sx={{ width: 45, height: 45 }} />
          </Avatar>
          <TypographyTrad>yourOrder</TypographyTrad>
        </Stack>

        <Stack spacing={2}>
          {order?.cart?.map((product, key) => {
            const extraString = product?.extras
              .map((extra) => extra.name)
              .join(", ")
            return (
              <div key={key}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{ width: "100%" }}
                >
                  <Typography>{product.name}</Typography>
                  <Typography>X {product.quantity}</Typography>
                </Stack>
                <Typography>{extraString}</Typography>
              </div>
            )
          })}
        </Stack>
      </DialogContent>
    )
  }
}
