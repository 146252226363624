import * as React from "react"
import Box from "@mui/material/Box"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { useTranslation } from "react-i18next"
import { reduxFormDataHook } from "../reactCustomHooks"

export default function MuiSelect({ column }) {
  const { accessorKey, required, value, onChange, t, options, header } =
    reduxFormDataHook(column)

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel>{t(accessorKey)}</InputLabel>
        <Select
          required={required}
          value={value}
          label={header}
          name={accessorKey}
          onChange={(e) => onChange(e)}
        >
          {/* <MenuItem>
            {t("choose-an-option")}
            </MenuItem> */}
          {options?.map((option, key) => (
            <MenuItem key={key} value={option}>
              {t(option)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
