import PropTypes from "prop-types"
import { forwardRef, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// material-ui
import { useTheme } from "@mui/material/styles"
import {
  Avatar,
  Badge,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material"

// project import
import { activeItem } from "store/reducers/menu"
import { socket } from "../../../../../App"
import { useTranslation } from "react-i18next"
import { dashboardPath } from "../../../../../config"

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, level }) => {
  const {  t } = useTranslation()

  const theme = useTheme()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const { drawerOpen, openItem } = useSelector((state) => state.menu)
  const [reduxUpdate, setReduxUpdate] = useState(null)
  socket.on("redux-update-waiter", (dataToDispatch) => {
    const { type } = dataToDispatch
    if (type === "ADD_ORDER") setReduxUpdate("order")
  })

const removeBadge = () => { 
  setReduxUpdate(null)

}

  let itemTarget = "_self"
  if (item.target) {
    itemTarget = "_blank"
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={item.path} target={itemTarget} />
    )),
  }
  if (item?.external) {
    listItemProps = { component: "a", href: item.path, target: itemTarget }
  }

  const itemHandler = (path) => {
    removeBadge()
    dispatch(activeItem({ openItem: [path] }))
  }

  const Icon = item.icon
  const IconPng = item.iconPng
  var itemIcon = ""
  if (Icon)
    itemIcon = item.icon ? (
      <Icon style={{ fontSize: drawerOpen ? "1.1rem" : "1.25rem" }} />
    ) : (
      false
    )
  if (IconPng)
    itemIcon = item.iconPng ? (
      <img style={{ width: drawerOpen ? "1.1rem" : "1.25rem" }} src={IconPng} />
    ) : (
      false
    )

  const isSelected = openItem.findIndex((path) => path === item.path) > -1
  // active menu item on page load
  useEffect(() => {
    if (pathname.includes(item.url)) {
      dispatch(activeItem({ openItem: [item.path] }))
    }
    // eslint-disable-next-line
  }, [pathname])

  const textColor = "white"
  const iconSelectedColor = "white"
  // const iconSelectedColor = "primary.main"

  return (
    <ListItemButton
      {...listItemProps}
      className="custom-hover-10"

      disabled={item.disabled}
      onClick={() => itemHandler(item.path)}
      selected={isSelected}
      sx={{
        zIndex: 1201,
        pl: drawerOpen ? `${level * 28}px` : 1.5,
        py: !drawerOpen && level === 1 ? 1.25 : 1,
        ...(drawerOpen && {
          "&:hover": {
            bgcolor: "primary.700",
          },
          "&.Mui-selected": {
            bgcolor: "primary.700",
            borderRight: `2px solid ${theme.palette.primary.main}`,
            color: iconSelectedColor,
            "&:hover": {
              color: iconSelectedColor,
              bgcolor: "primary.700",
            },
          },
        }),
        ...(!drawerOpen && {
          "&:hover": {
            bgcolor: "transparent",
          },
          "&.Mui-selected": {
            "&:hover": {
              bgcolor: "transparent",
            },
            bgcolor: "transparent",
          },
        }),
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            marginRight: 1,
            minWidth: 28,
            color: isSelected ? iconSelectedColor : textColor,
            ...(!drawerOpen && {
              borderRadius: 1.5,
              width: 36,
              height: 36,
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                bgcolor: "secondary.lighter",
              },
            }),
            ...(!drawerOpen &&
              isSelected && {
                bgcolor: "primary.lighter",
                "&:hover": {
                  bgcolor: "primary.lighter",
                },
              }),
          }}

        >
          {itemIcon}
        </ListItemIcon>
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && (
        <ListItemText
          primary={
            <Typography
              variant="h6"
              sx={{ color: isSelected ? iconSelectedColor : textColor }}
            >
              {t(item.title)}
            </Typography>
          }
        />
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) &&
        item.chip &&
        reduxUpdate === item.path && (
        <Chip
          className="animated-element"
            color={item.chip.color}
            variant={item.chip.variant}
            size={item.chip.size}
            label={item.chip.label}
            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          />
        )}
    </ListItemButton>
  )
}

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
}

export default NavItem
