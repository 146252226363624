import { joinRoom, leaveRoom, socketListenerLayout } from ".."
import { dispatch } from "../../../store"
import { feedbackInfo, setOpenTrigger } from "../../../store/actions/misc"
import { deleteOrderReducer } from "../../../store/actions/order"
import { bellsNotification, notificationSound } from "../../howlerSounds"
import vibrateApi from "../../vibrateApi"

const modelsActionMap = {
  order: {
    waiter: {
      add: (payload) => {
        bellsNotification()
        vibrateApi()
        joinRoom(payload._id)
      },
      // update: (payload) => {},
      delete: (payload) => {
        leaveRoom(payload._id)
      },
    },
    client: {
      soundPlay: () => notificationSound(),
      // add: (payload) => {},
      // update: (payload) => {},
      delete: (payload) => {
        leaveRoom(payload)
        return deleteOrderReducer()
      },
    },
  },
  booking: {
    waiter: {
      add: (payload) => {
        // notificationSound()
      },
      update: (payload) => {},
      delete: (payload) => {},
    },
    client: {
      add: (payload) => {},
      update: (payload) => {},
      delete: (payload) => {},
    },
  },
}

export const reduxUpdateWaiter = () => {
  socketListenerLayout({
    listenerName: "redux-update-waiter",
    fn: (dataToDispatch) => {
      const { type, payload, operationType, model } =
        dataExtraction(dataToDispatch)

      modelsActionMap[model].waiter[operationType]
      console.log(modelsActionMap[model].waiter[operationType])

      dispatch({ type, payload })
    },
  })
}

export const reduxClientOrder = (clientOrder) => {
  socketListenerLayout({
    condition: clientOrder,
    listenerName: "redux-update-client",
    fn: (dataToDispatch) => {
      const { type, payload, operationType, model } =
        dataExtraction(dataToDispatch)

      setOpenTrigger(payload)
      modelsActionMap[model].client.soundPlay()
      modelsActionMap[model].client[operationType]

      dispatch({ type, payload })
    },
  })
}
function dataExtraction(dataToDispatch) {
  console.log(dataToDispatch)
  const { type, payload } = dataToDispatch
  var [operationType, model] = type.split("_").map((str) => str.toLowerCase())
  feedback(operationType, model)

  return {
    type,
    payload,
    operationType,
    model,
  }
}
function feedback(operationType, model) {
  feedbackInfo(`${model}| |${operationType}Action`)
}
