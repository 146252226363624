import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import { Avatar, ButtonBase } from "@mui/material"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/system" // Import the styled function
import { deleteCart, updateCart } from "../../../../../../store/actions/cart"
import { AvatarPath } from "../../../../../../components/ImagePath"
import { moneyCurrency } from "../../../../../../config"
import { useTranslation } from "react-i18next"

const ProductImage = styled(ButtonBase)({
  width: 128,
  height: 128,
  borderRadius: "50%",
  objectFit: "cover",
})

const ProductInfo = styled(Grid)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))

const QuantityContainer = styled(Grid)(({ theme }) => ({
  marginLeft: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const CartItem = (props) => {
  const {
    name,
    price,
    images,
    extras = [],
    quantity = 1,
    productInCartId,
  } = props
  const { t } = useTranslation()

  const handleDecrease = () => {
    // if (quantity === 1) return deleteCart(productInCartId)

    updateCart({
      ...props,
      quantity: quantity - 1,
    })
  }

  const handleIncrease = () => {
    updateCart({
      ...props,
      quantity: quantity + 1,
    })
  }

  const extrasNames = extras?.map((extra) => extra.name).join(", ")
  const extrasPrice = extras
    ? extras.reduce((acc, cur) => acc + cur.price, 0)
    : 0
  const totalPrice = (price + extrasPrice) * quantity

  return (
    <Paper elevation={3}>
      <Grid sx={{ p: 2 }} container spacing={2}>
        <Grid style={{ paddingTop: 0 }} item>
          <ProductImage>
            <AvatarPath
              sx={{ width: "100%", height: "100%" }}
              width={"100%"}
              src={images}
              alt={name}
            />
          </ProductImage>
        </Grid>
        <ProductInfo style={{ paddingTop: 0 }} item>
          <Typography variant="h6">{name}</Typography>
          {extrasNames && (
            <Typography  variant="body2">
              {t('extras')}: {extrasNames}
            </Typography>
          )}
          <Typography  sx={{mt :1}}  variant="subtitle1">
            {t('total-price')}: {totalPrice + moneyCurrency}
          </Typography>
        </ProductInfo>
        <QuantityContainer style={{ paddingTop: 0 }} item>
          <IconButton aria-label="reduce" onClick={handleDecrease}>
            <RemoveIcon />
          </IconButton>
          <Typography variant="body1">{quantity}</Typography>
          <IconButton aria-label="increase" onClick={handleIncrease}>
            <AddIcon />
          </IconButton>
        </QuantityContainer>
      </Grid>
    </Paper>
  )
}

export default CartItem
