import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { feedbackError } from "../../store/actions/misc"

const activateWarning = true

const useOnlineStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  const updateOnlineStatus = () => {
    setIsOnline(navigator.onLine)
  }

  useEffect(() => {
    window.addEventListener("online", updateOnlineStatus)
    window.addEventListener("offline", updateOnlineStatus)

    return () => {
      window.removeEventListener("online", updateOnlineStatus)
      window.removeEventListener("offline", updateOnlineStatus)
    }
  }, [])

  return isOnline
}

export default useOnlineStatus

export const noInternetFeedback = () => {
  const isOnline = useOnlineStatus()

  useEffect(() => {
    const checkInternetConnection = () => {
      if (!isOnline && activateWarning) {
        feedbackError("please-check-your-internet-connection")
      }
    }

    // Initial check
    checkInternetConnection()

    // Set up interval to periodically check internet connection
    const intervalId = setInterval(checkInternetConnection, 5000) // Adjust the interval time as needed (e.g., every 5 seconds)

    // Clear interval on component unmount
    return () => clearInterval(intervalId)
  }, [isOnline])
}
