import { Divider, Typography } from "@mui/material"
import dayjs from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
// const lastUpdatedDate = "03/09/2023"
// const companyName = "Smart MenuQR"
// const yourContactInformation = "smart.menuqr@gmail.com"
// const yourJuridiction = "TODO"
const RIB = "123412341234"
function TermsAndConditions() {

  const { authData } = useSelector((state) => state.auth)
  const { establishment } = useSelector((state) => state.establishment)

  const { t } = useTranslation()
  return (
    <div>
      <div
        // style={{ textAlign: 'left' }}
      >
        <Typography variant="h4" gutterBottom>
          {t('web-application-menu-qr-sales-agreement-for-restaurant')} </Typography>

        <Typography variant="body1">
          {t('this-contract-is-concluded-on')} {dayjs(establishment?.createdAt).format("DD/MM/YYYY")}{t('between-smart-waves-technologies-hereinafter-referred-to-as-denguezli-iskander-and')} {establishment?.name}{t('hereinafter-referred-to-as')} "{authData.firstName + " " + authData.lastName}".
        </Typography>

        <Divider />
        <br />

        <Typography variant="body1">
          {t('1-object-of-the-sale')} </Typography>
        <Typography variant="body2" paragraph>
          {t('the-seller-transfers-to-the-buyer-the-rights-and-ownership-of-the-web-application-for-a-qr-menu-specifically-designed-for-restaurants-including-the-source-code-documentation-and-all-associated-elements')} </Typography>

        <Typography variant="body1">
          {t('2-price-and-payment')} </Typography>
        <Typography variant="body2" paragraph>
          {t('the-buyer-agrees-to-pay-the-seller-the-amount-of-1500dt-and-every-year-100dt-as-a-subscription-fee-to-be-sent-to-the-companys-account-with-the-rib-number')}{RIB} </Typography>

        <Typography variant="body1">
          {t('3-transfer-of-ownership')} </Typography>
        <Typography variant="body2" paragraph>
          {t('the-transfer-of-ownership-of-the-web-application-for-the-qr-menu-will-be-effective-upon-receipt-of-full-payment-by-the-seller')} </Typography>

        <Typography variant="body1">
          {t('4-warranties')} </Typography>
        <Typography variant="body2" paragraph>
          {t('the-seller-guarantees-that-the-web-application-for-the-qr-menu-operates-in-accordance-with-the-provided-documentation-all-future-updates-or-improvements-are-not-included-in-this-warranty')} </Typography>

        <Typography variant="body1">
          {t('5-post-sale-responsibilities')} </Typography>
        <Typography variant="body2" paragraph>
          {t('the-seller-assumes-no-responsibility-for-any-damage-or-loss-occurring-after-the-transfer-of-ownership-the-buyer-is-responsible-for-the-maintenance-and-management-of-the-application')} </Typography>

        <Typography variant="body1">
          {t('6-confidentiality')} </Typography>
        <Typography variant="body2" paragraph>
          {t('the-parties-agree-to-maintain-the-confidentiality-of-all-confidential-information-related-to-this-transaction')} </Typography>
      </div>
      {/* CONTRAT DE VENTE D'APPLICATION WEB DE MENU QR POUR RESTAURANT Ce contrat est conclu le [{dayjs(establishment?.createdAt).format("DD/MM/YYYY")}], entre [Smart Waves Technologies], ci-après dénommé le "Iskander Denguezli ", et [{establishment?.name}], ci-après dénommé le "{authData.firstName + " " + authData.lastName}".<br />

      1. Objet de la Vente Le Vendeur cède à l'Acheteur les droits et la propriété de l'application web de menu QR spécifiquement conçue pour les restaurants, comprenant le code source, la documentation et tous les éléments associés.<br /> 2. Prix et Paiement L'Acheteur s'engage à payer au Vendeur la somme de[1500DT] Et chaque année[100DT] prix d'abonnement en envoyer au compte de société numéro de RIB [1324857865745647987878778] <br />3. Transfert de Propriété Le transfert de propriété de l'application web de menu QR sera effectif à la réception du paiement intégral par le Vendeur. <br />4. Garanties Le Vendeur garantit que l'application web de menu QR fonctionne conformément à la documentation fournie. Toutes les mises à jour ou améliorations futures ne sont pas incluses dans cette garantie. <br />5. Responsabilités Post-Vente Le Vendeur n'assume aucune responsabilité pour tout dommage ou perte survenant après le transfert de propriété.L'Acheteur est responsable de l'entretien et de la gestion de l'application.<br /> 6. Confidentialité Les parties s'engagent à maintenir la confidentialité de toutes les informations confidentielles liées à cette transaction. */}
      {/* <Typography variant="body2" paragraph>
        {t("lastUpdatedLabel")} {lastUpdatedDate}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("welcomeLabel", { companyName })}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("termsIntro", { companyName })}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {t("section1Title")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("section1Content")}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {t("section2Title")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("section2Content")}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {t("section3Title")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("section3Content")}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {t("section4Title")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("section4Content")}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {t("section5Title")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("section5Content")}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {t("section6Title")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("section6Content")}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {t("section7Title")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("section7Content")}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {t("section8Title")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("section8Content")}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {t("section9Title")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("section9Content")}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {t("section10Title")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("section10Content", { yourJuridiction })}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {t("section11Title")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("section11Content", { yourContactInformation })}
      </Typography>
      <Typography variant="body1">
        {t("agreementLabel", { companyName })}
      </Typography> */}
    </div>
  )
}

export default TermsAndConditions
