import * as actionType from "../actionTypes"
export default (
  state = {
    claims: [],
  },
  action
) => {
  const payload = action.payload
  switch (action.type) {

    case actionType.SET_CLAIMS:
      return { ...state, claims: payload }

    case actionType.ADD_CLAIM:
      return {
        ...state,
        claims: [payload,...state.claims],
      }

    case actionType.UPDATE_CLAIM:
      return {
        ...state,
        claims: state.claims.map((document) =>
         document._id === payload._id ? payload : document
        ),
      }

    case actionType.DELETE_CLAIM:
      return {
        ...state,
        claims: state.claims.filter(
          (document) => document._id !== payload
        ),
      }

    default:
      return state
  }
}
