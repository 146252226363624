import { Card, CardContent, styled } from "@mui/material"

import NumericValueIcon from "@mui/icons-material/Functions"

const StyledCard = styled(Card)({
  // marginBottom: "16px",
  padding: "10px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.2s",
  "&:hover": {
    transform: "scale(1.02)",
  },
})


function CustomCard({ children, type }) {
  return (
    <StyledCard>
      {/* <CardContent sx={{ p  : 1}}> */}
          {children}
      {/* </CardContent> */}
    </StyledCard>
  )
}

export default CustomCard
