import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
dayjs.extend(duration)

export const calculateTimeRemaining = (startDate, endDate) => {
  if (!startDate || !endDate) return null

  const timeRemaining = endDate.diff(startDate)
  const dateDuration = dayjs.duration(timeRemaining)
  return {
    years: dateDuration.years(),
    months: dateDuration.months(),
    days: dateDuration.days(),
  }
}
