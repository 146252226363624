import * as actionType from "../actionTypes"
export default (
  state = {
    notifications: [],
  },
  action
) => {
  const payload = action.payload
  switch (action.type) {
    case actionType.SET_NOTIFICATIONS:
      return { ...state, notifications: payload }

    case actionType.ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [payload, ...state.notifications],
      }

    case actionType.UPDATE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map((document) =>
          document._id === payload._id ? payload : document
        ),
      }

    case actionType.DELETE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (document) => document._id !== payload
        ),
      }
    case actionType.DELETE_ALL_NOTIFICATION:
      return {
        ...state,
        notifications: [],
      }

    default:
      return state
  }
}
