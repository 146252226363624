import PropTypes from "prop-types"

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { useTheme } from "@mui/material/styles"

// assets
import {
  EditOutlined,
  LogoutOutlined
} from "@ant-design/icons"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { activeItem } from "../../../../../store/reducers/menu"

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ handleLogout }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const itemHandler = (id) => {
    dispatch(activeItem({ openItem: [id] }))
  }

  return (
    <List
      component="nav"
      sx={{
        p: 0,
        "& .MuiListItemIcon-root": {
          minWidth: 32,
          color: theme.palette.grey[500],
        },
      }}
    >
      <ListItemButton
        onClick={(event) => {
          navigate("/dashboard/profile")
          itemHandler("myProfile")
        }}
      >
        <ListItemIcon>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText primary="Modifier le profil" />
      </ListItemButton>
      {/* <ListItemButton selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)}>
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary="Voir le profil" />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3)}>
        <ListItemIcon>
          <ProfileOutlined />
        </ListItemIcon>
        <ListItemText primary="Profil social" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 4} onClick={(event) => handleListItemClick(event, 4)}>
        <ListItemIcon>
          <WalletOutlined />
        </ListItemIcon>
        <ListItemText primary="Facturation" />
      </ListItemButton> */}
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Déconnexion" />
      </ListItemButton>
    </List>
  )
}

ProfileTab.propTypes = {
  handleLogout: PropTypes.func,
}

export default ProfileTab
