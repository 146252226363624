import { Card, Grid, Paper, Typography } from "@mui/material"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { moneyCurrency } from "../../../config"
import { resetDisplayDataFormMap } from "../../../store/reducers/misc2"
import { MuiDialog } from "../../../utils/MuiDialog"
import MuiStepper from "../../../utils/MuiStepper"
import CarouselGallery from "../../../utils/carouselSwiper/CarouselGallery"
import { formatPhoneNumberWithSpaces } from "../../../utils/formatText"

const cardStyle = { p: 2, bgcolor: "lightgray" }

function OrderDetails() {
  const { displayDataFormMap } = useSelector((state) => state.misc2)
  const {
    cart = [],
    price,
    buyerData,
    number,
    status,
  } = displayDataFormMap || {}

  const { t } = useTranslation()
  return (
    <div>
      <MuiDialog
        title={`${t("purchaseSummary")}: ${cart.length} ${t("product")}`}
        Content={
          <Paper>
            <Grid container spacing={2}>
              {/* Select Product */}
              {cart.map((product, key) => {
                const { name, images, color, size, description, category } =
                  product
                return (
                  <Fragment key={key}>
                    {/* Image */}
                    <Grid item xs={6}>
                      <div style={{ padding: "16px" }}>
                        <CarouselGallery images={[images[0]]} />
                      </div>
                    </Grid>
                    {/* Info */}
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {price?.toFixed(3)} {moneyCurrency}
                      </Typography>
                      <Typography variant="h4" gutterBottom>
                        {name}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {t("color")}: {t(color)}
                      </Typography>
                    </Grid>
                  </Fragment>
                )
              })}

              {/* Total */}
              <Grid item xs={12}>
                <Card sx={cardStyle}>
                  <strong>{t("totalProducts")}</strong>: {price} {moneyCurrency}{" "}
                  <br />
                  {/* {t("shipping")}: {price} <br/> */}
                </Card>
              </Grid>
              {/* Address */}
              <Grid item xs={12}>
                <div style={{ padding: "16px" }}>
                  <Typography variant="subtitle1" gutterBottom>
                    {t("billingAddress")}:
                  </Typography>
                  {buyerData.firstName} {buyerData.lastName} <br />
                  {buyerData.postalCode} {buyerData.address} <br />
                  {formatPhoneNumberWithSpaces(buyerData.phoneNumber)} <br />
                  {buyerData.email} <br />
                </div>
              </Grid>
              {/* Delivery */}
              <Grid item xs={12}>
                <Card sx={cardStyle}>
                  <strong>{t("orderNumber")}</strong>: {number} <br />
                  <strong>{t("shippingMethod")}</strong>:{" "}
                  {t(buyerData.paymentMethod)} <br />
                </Card>
              </Grid>
              {/* Steps */}
              <Grid item xs={12}>
                <div style={{ padding: "16px" }}>
                  <Typography variant="subtitle1" gutterBottom>
                    {t("deliveryStatus")}:
                  </Typography>
                  <MuiStepper
                    active={[
                      "waiting",
                      "confirmed",
                      "delivering",
                      "delivered",
                    ].indexOf(status)}
                    steps={["waiting", "confirmed", "delivering", "delivered"]}
                  />
                </div>
              </Grid>
            </Grid>
          </Paper>
        }
        forceOpen={displayDataFormMap ? true : false}
        maxWidth={"xs"}
        reset={resetDisplayDataFormMap}
      ></MuiDialog>
    </div>
  )
}

export default OrderDetails
