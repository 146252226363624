import React from "react"
import { Grid, Stack } from "@mui/material"
import { setDisplayDataFormMap } from "../store/reducers/misc2"
import CustomButton from "../components/Buttons/CustomButton"
import TypographyTrad from "./TypographyTrad."

export function ActionBar({ displayDataFormMap, actions }) {
  if (displayDataFormMap && actions)
    return (
      <div>
        <hr
          style={{
            margin: 0,
          }}
        />
        {/* <Grid container spacing={2}> */}
        {/* <Grid item xs={6} sm={4} md={3}>
            <TypographyTrad sx={{ textAlign: "center" }} variant="h3">
              action| :
            </TypographyTrad>
          </Grid> */}
        <Stack direction="row" justifyContent={"space-between"} useFlexGap flexWrap={"wrap"} sx={{ p: 1 }}>
          {actions.map(
            (action, key) =>
              action?.condition(displayDataFormMap) && (
                // <Grid key={key} item xs={6} sm={4} md={3}>
                <CustomButton
                  key={key}
                  onClick={() => {
                    action.actionFn(displayDataFormMap)
                    setDisplayDataFormMap(null)
                  }}
                  bgcolor={action?.color()}
                  icon={action?.Icon() ? <action.Icon /> : null}
                  variant="contained"
                >
                  {action.title()}
                </CustomButton>
                // </Grid>
              )
          )}
        </Stack>
        {/* </Grid> */}
      </div>
    )
}
