import PropTypes from "prop-types"

// material-ui
import { useTheme } from "@mui/material/styles"
import { Stack, Chip, Typography, Divider } from "@mui/material"

// project import
import DrawerHeaderStyled from "./DrawerHeaderStyled"
import Logo from "components/Logo"
import { useSelector } from "react-redux"

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme()
  const { establishment } = useSelector((state) => state.establishment)

  return (
    // only available in paid version
    <div>
      <DrawerHeaderStyled theme={theme} open={open}>
        <Stack
          // direction="row"
          // spacing={1}
          alignItems="center"
          justifyContent="center"
          sx={{ p: 0 }}
        >
          <Logo />
          {/* <Typography variant="h4" sx={{ fontFamily: 'Poppins' }}>
           Smart <br/> MenuQR
          </Typography> */}
          {/* <Chip
            label={"Smart MenuQR"}
            label={process.env.REACT_APP_VERSION}
            size="small"
            sx={{ height: 16, '& .MuiChip-label': { fontSize: '0.625rem', py: 0.25 } }}
            component="a"
            href="https://github.com/codedthemes/mantis-free-react-admin-template"
            target="_blank"
            clickable
          /> */}
        </Stack>
      </DrawerHeaderStyled>
      <Divider sx={{borderColor : "gray"}} ></Divider>
    </div>
  )
}

DrawerHeader.propTypes = {
  open: PropTypes.bool,
}

export default DrawerHeader
