import DarkModeIcon from "@mui/icons-material/DarkMode"
import LightModeIcon from "@mui/icons-material/LightMode"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import Brightness4Icon from "@mui/icons-material/Brightness4"
import Brightness7Icon from "@mui/icons-material/Brightness7"
import {
  setFetchMethod,
  enable as enableDarkMode,
  disable as disableDarkMode,
  auto as followSystemColorScheme,
  exportGeneratedCSS as collectCSS,
  isEnabled as isDarkReaderEnabled,
} from "darkreader"
import { IconButton } from "@mui/material"
setFetchMethod(window.fetch)

export default function ToggleThemeButtons({ variant = "rectangleDesign" }) {
  const { t } = useTranslation()
  const { themeMode } = useSelector((state) => state.misc)
const dispatch = useDispatch()
  const [mode, setMode] = useState(themeMode)

  const handleToggle = (event, data) => {
    setMode(data)

    // if (data === "dark") {
    //   enableDarkMode()
    // } else disableDarkMode()

    dispatch({ type: "SET_THEME_MODE", payload: data })
    localStorage.setItem("themeMode", data)
  }

  const models = {
    IconToggle: (
      <IconButton
        sx={{ ml: 1 }}
        onClick={(e) =>
          handleToggle(e, mode === "light" ? "dark" : "light")
        }
        color="inherit"
      >
        {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    ),
    rectangleDesign: (
      <ToggleButtonGroup
        value={mode}
        exclusive
        onChange={handleToggle}
        sx={{
          m: "auto",
          display: "block",
          width: "fit-content",
          bgcolor: "white",
        }}
      >
        <ToggleButton
          color="primary"
          value="light"
          aria-label="left aligned"
          size="small"
        >
          <LightModeIcon sx={{ fontSize: 18, mr: 0.5 }} />
          {t("light")}
        </ToggleButton>
        <ToggleButton
          color="primary"
          value="dark"
          aria-label="centered"
          size="small"
        >
          <DarkModeIcon sx={{ fontSize: 18, mr: 0.5 }} /> {t("dark")}
        </ToggleButton>
      </ToggleButtonGroup>
    ),
  }

  return models[variant]
}
