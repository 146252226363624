import { Box, ThemeProvider, createTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import * as React from "react";

const darkTheme = createTheme({
  palette: {
    mode: 'dark', // Set the theme mode to dark
  },
});

export default function MuiDrawer({ drawerProps }) {

  return (
    <ThemeProvider theme={darkTheme}>
      <Drawer
        // anchor={"right"}
        open={true}
        // onClose={toggleDrawer(anchor, false)}
        // hideBackdrop
        PaperProps={{
          sx: { width: "20vw" },
        }}
        variant="persistent"
        // {...drawerProps}
      >
        <Box sx={{ width: "100%" }}>
          azeaaaaaaaaaaaaaaaaaaaaaaaaaazzzzzzzzzzzzzzzzzzzeze
        </Box>
        {/* {list(anchor)} */}
      </Drawer>
    </ThemeProvider>
  );
}
