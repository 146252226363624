import React, { useState, useEffect } from "react"

import { calculateTimeRemaining } from "./calculateTimeRemaining"
import { useTranslation } from "react-i18next"

const CountdownTimer = ({ startDate, endDate }) => {
  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeRemaining(startDate, endDate)
  )

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setTimeRemaining(calculateTimeRemaining(targetDate))
  //   }, 1000)

  //   return () => clearInterval(intervalId)
  // }, [])

  if (!timeRemaining) return <></>
  const { t } = useTranslation()
  const { years, months, days } = timeRemaining
  const yearsString = years ? years + " " + t("years") : ""
  return (
    <div>
      {t("in")}{" "}
      {`${yearsString} ${months} ${t("months")}, ${days} ${t("days")}`}
    </div>
  )
}

export default CountdownTimer
