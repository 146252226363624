import AddIcon from "@mui/icons-material/Add"
import CancelIcon from "@mui/icons-material/Cancel"
import CheckIcon from "@mui/icons-material/Check"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import LoadingButton from "@mui/lab/LoadingButton" // Import LoadingButton from Material-UI
import { styled } from "@mui/material/styles"
import { cloneElement } from "react"
import { useTranslation } from "react-i18next"
import {
  blueGradient,
  greenGradient,
  orangeGradient,
  purpleGradient,
  purpleRedGradient,
  redGradient,
} from "gradients"
import { darkBlue } from "colors"
import { Button, Stack, Tooltip } from "@mui/material"
import TypographyTrad from "../../utils/TypographyTrad."
import { tradSentence } from "../../utils/tradSentence"
import { RestartAltIcon } from "../../utils/MuiIcons"
import { Link } from "react-router-dom"

const iconMap = {
  add: <AddIcon />,
  edit: <EditIcon />,
  confirm: <CheckIcon />,
  cancel: <CancelIcon />,
  submit: <CheckIcon />,
  delete: <DeleteIcon />,
  reset: <RestartAltIcon />,
}

const CustomButton = (props) => {
  const {
    left,
    right,
    center,
    forceIcon,
    icon,
    disabled,
    helperText,
    color,
    sx,
    style,
    to,
    ...rest
  } = props

  var text = props.text || props.children

  if (Array.isArray(text)) text = text.join(" ")
  const lowerText = text?.toLowerCase()
  const startIcon = iconMap[forceIcon || lowerText] || icon

  const { t } = useTranslation()
  let custom = {}
  if (left)
    custom.style = { ...props.style, marginLeft: "auto", display: "block" }
  if (right)
    custom.style = { ...props.style, marginRight: "auto", display: "block" }
  if (center)
    custom.style = { ...props.style, margin: "0px auto", display: "block" }

  const gradients = {
    blue: blueGradient,
    green: greenGradient,
    red: redGradient,
    purple: purpleGradient,
    orange: orangeGradient,
    darkBlue: darkBlue,
  }

  const gradient = gradients[props.bgcolor] || gradients.blue
  const customButtonStyles = {
    borderRadius: 20,
    border: 0,
    color: "white",
    height: 48,
    padding: "0px 30px",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .15)",
    transition: "all 0.3s ease-in-out",
    // background: gradient,
    // backgroundColor: gradient,
    "&:hover": {
      // background: gradient,
      // backgroundColor: gradient,
      transform: "scale(1.02)",
      boxShadow: "0 5px 10px rgba(0, 0, 0, .3)",
      filter: "brightness(115%)",
    },
    "&.Mui-disabled": {
      filter: "grayscale(0.5)",
    },
  }

  const Container = to ? Link : "span"
  return (
    <Tooltip title={t(helperText) || ""}>
      <Container to={to}>
        <Button
          sx={{
            margin: 1,
            ...customButtonStyles,
            ...(!color && {
              background: gradient,
              backgroundColor: gradient,
            }),

            ...sx,
          }}
          color={color}
          disabled={disabled}
          {...rest}
          {...custom}
          style={style}
        >
          <Stack direction={"row"}>
            {startIcon && cloneElement(startIcon, { sx: { marginRight: 0.5 } })}
            {tradSentence(text)}
          </Stack>
        </Button>
      </Container>
    </Tooltip>
  )
}

export default CustomButton
