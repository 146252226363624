// import * as actionType from "../actionTypes"
// export default (
//   state = {
//     establishment: null,
//   },
//   action
// ) => {
//   const payload = action.payload
//   switch (action.type) {
//     case actionType.SET_ESTABLISHMENT:
//       return { ...state, establishment: payload }

//     default:
//       return state
//   }
// }

import { createSlice } from "@reduxjs/toolkit"
import { axiosAPI } from "../axiosFunctions"
import { dispatch, store } from ".."

const initialState = {
  establishment:
    JSON.parse(localStorage.getItem("cachedEstablishment")) || null,
  establishments: [],
}

const establishmentSlice = createSlice({
  name: "establishment",
  initialState,
  reducers: {
    setEstablishmentReducer(state, action) {
      state.establishment = action.payload
    },
    setEstablishmentsReducer(state, action) {
      state.establishments = action.payload
    },

    addEstablishmentReducer(state, action) {
      state.establishments.unshift(action.payload)
    },

    updateEstablishmentReducer(state, action) {
      state.establishments = state.establishments.map((document) =>
        document._id === action.payload._id ? action.payload : document
      )
    },

    deleteEstablishmentReducer(state, action) {
      state.establishments = state.establishments.filter(
        (document) => document._id !== action.payload
      )
    },
  },
})

const {
  setEstablishmentReducer,
  setEstablishmentsReducer,
  addEstablishmentReducer,
  updateEstablishmentReducer,
  deleteEstablishmentReducer,
} = establishmentSlice.actions
// 21692640172
// <a href="https://api.whatsapp.com/send?phone=21692640172">Send Message</a>
//https://api.whatsapp.com/send?phone=21692640172&text=Send20%a20%quote

//https://wa.me/21692640172?text=Send20%a20%quote
export const setEstablishment = (id) => {
  const authData = store.getState().auth?.authData
  id = id || authData?.establishment
  return axiosAPI.get("/establishment/" + id).then((data) => {
    localStorage.setItem("cachedEstablishment", JSON.stringify(data))
    dispatch(setEstablishmentReducer(data))
  })
}

export const setEstablishments = (query) =>
  axiosAPI
    .post("/establishment/fetchEstablishmentQuery", { query })
    .then((data) => dispatch(setEstablishmentsReducer(data)))

export const addEstablishment = (body) =>
  axiosAPI
    .post("/establishment/", body)
    .then((data) => dispatch(addEstablishmentReducer(data)))
//.catch((e) => Promise.reject(e))

export const updateEstablishment = (id, body) =>
  axiosAPI.patch(`/establishment/${id}`, body).then((data) => {
    dispatch(setEstablishmentReducer(data))
    dispatch(updateEstablishmentReducer(data))
  })
//.catch((e) => Promise.reject(e))
export const updateEstablishmentSupervisor = (id, body) =>
  axiosAPI
    .patch(`/establishment/updateEstablishmentSupervisor/${id}`, body)
    .then((data) => dispatch(updateEstablishmentReducer(data)))
// .catch((e) => Promise.reject(e))

export const deleteEstablishment = (id) =>
  axiosAPI
    .delete(`/establishment/${id}`)
    .then((_) => dispatch(deleteEstablishmentReducer(id)))

export default establishmentSlice.reducer
