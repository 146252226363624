import jwtDecode from "jwt-decode"
import { dispatch, store } from "../store"
import { feedbackInfo } from "../store/actions/misc"

export function checkTokenExpiration() {
  const { authData } = store.getState().auth
  if (!authData?.accessToken) return false

  const decodedToken = jwtDecode(authData.accessToken)
  const currentTime = new Date().getTime()
  const tokenExpirationTime = decodedToken?.exp * 1000

  if (tokenExpirationTime < currentTime) {
    dispatch({ type: "LOGOUT" })
    window.location.href = "/"
    feedbackInfo("Expired session")
    return false
  } else return true
}
