import { Card, CardContent, Container } from "@mui/material"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { FileUploader } from "react-drag-drop-files"
import CardMediaPath, { AvatarPath } from "../../../components/ImagePath"
import { imageInputTypes } from "../../../config"
import { DeleteIcon, DragIndicatorIcon } from "../../MuiIcons"
import { TooltipAction2 } from "../../MuiTooltipAction"
import TypographyTrad from "../../TypographyTrad."
import { convertToBase64 } from "../../convertToBase64"
import { reduxFormDataHook } from "../../reactCustomHooks"
import { arrayFirstElement, convertToArray, arrayLength } from "../../shortCuts"
import DraggableItem from "./DraggableItem"
import CustomButton from "../../../components/Buttons/CustomButton"


export function MuiImagesInput({ column }) {
  var { accessorKey, required, value, onChange2, t, multiple, variant } =
    reduxFormDataHook(column)
  value = convertToArray(value)
  const buttonVariant = column.variant === "button"

  const handleImageChange = async (images) => {
    if (images) {
      const imgs = multiple
        ? convertToArray(await convertToBase64(images))
        : await convertToBase64(images)

      onChange2(multiple ? [...value, ...imgs] : imgs)
    }
  }

  const moveItem = (dragIndex, hoverIndex) => {
    const draggedItem = value[dragIndex]
    const updatedItems = [...value]
    updatedItems.splice(dragIndex, 1)
    updatedItems.splice(hoverIndex, 0, draggedItem)
    onChange2(updatedItems)
  }

  return (
    <DndProvider key={1} backend={HTML5Backend}>
      <Container maxWidth="sm">
        <Card sx={{backgroundColor: buttonVariant ?'transparent' : ""}}>
         {!buttonVariant&& <TypographyTrad variant="h4" sx={{ textAlign: "center" }}>
            {accessorKey}
          </TypographyTrad>}
          {!arrayLength(value) ? (
            <CardContent sx={{ textAlign: "center",  }}>
              <TypographyTrad variant="body2" color="text.secondary">
                {multiple ? "select-many-images" : "selectAnImage"}
              </TypographyTrad>
            </CardContent>
          ) : multiple ? (
            MultipleImage()
          ) : (
            SingleImage()
          )}

          <CardContent sx={{ textAlign: "center" }}>
            <FileUploader
              handleChange={handleImageChange}
              name="file"
              types={imageInputTypes}
              multiple
              classes="dropImage"
              label={t("Upload or drop a file right here")}
              required={value ? false : required}
              // onTypeError={(e) => console.log(e)}
            >
              {buttonVariant &&<CustomButton>add</CustomButton>}
            </FileUploader>
          </CardContent>
        </Card>
      </Container>
    </DndProvider>
  )

  function MultipleImage() {
    return value?.map((image, key) => (
      <DraggableItem key={key} id={image} index={key} moveItem={moveItem}>
        <div key={key} style={{ position: "relative" }}>
          <TooltipAction2
            Icon={<DeleteIcon color="error" />}
            title="delete"
            confirm={false}
            actionFn={() => onChange2(value.filter((img) => img !== image))}
            sx={{
              bgcolor: "white",
              position: "absolute",
              top: 8,
              right: 8,
              "&:hover": {
                bgcolor: "lightgray",
              },
            }}
          />
          <TooltipAction2
            Icon={<DragIndicatorIcon color="primary" />}
            title="drag"
            sx={{
              bgcolor: "white",
              position: "absolute",
              top: 8,
              left: 8,
              "&:hover": {
                bgcolor: "lightgray",
              },
            }}
          />
          <CardMediaPath
            sx={{ my: 1 }}
            component="img"
            // height="200"
            image={image}
          />
        </div>
      </DraggableItem>
    ))
  }
  function SingleImage() {
    return variant === "avatar" ? (
      <AvatarPath
        sx={{ width: 200, height: 200, margin: "auto" }}
        src={arrayFirstElement(value)}
      />
    ) : (
      <CardMediaPath
        component="img"
        // height="200"
        image={arrayFirstElement(value)}
      />
    )
  }
}
