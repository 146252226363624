import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RouterProvider } from "react-router"
import { createBrowserRouter } from "react-router-dom"
import Routes from "routes"
import io from "socket.io-client"
import ThemeCustomization from "themes"
import "./app.css"
import { AutoClosePopupProvider } from "./components/AutoClosePopup"
import ConfirmationDialog from "./components/DirtyFormConfirmationDialog"
import Pace from "./components/Pace"
import { darkTheme, lightTheme } from "./config"
import "./i18n"
import { baseURL } from "./store/axiosFunctions"

import { useTheme } from "@mui/material"
import { Helmet } from "react-helmet"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import BottomInfoBar from "./pages/BottomInfoBar"
import { checkTokenExpiration } from "./utils/checkTokenExpiration"
import { noInternetFeedback } from "./utils/detectInternet"
import {
  clientConnectWithWaiterSocket,
  clientInitSocket,
  managerSocket,
  waiterSocket,
} from "./utils/socketFunction"
import { IsManager, IsWaiter } from "./utils/useIsRole"
export const socket = io(baseURL)

const App = () => {
  const { authData } = useSelector((state) => state.auth)
  const { stayConnected } = useSelector((state) => state.misc)
  const { orders } = useSelector((state) => state.order)
  const { establishment } = useSelector((state) => state.establishment)
  const clientOrder = orders[0]
  const dispatch = useDispatch()
  const isClient = !authData

  const router = createBrowserRouter(Routes)

  const isDashboard =
    location.pathname.includes("dashboard") ||
    location.pathname.includes("login")
  const theme = useTheme()
  const { mode } = theme.palette

  useEffect(() => {
    for (let link of document.getElementsByClassName("favIconClass")) {
      link.href = establishment?.logo || "/favicon-32x32.png"
    }
  }, [establishment])

  // Use effect
  noInternetFeedback()

  // Socket
  useEffect(() => {
    if (checkTokenExpiration()) {
      IsManager() && managerSocket(authData)
      IsWaiter() && waiterSocket(authData)
    }
    isClient && clientInitSocket()
  }, [authData?.accessToken])

  useEffect(() => {
    if (isClient) clientConnectWithWaiterSocket(clientOrder)
  }, [clientOrder])
  /* #endregion */

  // Keep login
  useEffect(() => {
    if (stayConnected === false)
      window.addEventListener("beforeunload", (ev) => {
        dispatch({ type: "LOGOUT" })
      })
  }, [stayConnected])

  return (
    <ThemeCustomization isDashboard={isDashboard}>
      <AutoClosePopupProvider
        theme={isDashboard ? lightTheme : darkTheme}
        defaultOptions={{
          confirmationButtonProps: { autoFocus: true },
        }}
      >
        <ToastContainer
          autoClose={5000}
          position="top-center"
          // theme={isDashboard ? mode : "dark"}
          theme="light"
        />
        <Helmet
          title={"BRB Store Online"}
          // title={
          //   window.location.origin.includes("brbstoreonline.com")
          //     ? "BRB Store Online"
          //     : "Smart MenuQR"
          // }
        >
          <meta
            name="description"
            content={
              "Bienvenue dans l'univers captivant, sophistiqué et infiniment passionnant de BRB, la marque de mode emblématique façonnée avec amour et dévouement par la créatrice visionnaire Ines. Chez BRB"
              // "Smart MenuQR - Simplify menu management for restaurants and businesses. Explore our innovative solutions for better customer experiences."
            } // Translate description
          />

          <link
            rel="stylesheet"
            type="text/css"
            href="home/css/bootstrap.css"
          />
          <link href="home/css/font-awesome.min.css" rel="stylesheet" />

          <link href="home/css/responsive.css" rel="stylesheet" />
          <link href="home/css/style.css" rel="stylesheet" />

          {/* <!-- Home --> */}

          <script
            src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
            integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"
            crossorigin="anonymous"
          ></script>
        </Helmet>
        <Pace />
        <ConfirmationDialog />
        {/* Routers */}
        <RouterProvider router={router} />
        <BottomInfoBar />
      </AutoClosePopupProvider>
    </ThemeCustomization>
  )
}

export default App
