let lastTap = 0; 
document.addEventListener('touchstart', function(event) {
    const now = new Date().getTime();
    const lastTouch = lastTap;
    const timeDiff = now - lastTouch;

    if (timeDiff < 300 && timeDiff > 0) { // Adjust the threshold (300 milliseconds) as needed
        // This is a double tap
        event.preventDefault(); // Prevent the default touch zoom or click event
        // Your double tap action here
        var element = event.target;
        element.dispatchEvent(new MouseEvent('dblclick', { bubbles: true }));
    }

    lastTap = now;
});