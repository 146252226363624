import {
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import TypographyTrad from "./TypographyTrad."
import { setSelectedRow } from "../store/actions/misc"
import { localizeDate } from "./dates"
import {
  blueGradient,
  darkGreenGradient,
  greenGradient,
  lightGreenGradient,
  orangeGradient,
  purpleGradient,
  purpleRedGradient,
  redGradient,
} from "gradients"
import { darkBlueGradient } from "gradients"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { CancelIcon } from "./MuiIcons"
const gradients = {
  canceled: redGradient,
  waiting: darkBlueGradient,
  confirmed: greenGradient,
  delivered: darkGreenGradient,
  // delivering: blueGradient,
}

function BaublesGrid({ rows, onRowClick }) {
  const { t } = useTranslation()
  const [filteredRows, setFilteredRows] = useState([])

  useEffect(() => {
    if (rows) setFilteredRows(rows)
  }, [rows])
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" gap={1} useFlexGap flexWrap={"wrap"}>
            {Object.keys(gradients).map((status, key) => (
              <Chip
                onClick={() =>
                  setFilteredRows(rows.filter((row) => row.status === status))
                }
                key={key}
                sx={{ background: gradients[status] }}
                label={t(status)}
                color="primary"
              />
            ))}
            <IconButton onClick={() => setFilteredRows(rows)}>
              <CancelIcon color="error" />
            </IconButton>
          </Stack>
        </Grid>
        {filteredRows.length === 0 ? (
          <Grid xs={12} item>
            <TypographyTrad sx={{ textAlign: "center" }} variant="h3">
              no-data
            </TypographyTrad>
          </Grid>
        ) : (
          filteredRows.map((row, key) => {
            const backgroundGradient = gradients[row.status]
            return (
              <Grid
                sx={{
                  textAlign: "center",
                }}
                item
                xs={6}
                sm={6}
                md={4}
                lg={3}
                key={key}
              >
                <Card onClick={() => onRowClick(row)}>
                  <CardContent
                    sx={{
                      color: "#FFFFFF",
                      background: backgroundGradient,
                    }}
                  >
                    <Typography variant="h3">{row.tableNumber}</Typography>
                    <Typography>{localizeDate(row.createdAt)}</Typography>
                  </CardContent>
                </Card>
                {/* {row.readyToPay && (
                  <Chip color="success" label="Ready to pay"></Chip>
                )} */}
              </Grid>
            )
          })
        )}
        {/* <WaiterOrderDialog
          viewOnly={viewOnly}
          selectedRow={selectedRow}
          order={orders.find((order) => order._id === selectedRow?._id)}
        /> */}
      </Grid>
    </>
  )
}

export default BaublesGrid
