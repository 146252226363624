import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { returnModifiedFields } from "../functions/myFunctions"
import i18n from "../i18n"
import { updateEstablishment } from "../store/reducers/establishment"
import { IsManager } from "../utils/useIsRole"
import CustomButton from "./Buttons/CustomButton"
import { ImgPath } from "./ImagePath"
import ReactRouterPromptComp from "./ReactRouterPromptComp"
import { convertToBase64 } from "../utils/convertToBase64"

const editorConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "alignment",
      "|",
      "link",
      "|",
      "undo",
      "redo",
    ],
  },
  language: i18n.language,
}

function EditCMS({ field, type = "text", imageProps }) {
  const { i18n, t } = useTranslation()
  const { establishment } = useSelector((state) => state.establishment)

  const authorize = IsManager()
  const isImage = type === "image"
  field = isImage ? field : field + "_" + i18n.language
  const isTitle = field.toLowerCase().indexOf("title") !== -1

  const siteModelModifications = establishment?.siteModelModifications
  const originalData = siteModelModifications?.[field]

  const [editOn, setEditOn] = useState(false)
  const [data, setData] = useState(originalData)

  useEffect(() => {
    setData(siteModelModifications?.[field])
  }, [establishment, i18n.language])

  const onSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setEditOn(false)
    updateEstablishment(establishment._id, {
      siteModelModifications: {
        [field]: data,
      },
    })
  }

  // const handleChange = handleChangeHook(data, setData)

  const handleToggle = () => {
    if (authorize) {
      if (isImage) inputElem?.click()
      else setEditOn(!editOn)
    }
  }

  const cancelEdit = () => {
    setEditOn(false)
    setData(siteModelModifications[field])
  }

  const handleImageChange = async (files) => {
    const image64 = await convertToBase64(files)
    setData(image64)
    setEditOn(true)
  }

  var inputElem = document.getElementById("changeImage" + field)
  useEffect(() => {
    inputElem = document.getElementById("changeImage" + field)
  }, [])

  let renderElement
  switch (type) {
    case "text":
      renderElement = (
        <>
          {!editOn && (
            <span
              dangerouslySetInnerHTML={{ __html: data }}
              onDoubleClick={() => handleToggle()}
            ></span>
          )}
          {editOn && (
            <CKEditor
              editor={ClassicEditor}
              data={data}
              onChange={(event, editor) => {
                const data = editor.getData()
                setData(data)
              }}
              config={editorConfig}
            />
            // // <div style={{ background: "white", width: "fit-content" }}>
            //   {/* <MuiTextField
            //     formData={data}
            //     setFormData={handleChange}
            //     column={{ accessorKey: field }}
            //   /> */}

            // {/* </div> */}
          )}
        </>
      )

      break
    case "image":
      renderElement = (
        <>
          <ImgPath
            {...imageProps}
            src={data}
            alt={field}
            onDoubleClick={() => {
              console.log(inputElem)
              authorize && inputElem?.click()
            }}
            style={authorize ? { cursor: "pointer" } : undefined}
          />
        </>
      )

      break

    default:
      break
  }
  const [isShown, setIsShown] = useState(false)

  return (
    <>
      <ReactRouterPromptComp
        isFormDirty={returnModifiedFields(data, originalData)}
      />
      {isImage && (
        <input
          type="file"
          accept="image/*"
          id={"changeImage" + field}
          style={{ display: "none" }}
          onChange={(e) => handleImageChange(e.target.files)}
        />
      )}
      <span style={{ position: "relative" }}>
      {renderElement}
        {authorize && !editOn && (
          <Stack
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              position: "absolute",
              inset: "0",
              transition: "all 1s",
              height: "100%",
              bgcolor: "#55555550",
              opacity: !data ? 1 : isShown ? 1 : 0,
              zIndex: 100,
              // minHeight: 200,
            }}
            spacing={2}
          >
            <CustomButton onClick={handleToggle}>
              {data
                ? t(
                    isImage
                      ? "modify-image"
                      : isTitle
                      ? "modify-title"
                      : "modify-text"
                  )
                : t(isImage ? "add-image" : isTitle ? "add-title" : "add-text")}
            </CustomButton>
          </Stack>
        )}
        {!data && !editOn && (
          <span style={{ height: 100 }}>
            {/* {t(isImage ? "add-image" : "add-text") + t("field") + ": " + field} */}
          </span>
        )}
      </span>

      {editOn && (
        <span>
          <CustomButton bgcolor="red" onClick={cancelEdit}>
            {t("cancel")}
          </CustomButton>
          <CustomButton
            disabled={!returnModifiedFields(data, originalData)}
            onClick={onSubmit}
          >
            {t("confirm")}
          </CustomButton>
        </span>
      )}
    </>
  )
}

export default EditCMS
