import {
  DialogContent,
  DialogContentText,
  Divider,
  List,
  TextField,
  Typography,
  styled,
  DialogActions,
  Stack,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { moneyCurrency } from "../../../../../../config"
import CartItem from "./CartItem"

import OrangeButton from "../../../../../../components/Buttons/orangeButton/OrangeButton"
import { setCart } from "../../../../../../store/actions/cart"
import {
  createOrder,
  updateOrderClient,
} from "../../../../../../store/actions/order"
import { useConfirm } from "../../../../../../components/ConfirmDialogPromise"
import { useState } from "react"
import { IsManager } from "../../../../../../utils/useIsRole"
import { toast } from "react-toastify"

const CartTotal = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

function CartContentDialog({ handleOpen }) {
  const { cart } = useSelector((state) => state.cart)
  const { orders } = useSelector((state) => state.order)
  const confirm = useConfirm()
  const queryParams = new URLSearchParams(location.search)
  const tableNumber = queryParams.get("tableNumber")

  const [comment, setComment] = useState("")
  const order = orders[0]
  const orderId = order?._id
  const { t } = useTranslation()

  const handleCreateOrder = async () => {
    if (IsManager()) return toast.error(t("you-are-in-manager-mode"))
    if (!tableNumber) return toast.error(t("you-cant-order-without-scanning-the-qr-code"))
    try {
      await confirm({ title: t("are-you-sure-to-checkout-these-items") })
    } catch (error) {
      return
    }

    if (!order)
      await createOrder({
        cart,
        comment,
      })
    else await updateOrderClient(orderId, { cart })

    setCart([])
    handleOpen()
  }

  var totalPrice = 0

  cart.forEach((product) => {
    const { extras, price, quantity } = product
    const extrasPrice = extras.length
      ? extras.reduce((acc, cur) => acc + cur.price, 0)
      : 0

    totalPrice += (extrasPrice + price) * quantity
  })

  return (
    <>
      <Divider />
      <DialogContent>
        <div>
          {cart.length === 0 ? (
            <DialogContentText id="cart-dialog-description">
              Your cart is empty.
            </DialogContentText>
          ) : (
            <>
              <List>
                {cart.map((product, key) => {
                  return (
                    <div key={key}>
                      <CartItem {...product} />
                    </div>
                  )
                })}
              </List>
              <TextField
                sx={{
                  mt: 1,
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "white", // Change this to your desired focused label color
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "gray",
                    },
                }}
                label="Add Comment"
                className="customInput"
                multiline
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                fullWidth
              />
            </>
          )}
          {/* Total price */}
          <CartTotal variant="h6">
            {t("total")}: {totalPrice.toFixed(3) + moneyCurrency}
          </CartTotal>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%", p: 2, px: 1 }}
        >
          <OrangeButton onClick={handleOpen} color="primary">
            continue-shopping
          </OrangeButton>
          {cart.length !== 0 && (
            <OrangeButton onClick={handleCreateOrder} color="primary">
              confirm
            </OrangeButton>
          )}
        </Stack>
      </DialogActions>
    </>
  )
}

export default CartContentDialog
