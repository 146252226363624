import { Badge, IconButton, Tooltip } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import sweetAlertUtils, { areYouSureAlert } from "./sweetAlertUtils"

function TooltipAction({ Icon, title, actionFn, color, confirm = true }) {
  const { t } = useTranslation()

  const handleAction = async () => {
    if (!actionFn) return
    if (!confirm) return actionFn()
    areYouSureAlert({ title, actionFn })
  }

  return (
    <Tooltip arrow placement="left" title={t(title)}>
      <IconButton onClick={handleAction}>
        <Icon sx={{ color: color }} color={color} />
      </IconButton>
    </Tooltip>
  )
}

export default TooltipAction

export function TooltipAction2({
  Icon,
  title,
  actionFn,
  sx,
  confirm = true,
  badgeContent,
}) {
  const { t } = useTranslation()

  const handleAction = async () => {
    if (!actionFn) return
    if (!confirm) return actionFn()
    areYouSureAlert({ title, actionFn })
  }

  return (
    <Tooltip arrow placement="left" title={t(title)}>
      <IconButton sx={sx} onClick={handleAction}>
        <Badge badgeContent={badgeContent}>{Icon}</Badge>
      </IconButton>
    </Tooltip>
  )
}
