import { startLoading } from "./actions/misc"
import { store } from "."
import { checkTokenExpiration } from "../utils/checkTokenExpiration"
import { axiosAPI } from "./axiosFunctions"
import { IsClient } from "../utils/useIsRole"
import uniquePageWebsite from "../pages/uniquePageWebsite"

export function axiosInterceptorSend() {
  axiosAPI.interceptors.request.use(async (req) => {
    pagination(req)

    attachData(req)
    /* #region  Attach establishment and tableNumber when client is user */

    startLoading()
    return req
  })
}

const attachData = (req) => {
  const clientEcommerceOrderToken = localStorage.getItem(
    "clientEcommerceOrderToken"
  )
  const { authData } = store.getState().auth

  const url = new URL(window.location.href)

  req.headers.establishment =
    authData?.establishment || uniquePageWebsite() || url.pathname.split("/")[2]

  const tableNumber = url.searchParams.get("tableNumber")

  if (tableNumber && req.data) req.data.tableNumber = parseInt(tableNumber)

  req.headers.tablenumber = tableNumber
  req.headers.clientEcommerceOrderToken = clientEcommerceOrderToken

  req.headers.authorization = `Bearer ${
    IsClient() ? clientEcommerceOrderToken : authData.accessToken
  }`
}

const pagination = (req) => {
  /* #region  Pagination */
  const { pagination } = store.getState().misc

  const page = pagination?.pageIndex
  const limit = pagination.pageSize
  if (limit) {
    req.headers.pagination_page = page
    req.headers.pagination_limit = limit
  }
  /* #endregion */
}
