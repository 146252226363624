import * as actionType from "../actionTypes"
export default (
  state = {
    order: null,
    orders: [],
    oldOrders: [],
  },
  action
) => {
  const payload = action.payload
  switch (action.type) {
    case actionType.SET_ORDER:
      return { ...state, order: payload }
    case actionType.SET_ORDERS:
      return { ...state, orders: payload }
    case actionType.SET_OLD_ORDERS:
      return { ...state, oldOrders: payload }

    case actionType.ADD_ORDER:
      return {
        ...state,
        orders: [payload, ...state.orders],
      }
    case actionType.ADD_OLD_ORDER:
      return {
        ...state,
        oldOrders: [payload, ...state.orders],
      }

    case actionType.UPDATE_ORDER:
      return {
        ...state,
        orders: state.orders.map((document) =>
          document._id === payload._id ? payload : document
        ),
      }

    case actionType.DELETE_ORDER:
      return {
        ...state,
        orders: state.orders.filter((document) => document._id !== payload),
      }

    default:
      return state
  }
}
