import PropTypes from "prop-types"
import { useSelector } from "react-redux"

// material-ui
import { Box, List, Typography } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

// project import
import NavItem from "./NavItem"
import { useTranslation } from "react-i18next"
import MuiAccordion from "../../../../../utils/MuiAccordion"

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
  const { t } = useTranslation()

  const menu = useSelector((state) => state.menu)
  const { authData } = useSelector((state) => state.auth)
  const { establishment } = useSelector((state) => state.establishment)

  const checkAccess = (item) => {
    const siteModelAccess = item?.siteModel
      ? item?.siteModel.includes(establishment?.siteModel)
      : true

    return item?.roles?.indexOf(authData.role) !== -1 && siteModelAccess
  }
  const showGroup = checkAccess(item)

  const { drawerOpen } = menu

  const navCollapse =
    showGroup &&
    item.children?.map((menuItem) => {
      let showItem = checkAccess(menuItem)

      switch (menuItem.type) {
        case "item":
          return showItem ? (
            <NavItem
              show={showGroup}
              key={menuItem.path}
              item={menuItem}
              level={1}
            />
          ) : null
        default:
          return (
            <Typography
              key={menuItem.path}
              variant="h6"
              color="error"
              align="center"
            >
              Fix - Group Collapse or Items
            </Typography>
          )
      }
    })

  if (!showGroup) return <></>

  return (
    <MuiAccordion
      ExpandIcon={<ExpandMoreIcon  sx={{  color: "white" }} />}
      title={
        <Typography sx={{ pl: 3, color: "lightgray" }} variant="subtitle2">
          {t(item.title)}
        </Typography>
      }
    >
      {/* <List
        subheader={
          item.title &&
          showGroup &&
          drawerOpen && (
            <Box sx={{ pl: 3, mb: 1.5 }}>
              <Typography variant="subtitle2" color="textSecondary">
                {t(item.title)}
              </Typography>
            </Box>
          )
        }
        sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}
      > */}
      <div style={{ marginBottom: 16 }}>{navCollapse}</div>
      {/* </List> */}
    </MuiAccordion>
  )
}

NavGroup.propTypes = {
  item: PropTypes.object,
}

export default NavGroup
