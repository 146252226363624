import { ThemeProvider, useTheme } from "@emotion/react"
import ListAltIcon from "@mui/icons-material/ListAlt"
import TableBarIcon from "@mui/icons-material/TableBar"
import {
  Badge,
  Dialog,
  IconButton,
  createTheme,
  useMediaQuery,
} from "@mui/material"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { DialogRender } from "./DialogRender"
import { reduxClientOrder } from "../../../../../../utils/socket/modelSockets/restaurant"
const darkTheme = createTheme({
  typography: {
    color: "#FFFFFF",
    fontSize: 16,
  },
  palette: {
    mode: "dark",
    text: {
      primary: "#FFF",
      secondary: "#BBB",
      disabled: "#777",
    },
  },
})
function OrderDialog() {
  const { orders } = useSelector((state) => state.order)
  const { openTrigger } = useSelector((state) => state.misc)
  const order = orders[0]
  const theme = useTheme()

  reduxClientOrder(order)

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!open) setOpen(true)
  }, [openTrigger])

  const handleOpen = () => {
    setOpen(!open)
  }
  const fullScreen =
    useMediaQuery(theme.breakpoints.down("sm")) && order?.status !== "payed"

  if (!order) return <></>
  return (
    <ThemeProvider theme={darkTheme}>
      <Badge
        className="custom-hover-20"
        color="error"
        badgeContent={orders.length ? "!" : 0}
      >
        <IconButton color="inherit" onClick={handleOpen}>
          <TableBarIcon />
          {/* <ListAltIcon /> */}
        </IconButton>
      </Badge>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="xl"
        open={open}
        onClose={handleOpen}
      >
        <DialogRender open={open} order={order} handleOpen={handleOpen} />
      </Dialog>
    </ThemeProvider>
  )
}
export default OrderDialog
