import dayjs from "dayjs"
import ar from "dayjs/locale/ar"
import en from "dayjs/locale/en"
import es from "dayjs/locale/es"
import fr from "dayjs/locale/fr"
import it from "dayjs/locale/it"
import customParseFormat from "dayjs/plugin/customParseFormat"
import isBetween from "dayjs/plugin/isBetween.js"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import weekday from "dayjs/plugin/weekday"

dayjs.extend(isBetween)
dayjs.extend(isSameOrBefore)
dayjs.extend(customParseFormat)
dayjs.extend(weekday)

const languages = { en, es, it, fr, ar }

export const localizeDate = (date, dateTime) => {
  if (!date) return ""
  date = date.replace("Z", "")
  const localFormat = dateTime ? "LLLL" : "dddd MMMM D, YYYY"
  return dayjs(date).format(localFormat)
}

export function changeDayjsLanguage(language) {
  dayjs.extend(LocalizedFormat)
  dayjs.locale({
    ...languages[language],
    weekStart: 1, // Set Monday as the first day of the week
  })
}
