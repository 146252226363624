import React from "react"

// material-ui
import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material"

// third party
import { Formik } from "formik"
import * as Yup from "yup"

// importation du projet
import AnimateButton from "components/@extended/AnimateButton"
import { useDispatch } from "react-redux"
import { forgetPassword } from "../../../store/axiosFunctions"
import { feedbackError, feedbackSuccess } from "../../../store/actions/misc"
import { useTranslation } from "react-i18next"

// assets

// ============================|| FIREBASE - CONNEXION ||============================ //

const AuthForgetPassword = () => {
const { t } = useTranslation()
  return (
    <>
      <Formik
        initialValues={{
          email: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t('ladresse-e-mail-doit-etre-valide'))
            .max(255)
            .required(t('le-mail-est-requis-0')),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          console.log(values)
          try {
            setStatus({ success: false })
            setSubmitting(false)
            await forgetPassword(values)
            feedbackSuccess("an-email-has-been-sent-to-reset-your-password")
          } catch (err) {
            setStatus({ success: false })
            setErrors({ submit: err.response.data })
            setSubmitting(false)
            feedbackError("an-error-occurred-while-sending-the-email")
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="email-reset">{t('adresse-e-mail-0')}</InputLabel>
                  <OutlinedInput
                    id="email-reset"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={t('entrez-votre-adresse-e-mail-0')}
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-reset"
                    >
                      {errors.email}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              {errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Grid>
              )}
              <Grid item xs={12}>
                <AnimateButton>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {t('reinitialiser-le-mot-de-passe')} </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  )
}

export default AuthForgetPassword
