import { Autocomplete, Checkbox, TextField } from "@mui/material"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import { useTranslation } from "react-i18next"
import { reduxFormDataHook } from "../reactCustomHooks"

export function MuiAutocomplete({ column }) {
  const {
    accessorKey,
    required,
    value,
    onChange,
    onChange2,
    label,
    t,
    multiple,
    options,
  } = reduxFormDataHook(column)
  
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />

  const checkedIcon = <CheckBoxIcon fontSize="small" />

  if (multiple)
    return (
      <Autocomplete
        value={value || []}
        multiple
        fullWidth
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option) =>
          t(option?.optionName || option?.name || option)
        }
        onChange={(e, val) => onChange2(val)}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {t(option?.optionName || option?.name || option)}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} label={label} placeholder={label} />
        )}
      />
    )
  return (
    <Autocomplete
      fullWidth
      value={value || null}
      key={`column-${accessorKey}`}
      options={options}
      getOptionLabel={(option) => t(option.name || option)}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} required={required} label={label} />
      )}
    />
  )
}
