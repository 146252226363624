import { ThemeProvider, useTheme } from "@emotion/react"
import CloseIcon from "@mui/icons-material/Close"
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket"
import {
  Badge,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  createTheme,
  useMediaQuery,
} from "@mui/material"
import { styled } from "@mui/system" // Import the styled function
import { useState } from "react"
import { useSelector } from "react-redux"
import { useConfirm } from "../../../../../../components/ConfirmDialogPromise"
import { AvatarPath } from "../../../../../../components/ImagePath"
import CartContentDialog from "./CartContentDialog"
import ListAltIcon from "@mui/icons-material/ListAlt"

// Create a dark theme
const darkTheme = createTheme({
  typography: {
    color: "#FFFFFF",
    fontSize: 16,
  },
  palette: {
    mode: "dark",
    text: {
      primary: "#FFF",
      secondary: "#BBB",
      disabled: "#777",
    },
  },
})

// Styled components
const CartButton = styled(IconButton)({
  marginLeft: "auto",
})

const Carts = () => {
  const { cart } = useSelector((state) => state.cart)
  const { establishment } = useSelector((state) => state.establishment)

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(!open)
  }

  const theme = useTheme()

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <ThemeProvider theme={darkTheme}>
      {/* Badge with the cart item count */}
      <Badge
        className="custom-hover-20"
        color="error"
        badgeContent={cart.length}
      >
        <CartButton color="inherit" onClick={handleOpen}>
          <ListAltIcon />
          {/* <ShoppingBasketIcon /> */}
        </CartButton>
      </Badge>
      {/* Dialog */}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="xl"
        open={open}
        onClose={handleOpen}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleOpen}
          style={{ position: "absolute", top: 10, right: 20 }}
        >
          <CloseIcon />
        </IconButton>
        {/* Title */}
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <AvatarPath
              sx={{ width: 75, height: 75 }}
              src={establishment.logo}
            />
            <h1 className="logo me-auto me-lg-0">
              <span style={{ color: "#cda45e" }}>{establishment.name}</span>
            </h1>
          </Stack>
        </DialogTitle>

        {/* Content */}
        <CartContentDialog handleOpen={handleOpen} />
      </Dialog>
    </ThemeProvider>
  )
}

export default Carts
