import * as React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useTranslation } from "react-i18next"
import { Paper } from "@mui/material"

export default function MuiAccordion({
  title,
  description,
  children,
  isPaper,
  ExpandIcon,
}) {
  const { t } = useTranslation()

  if (!title) return children
  const Parent = isPaper ? Paper : "div"
  return (
    <Parent sx={{ p: 2 }}>
      <Accordion
        sx={{
          minHeight: 0,
          // ".Mui-expanded": { m: 0 },
          bgcolor: "transparent",
          boxShadow: "none",
        }}
        defaultExpanded
        disableGutters
      >
        <AccordionSummary
          sx={{
            // borderBottom: "1px solid white",

            p: 0,
            // ".Mui-expanded": { m: 0 },
          }}
          expandIcon={ExpandIcon}
        >
          {typeof title === "string" ? (
            <Typography variant="h4" sx={{ flexShrink: 0 }}>
              {t(title)}
            </Typography>
          ) : (
            title
          )}

          <Typography sx={{ color: "text.secondary" }}>
            {t(description)}
          </Typography>
        </AccordionSummary>
        {/* <AccordionDetails> */}
        {children}
        {/* </AccordionDetails> */}
      </Accordion>
    </Parent>
  )
}
