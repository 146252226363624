import * as actionType from "../actionTypes"

export default (
  state = {
    cart : [],
    // cart: [
    //   {
    //     _id: "654fe73455245c94f29a2e9e",
    //     price: 25,
    //     name: "Veste",
    //     image:
    //       "DBImages\\654fe73455245c94f29a2e9e\\images_0\\image_2023-11-27T11_31_03_images_0.png",
    //     quantity: 1,
    //     size: "S",
    //     color: "white",
    //     productInCartId: "idbb8d22a7f30ee",
    //   },
    //   {
    //     _id: "654fe73455245c94f29a2e9e",
    //     price: 25,
    //     name: "Veste",
    //     image:
    //       "DBImages\\654fe73455245c94f29a2e9e\\images_0\\image_2023-11-27T11_31_03_images_0.png",
    //     quantity: 3,
    //     size: "M",
    //     color: "black",
    //     productInCartId: "id1c80459fe1b8e",
    //   },
    // ],
  },
  action
) => {
  const payload = action.payload
  switch (action.type) {
    case actionType.SET_CART:
      return { ...state, cart: payload }

    case actionType.ADD_CART:
      return {
        ...state,
        cart: [payload, ...state.cart],
      }

    case actionType.UPDATE_CART:
      return {
        ...state,
        cart: state.cart.map((document) =>
          document.productInCartId === payload.productInCartId
            ? payload
            : document
        ),
      }

    case actionType.DELETE_CART:
      return {
        ...state,
        cart: state.cart.filter(
          (document) => document.productInCartId !== payload
        ),
      }

    default:
      return state
  }
}
