import React from "react"

import "./orangeButton.css"
import { useTranslation } from "react-i18next"

const marginDirection = {
  center : "auto",
  left : "right",
  right : "left",
}

function OrangeButton(props) {
  var { margin } = props
  margin = "1rem " + margin
  const { t } = useTranslation()
  return (
    <button
      style={{ display: "block", margin }}
      className="addButton button-hover"
      {...props}
    >
      {t(props.children)}
    </button>
  )
}

export default OrangeButton
