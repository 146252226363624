import { useTranslation } from "react-i18next" // Import the translation hook
import { useSelector } from "react-redux"
import EditCMS from "../../../../components/EditCMS"

function ChefSection() {
  const { establishment } = useSelector((state) => state.establishment)
  const { t } = useTranslation() // Use the translation hook

  return (
    <section id="chefs" className="chefs">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>{t("chefs.title")}</h2> {/* Translate the title */}
          <p>{t("chefs.professionalChefs")}</p>{" "}
          {/* Translate the description */}
        </div>
        <div className="row">
          {Array.from({ length: 3 }, (_, i) => {
            i = i + 1
            const chefTitleField = "chefTitle" + i
            const chefTextField = "chefText" + i
            const ChefImageField = "chefImage" + i

            const chefTitleValue = establishment[chefTitleField]
            const chefTextValue = establishment[chefTextField]
            const ChefImageValue = establishment[ChefImageField]
            return (
              <div key={i} className="col-lg-4 col-md-6">
                <div className="member" data-aos="zoom-in" data-aos-delay={100}>
                  <EditCMS
                    type="image"
                    field={ChefImageField}
                    props={{ className: "img-fluid" }}
                  />
                </div>
                <div className="member-info">
                  <div
                    style={{ textAlign: "center" }}
                    className="member-info-content"
                  >
                    <h4>
                      <EditCMS field={chefTitleField} />
                    </h4>
                    {/* <span>{t("chefs.masterChef")}</span> */}
                  </div>
                  {/* <div className="social">
                    <a href="">
                      <i className="bi bi-twitter" />
                    </a>
                    <a href="">
                      <i className="bi bi-facebook" />
                    </a>
                    <a href="">
                      <i className="bi bi-instagram" />
                    </a>
                    <a href="">
                      <i className="bi bi-linkedin" />
                    </a>
                  </div> */}
                </div>
                {/* <p> */}
                  <EditCMS field={chefTextField} />
                {/* </p> */}
                {/* Translate the chef's description */}
              </div>
            )
          })}

          {/* Repeat similar translations for the other chefs */}
        </div>
      </div>
    </section>
  )
}

export default ChefSection
