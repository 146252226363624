import { lazy } from "react"

// project import
import Loadable from "components/Loadable"
import MinimalLayout from "layout/MinimalLayout"
import ProtectedRoutes from "../utils/ProtectedRoutes"
import ForgetPassword from "../pages/authentication/ForgetPassword"
import ResetPassword from "../pages/authentication/ResetPassword"

// render - login
const AuthLogin = Loadable(lazy(() => import("pages/authentication/Login")))
const AuthRegister = Loadable(
  lazy(() => import("pages/authentication/Register"))
)

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/auth/",
  element: (
    <ProtectedRoutes status="NOT_CONNECTED">
      <MinimalLayout />
    </ProtectedRoutes>
  ),
  children: [
    {
      path: "login",
      element: <AuthLogin />,
    },
    {
      path: "forget_password",
      element: <ForgetPassword />,
    },
    {
      path: "reset_password/:token",
      element: <ResetPassword />,
    },
    // {
    //   path: "register",
    //   element: <AuthRegister />,
    // },
  ],
}

export default LoginRoutes
