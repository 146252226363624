import CloseIcon from "@mui/icons-material/Close"
import { IconButton } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import CustomButton from "./Buttons/CustomButton"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

function ConfirmationDialog({
  openTrigger,
  onCancel,
  onConfirm,
  message,
  confirmButtonText,
  cancelButtonText,
}) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (openTrigger) handleOpen()
  }, [openTrigger])

  const handleOpen = async () => {
    setOpen(!open)
  }
  const confirm = () => {
    if (onConfirm) onConfirm()
    handleOpen()
  }
  const cancel = () => {
    if (onCancel) onCancel()
    handleOpen()
  }
  const { t } = useTranslation()
  return (
    <Dialog open={open} onClose={confirm}>
      <IconButton
        edge="end"
        color="inherit"
        onClick={confirm}
        style={{ position: "absolute", top: 10, right: 20 }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>{t("confirm")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t(message)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={cancel} bgcolor="red">
          {confirmButtonText}
        </CustomButton>
        <CustomButton onClick={confirm} bgcolor="green">
          {cancelButtonText}
        </CustomButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
