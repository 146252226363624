import React from "react"
import { useTranslation } from "react-i18next" // Import the translation hook
import { formatPhoneNumberWithSpaces } from "utils/formatText"
import { useSelector } from "react-redux"

function FooterSection() {
  const { establishment } = useSelector((state) => state.establishment)
  const { t } = useTranslation()
  const { twitter, facebook, instagram, googleMapMarker } = establishment

  return (
    <footer id="footer">
      <div className="container">
        {" "}
        {googleMapMarker && (
          <div data-aos="fade-up">
            <iframe
              style={{ border: 0, width: "100%", height: 350 }}
              src={googleMapMarker}
              frameBorder={0}
              allowFullScreen=""
            />
          </div>
        )}
        <div className="copyright">
          © {new Date().getFullYear()} Smart MenuQR{" "}
          {t("footer.allRightsReserved")}
        </div>
      </div>
    </footer>
  )
}

export default FooterSection
