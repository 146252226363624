import { useSelector } from "react-redux"
import { ImgPath } from "../../../components/ImagePath"
import { moneyCurrency } from "../../../config"
import { cartPrice } from "../../../utils/calculatePrices"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

export function Cart() {
  const { t } = useTranslation()
  const { cart } = useSelector((state) => state.cart)
  const totalPrice = cartPrice(cart)

  return (
    <div className="wrap-header-cart js-panel-cart">
      <div className="s-full js-hide-cart" />
      <div className="header-cart flex-col-l p-l-65 p-r-25">
        <div className="header-cart-title flex-w flex-sb-m p-b-8">
          <span className="mtext-103 cl2">{t("your-cart")}</span>
          <div className="fs-35 lh-10 cl2 p-lr-5 pointer hov-cl1 trans-04 js-hide-cart">
            <i className="zmdi zmdi-close" />
          </div>
        </div>
        <div className="header-cart-content flex-w js-pscroll">
          <ul className="header-cart-wrapitem w-full">
            {cart.map((product, key) => {
              const {
                name,
                price,
                images,
                description,
                size,
                color,
                quantity,
                category,
              } = product || {}
              return (
                <li key={key} className="header-cart-item flex-w flex-t m-b-12">
                  <div className="header-cart-item-img">
                    <ImgPath src={images} alt="IMG" />
                  </div>
                  <div className="header-cart-item-txt p-t-8">
                    <a
                      href="#"
                      className="header-cart-item-name m-b-18 hov-cl1 trans-04"
                    >
                      {name}
                    </a>
                    <span className="header-cart-item-info">
                      {quantity + " x " + price + moneyCurrency}
                    </span>
                  </div>
                </li>
              )
            })}
          </ul>
          <div className="w-full">
            <div className="header-cart-total w-full p-tb-40">
              Total: {totalPrice + moneyCurrency}
            </div>
            {cart.length !== 0 && (
              <div className="header-cart-buttons flex-w w-full">
                <Link
                  to="./shopping_cart"
                  className="flex-c-m stext-101 cl0 size-107 bg3 bor2 hov-btn3 p-lr-15 trans-04 m-r-8 m-b-10"
                >
                  {t("view-cart")}{" "}
                </Link>
                <Link
                  to="./shopping_cart"
                  className="flex-c-m stext-101 cl0 size-107 bg3 bor2 hov-btn3 p-lr-15 trans-04 m-b-10"
                >
                  {t("check-out")}{" "}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
