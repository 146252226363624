import { useSelector } from "react-redux"
import CRUDTable from "../../../components/crudTable/CRUDTable"
import {
  createManager,
  deleteManager,
  setUsers,
  updateManager,
} from "../../../store/reducers/user"
import { formatPhoneNumberWithSpaces } from "../../../utils/formatText"

function ManagerPage() {
  const { users } = useSelector((state) => state.user)
  const columns = [
    {
      header: "name",
      accessorFn: (row) => row.firstName + " " + row.lastName,
      createHidden: true,
      editHidden: true,
    },

    {
      accessorKey: "firstName",
      required: true,
      hiddenColumn: true,
      editHidden: true,
    },
    {
      accessorKey: "lastName",
      required: true,
      hiddenColumn: true,
      editHidden: true,
    },
    {
      header: "phoneNumber",
      accessorKey: "phoneNumber",
      accessorFn: (row) => formatPhoneNumberWithSpaces(row.phoneNumber),
      editHidden: true,
      required: true,
    },
    {
      header: "email",
      accessorKey: "email",
    },

    // {
    //   accessorKey: "password",
    //   type: "password",
    //   // helperText:
    //   //   "The password must consist of a minimum of 8 characters and must include at least one number and one special character.",
    //   // TODO pattern: "^(?=.*[A-Z])(?=.*[a-z])(?=.*d).{8,}$",

    //   header: "Password",
    //   hiddenColumn: true,
    //   editHidden: true,
    // },
    {
      header: "establishmentName",
      accessorKey: "establishmentName",
      accessorFn: (row) => row.establishment?.name,
      editHidden: true,
    },
    //  {
    //   header: "siteModel",
    //   accessorFn: (row) => t(row.establishment.siteModel),
    //   editHidden: true,
    // },
    // {
    //   accessorKey: "siteModel",
    //   required: true,
    //   type: "select",
    //   options: Object.keys(siteModelOptions),
    //   hiddenColumn: true,
    //   editHidden: true,
    // },
  ]
  return (
    <div>
      <CRUDTable
        columns={columns}
        rows={users}
        fetchFn={setUsers}
        createFn={createManager}
        updateFn={updateManager}
        deleteFn={deleteManager}
        // collapsable={"list-of-managers"}
      />
    </div>
  )
}

export default ManagerPage
