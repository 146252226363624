import React, { useEffect } from "react"
import { formatPhoneNumberWithSpaces } from "utils/formatText"
import { useTranslation } from "react-i18next"
import { Avatar, Stack, useMediaQuery } from "@mui/material"
import { supportedLanguages } from "utils/i18nUtilities"
import { useSelector } from "react-redux"
import { changeDayjsLanguage } from "../../../../utils/dates"

function TopBar() {
  const { establishment } = useSelector((state) => state.establishment)
  const { t, i18n } = useTranslation()

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
    changeDayjsLanguage(i18n.language)
  }
  useEffect(() => {
    changeDayjsLanguage(i18n.language)
  }, [])
  // Use MUI useMediaQuery to check for mobile screen size
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"))

  return (
    <div id="topbar" className="d-flex align-items-center fixed-top">
      <div className="container d-flex justify-content-center justify-content-md-between">
        <Stack justifyContent="space-between" alignItems="center">
          {!isMobile && (
            <div className="contact-info d-flex align-items-center">
              <i className="custom-hover-10 bi bi-phone d-flex align-items-center">
                <span>
                  {t("topBar.phoneNumber")} +216{" "}
                  {formatPhoneNumberWithSpaces(establishment.phoneNumber)}
                </span>
              </i>
              <i className="custom-hover-10 bi bi-clock d-flex align-items-center ms-4">
                <span>
                  {t("topBar.openingHours", {
                    openingTime: establishment?.openingTime,
                    closingTime: establishment?.closingTime,
                  })}
                </span>
              </i>
            </div>
          )}
          {isMobile && Languages()}
        </Stack>
      </div>
      {!isMobile && Languages()}
    </div>
  )

  function Languages() {
    return (
      <Stack direction={isMobile ? "row" : "row"}>
        {supportedLanguages.map((language, key) => (
          <Stack
            key={key}
            className="custom-hover-10 language-link"
            direction="row"
            alignItems="center"
            onClick={() => changeLanguage(language.code)}
          >
            <Avatar
              sx={{ mr: 1, width: 30, height: 30 }}
              src={language.flag}
              alt={language.name}
            />
            {isMobile ? null : <span>{language.name}</span>}
          </Stack>
        ))}
      </Stack>
    )
  }
}

export default TopBar
