import { useState, useEffect } from 'react';

const useIsMobile = (threshold = 600) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= threshold); // You can adjust the threshold as needed

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= threshold); // Update the state when the window width changes
    };

    window.addEventListener('resize', handleResize); // Add a resize event listener

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;
