import React from "react"
import { useTranslation } from "react-i18next" // Import useTranslation hook
import { useSelector } from "react-redux"
import EditCMS from "../../../../components/EditCMS"

function Hero() {
  const { t } = useTranslation() // Use the t function for translations
  const { establishment } = useSelector((state) => state.establishment)

  return (
    <section id="hero" className="d-flex align-items-center">
      <div
        className="container position-relative text-center text-lg-start"
        data-aos="zoom-in"
        data-aos-delay={100}
      >
        <div className="row">
          <div className="col-lg-8">
            <h1>
              {t("hero.welcomeTo")} <span>{establishment.name}</span>
            </h1>
            <h2>
              <EditCMS field="slogan" />
              {/* {t("hero.deliveringFoodForYears")} */}
            </h2>
            <div className="btns">
              <a
                href="#menu"
                className="custom-hover-10 btn-menu animated fadeInUp scrollto"
              >
                {t("hero.ourMenu")}
              </a>
              <a
                href="#book-a-table"
                className="custom-hover-10 btn-book animated fadeInUp scrollto"
              >
                {t("hero.bookTable")}
              </a>
            </div>
          </div>
          {/* <div
              className="col-lg-4 d-flex align-items-center justify-content-center position-relative"
              data-aos="zoom-in"
              data-aos-delay={200}
            >
              <a
                href="https://www.youtube.com/watch?v=GlrxcuEDyF8"
                className="glightbox play-btn"
              />
            </div> */}
        </div>
      </div>
    </section>
  )
}

export default Hero
