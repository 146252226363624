import React, { useState, useCallback, useContext } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import {
  CssBaseline,
  IconButton,
  ThemeProvider,
  createTheme,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import CustomButton from "./Buttons/CustomButton"
import { useTranslation } from "react-i18next"

// Light theme
export const lightTheme = createTheme({
  palette: {
    mode: "light",
    // Add your light theme color configurations here
  },
  // Other light theme options
})

// Dark theme
export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    // Add your dark theme color configurations here
  },
  // Other dark theme options
})
// Create a context to manage the confirmation dialog
const ConfirmContext = React.createContext()

// ConfirmationDialog component
const ConfirmationDialog = ({
  open,
  options,
  onCancel,
  onConfirm,
  onClose,
  theme,
}) => {
  const [confirmationKeywordValue, setConfirmationKeywordValue] = useState("")
  const confirmationButtonDisabled =
    options.confirmationKeyword &&
    confirmationKeywordValue !== options.confirmationKeyword

  const handleCancel = () => {
    onCancel()
    onClose()
  }

  const handleConfirm = () => {
    onConfirm()
    onClose()
  }
const { t } = useTranslation()
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Dialog fullWidth open={open} onClose={onClose}>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          style={{ position: "absolute", top: 10, right: 20 }}
        >
          <CloseIcon />
        </IconButton>
        {options.title && <DialogTitle>{options.title}</DialogTitle>}
        <DialogContent>
          {options.content ? (
            <DialogContentText>{options.content}</DialogContentText>
          ) : (
            <DialogContentText>{options.description}</DialogContentText>
          )}
          {options.confirmationKeyword && (
            <TextField
              onChange={(e) => setConfirmationKeywordValue(e.target.value)}
              value={confirmationKeywordValue}
              fullWidth
            />
          )}
        </DialogContent>
        <DialogActions>
          {!options.hideCancelButton && (
            <CustomButton
              bgcolor="red"
              onClick={handleCancel}
              color="secondary"
            >
              {t(options.cancellationText)}
            </CustomButton>
          )}
          <CustomButton
            onClick={handleConfirm}
            bgcolor={"green"}
            disabled={confirmationButtonDisabled}
          >
            {t(options.confirmationText)}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

// Default options for the confirmation dialog
const DEFAULT_OPTIONS = {
  title: "Are you sure?",
  description: "",
  content: null,
  confirmationText: "yes",
  cancellationText: "cancel",
  allowClose: true,
  confirmationKeywordTextFieldProps: {},
  hideCancelButton: false,
}

// ConfirmProvider component to manage the dialog state
const ConfirmProvider = ({ children, defaultOptions = {}, theme }) => {
  const [options, setOptions] = useState({})
  const [resolveReject, setResolveReject] = useState([])
  const [resolve, reject] = resolveReject

  const confirm = useCallback(
    (options = {}) =>
      new Promise((resolve, reject) => {
        setOptions(options)
        setResolveReject([resolve, reject])
      }),
    []
  )

  const handleClose = useCallback(() => {
    setResolveReject([])
  }, [])

  const handleCancel = useCallback(() => {
    if (reject) {
      reject()
      handleClose()
    }
  }, [reject, handleClose])

  const handleConfirm = useCallback(() => {
    if (resolve) {
      resolve()
      handleClose()
    }
  }, [resolve, handleClose])

  return (
    <React.Fragment>
      <ConfirmContext.Provider value={confirm}>
        {children}
      </ConfirmContext.Provider>
      <ConfirmationDialog
        open={resolveReject.length === 2}
        options={{
          ...DEFAULT_OPTIONS,
          ...defaultOptions,
          ...options,
        }}
        onClose={handleClose}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        theme={theme} // Pass the theme to the dialog
      />
    </React.Fragment>
  )
}

// Hook to use the confirm function
const useConfirm = () => {
  const confirm = useContext(ConfirmContext)
  return confirm
}

export { ConfirmProvider, useConfirm }
