import React, { useState, useCallback, useContext, useEffect } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import {
  CssBaseline,
  Divider,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import CustomButton from "./Buttons/CustomButton"
import { logoPath } from "../config"

// Light theme
export const lightTheme = createTheme({
  palette: {
    mode: "light",
    // Add your light theme color configurations here
  },
  // Other light theme options
})

// Dark theme
export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    // Add your dark theme color configurations here
  },
  // Other dark theme options
})

// Create a context to manage the AutoClosePopup
const AutoClosePopupContext = React.createContext()

// AutoClosePopup component
const AutoClosePopup = ({
  open,
  options,
  onCancel,
  onConfirm,
  onClose,
  theme,
}) => {
  const [confirmationKeywordValue, setConfirmationKeywordValue] = useState("")
  const confirmationButtonDisabled =
    options.confirmationKeyword &&
    confirmationKeywordValue !== options.confirmationKeyword

  const handleCancel = () => {
    onCancel()
    onClose()
  }

  const handleConfirm = () => {
    onConfirm()
    onClose()
  }

  useEffect(() => {
    // Automatically close the AutoClosePopup after a specified time (in milliseconds)
    const autoCloseTimer = setTimeout(() => {
      onClose()
    }, options.autoCloseDuration || 5000) // Default auto close after 5 seconds

    return () => {
      // Clear the timer when the component unmounts or when options change
      clearTimeout(autoCloseTimer)
    }
  }, [open, options, onClose])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Dialog fullWidth open={open} onClose={onClose}>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          style={{ position: "absolute", top: 10, right: 20 }}
        >
          <CloseIcon />
        </IconButton>
        {options.title && <DialogTitle>{options.title}</DialogTitle>}
        <DialogContent>
          <Stack sx={{ p: 3, textAlign: "center" }} spacing={2}>
            {options.content}
          </Stack>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  )
}

// Default options for the AutoClosePopup
const DEFAULT_OPTIONS = {
  title: "",
  description: "",
  content: null,
  confirmationText: "Ok",
  cancellationText: "Cancel",
  allowClose: true,
  confirmationKeywordTextFieldProps: {},
  hideCancelButton: false,
}

// AutoClosePopupProvider component to manage the dialog state
const AutoClosePopupProvider = ({ children, defaultOptions = {}, theme }) => {
  const [options, setOptions] = useState({})
  const [resolveReject, setResolveReject] = useState([])
  const [resolve, reject] = resolveReject

  const autoClosePopup = useCallback(
    (options = {}) =>
      new Promise((resolve, reject) => {
        setOptions(options)
        setResolveReject([resolve, reject])
      }),
    []
  )

  const handleClose = useCallback(() => {
    setResolveReject([])
  }, [])

  const handleCancel = useCallback(() => {
    if (reject) {
      reject()
      handleClose()
    }
  }, [reject, handleClose])

  const handleConfirm = useCallback(() => {
    if (resolve) {
      resolve()
      handleClose()
    }
  }, [resolve, handleClose])

  return (
    <React.Fragment>
      <AutoClosePopupContext.Provider value={autoClosePopup}>
        {children}
      </AutoClosePopupContext.Provider>
      <AutoClosePopup
        open={resolveReject.length === 2}
        options={{
          ...DEFAULT_OPTIONS,
          ...defaultOptions,
          ...options,
        }}
        onClose={handleClose}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        theme={theme} // Pass the theme to the AutoClosePopup
      />
    </React.Fragment>
  )
}

// Hook to use the autoClosePopup function
const useAutoClosePopup = () => {
  const autoClosePopup = useContext(AutoClosePopupContext)
  return autoClosePopup
}

export { AutoClosePopupProvider, useAutoClosePopup }
