import { CardMedia, Tooltip } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { logoPath } from "../../config"
import { useTranslation } from "react-i18next"
import { IsSupervisor, IsWaiter } from "../../utils/useIsRole"
import { useSelector } from "react-redux"

const Logo = ({ width = 125 }) => {
  const { establishment } = useSelector((state) => state.establishment)

  const theme = useTheme()
  const styles = {
    media: {
      // margin : 1.5,
      mr: 2,
      width: width,
      height: width,
    },
  }
  const disabled = IsSupervisor() || IsWaiter()

  const { t } = useTranslation()
  return (
    <Tooltip title={disabled ? "" : t("visit-establishment")}>
      <CardMedia
        component="img"
        src={establishment.AppForOneClient  ?establishment.logo : logoPath}
        alt="Logo"
        sx={styles.media} // Apply the styles for hover effect
      />
    </Tooltip>
  )
}

export default Logo
