import { createSlice } from "@reduxjs/toolkit"
import { dispatch } from ".."

const initialState = {
  formDataMap: {},
  displayDataFormMap: null,
}

const miscSlice = createSlice({
  name: "misc2",
  initialState,
  reducers: {
    setFormDataMapReducer(state, action) {
      state.formDataMap = action.payload
    },
    setDisplayDataFormMapReducer(state, action) {
      state.displayDataFormMap = action.payload
    },
  },
})

const { setFormDataMapReducer, setDisplayDataFormMapReducer } =
  miscSlice.actions

export const setFormDataMap = (data) => {
  dispatch(setFormDataMapReducer(data))
}
export const resetFormDataMap = () => {
  dispatch(setFormDataMapReducer({}))
  // resetSelectedRow()
}

export const setDisplayDataFormMap = (data) => {
  dispatch(setDisplayDataFormMapReducer(data))
}
export const resetDisplayDataFormMap = () => {
  dispatch(setDisplayDataFormMapReducer(null))
}

export default miscSlice.reducer
