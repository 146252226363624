import React from "react"

// material-ui
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material"

// third party
import { Formik } from "formik"
import * as Yup from "yup"

// importation du projet
import AnimateButton from "components/@extended/AnimateButton"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router"
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons"
import { resetPassword } from "../../../store/actions/auth"
import { feedbackSuccess } from "../../../store/actions/misc"
import { useTranslation } from "react-i18next"

// assets

// ============================|| FIREBASE - CONNEXION ||============================ //

const AuthResetPassword = () => {
  const [showPassword, setShowPassword] = React.useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const navigate = useNavigate()
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const { token } = useParams()
  const { t } = useTranslation()
  return (
    <>
      <Formik
        initialValues={{
          password: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .max(255)
            .required(t("le-mot-de-passe-est-requis-0")),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setStatus({ success: false })
            setSubmitting(false)
            console.log(values)
            // resetPassword({ ...values, token })
            feedbackSuccess("your-password-has-been-changed")
            setTimeout(() => {
              navigate("/auth/login")
            }, 3000)
          } catch (err) {
            console.log(err)
            setStatus({ success: false })
            setErrors({ submit: err.response.data })
            setSubmitting(false)
            feedbackError("an-error-has-occurred")
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="password-login">
                    {t("nouveau-mot-de-passe")}{" "}
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                    id="-password-login"
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? (
                            <EyeOutlined />
                          ) : (
                            <EyeInvisibleOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder={t("entrez-votre-nouveau-mot-de-passe")}
                  />
                  {touched.password && errors.password && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-password-login"
                    >
                      {errors.password}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              {errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Grid>
              )}
              <Grid item xs={12}>
                <AnimateButton>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {t("reinitialiser-le-mot-de-passe")}{" "}
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  )
}

export default AuthResetPassword
