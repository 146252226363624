import { ThemeProvider } from "@emotion/react"
import { Grid, Paper, Stack, createTheme } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import CustomButton from "../components/Buttons/CustomButton"
import ReactRouterPromptComp from "../components/ReactRouterPromptComp"
import { returnModifiedFields } from "../functions/myFunctions"
import { setFormDataMap } from "../store/reducers/misc2"
import MuiAccordion from "./MuiAccordion"
import { MuiAutocomplete } from "./MuiFormComponents/MuiAutocomplete"
import { MuiCheckbox } from "./MuiFormComponents/MuiCheckbox"
import MuiCheckmarks from "./MuiFormComponents/MuiCheckmarks"
import MuiNumberInput from "./MuiFormComponents/MuiNumberInput"
import MuiRadioButton from "./MuiFormComponents/MuiRadioButton"
import MuiSelect from "./MuiFormComponents/MuiSelect"
import { MuiTextField3 } from "./MuiFormComponents/MuiTextField3"
import { MuiImagesInput } from "./MuiFormComponents/image/MuiImagesInput"
import RenderChipsInput from "./RenderChipsInput"
import DateTimePickerComp from "./dateTimePicker"
import MuiCKEditor from "./MuiFormComponents/CKEditor"

export const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
})

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
})

function MuiFormMap({
  columns,
  formDataInit,
  onSubmit,
  sm = 6,
  editMode = false,
  className,
  theme,
  additionalData,
  isPaper,
  options = {
    resetButtonOptions: { show: true },
    confirmButtonOptions: { show: true },
  },
}) {
  const { resetButtonOptions, confirmButtonOptions } = options
  const { formDataMap } = useSelector((state) => state.misc2)
  const [defaultForm, setDefaultForm] = useState(formDataInit)
  const modifiedValues = returnModifiedFields(formDataMap, defaultForm)

  const onConfirm = async (e) => {
    try {
      e.preventDefault()
      if (modifiedValues) {
        onSubmit && (await onSubmit(modifiedValues))

        localStorage.setItem(
          "lastForm",
          JSON.stringify({
            ...JSON.parse(localStorage.getItem("lastForm")),
            [window.location.href]: formDataMap,
          })
        )
        setDefaultForm(formDataMap)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (formDataInit) setFormDataMap(formDataInit)
  }, [])

  useEffect(() => {
    if (additionalData) setFormDataMap(additionalData)
  }, [])

  function switcher(column) {
    // column.disabled = disabled
    const { type, CustomElement } = column
    
    if (CustomElement) return <CustomElement />

    switch (type) {
      case "autocomplete":
        return <MuiAutocomplete column={column} />
      case "chip": // Array
        return <RenderChipsInput column={column} />

      case "avatar":
      case "image":
      case "images":
        return <MuiImagesInput column={column} />

      case "select":
        return <MuiSelect column={column} />

      case "checkbox":
        return <MuiCheckbox column={column} />

      case "radioButton":
        return <MuiRadioButton column={column} />
      case "numberInput":
        return <MuiNumberInput column={column} />

      case "dateTimePicker":
        return <DateTimePickerComp column={column} />

      case "customText":
        return <MuiCKEditor column={column} />

      default:
        return <MuiTextField3 column={column} />
    }
  }
  function Render(column) {
    const { editHidden, createHidden } = column
    if (editHidden && editMode) return <></>
    if (createHidden && !editMode) return <></>
    return (
      <Grid item xs={12} sm={column.fullWidth ? 12 : sm}>
        {switcher(column)}
      </Grid>
    )
  }
  const Container = isPaper ? Paper : "div"
  const isDirty = formDataInit && modifiedValues
  return (
    <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
      <Container sx={{ p: 2 }}>
        <form className={className} id="formMap" onSubmit={onConfirm}>
          <ReactRouterPromptComp isFormDirty={isDirty} />
          <Grid container spacing={2}>
            {columns.map((column, key) => {
              const { groupCollapse } = column
              if (groupCollapse)
                return (
                  <Grid item xs={12} key={key}>
                    <MuiAccordion isPaper={true} title={column?.accessorKey}>
                      <Grid container spacing={2}>
                        {groupCollapse.map((groupCol, key) => (
                          <Fragment key={key}>{Render(groupCol)}</Fragment>
                        ))}
                      </Grid>
                    </MuiAccordion>
                  </Grid>
                )
              return <Fragment key={key}>{Render(column)}</Fragment>
              // return <Render column={column} key={key} />
            })}
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              spacing={2}
            >
              {resetButtonOptions?.show && (
                <CustomButton
                  center
                  bgcolor="orange"
                  onClick={() => setFormDataMap(formDataInit || {})}
                >
                  reset
                </CustomButton>
              )}
              {SubmitButton(options.confirmButtonOptions)}
            </Stack>
          </Grid>
        </form>
      </Container>
    </ThemeProvider>
  )
}

export default MuiFormMap

const fullWithTypes = ["radioButton"]

function SubmitButton(confirmButtonOptions = {}) {
  const { show, element } = confirmButtonOptions

  if (element) return element
  if (show === false) return <></>

  return (
    <CustomButton center bgcolor="green" type="submit">
      confirm
    </CustomButton>
  )
}
