import { setSelectedRow } from "../../store/actions/misc"
import { DeleteIcon, EditIcon } from "../../utils/MuiIcons"

const actionsMap = ({ deleteFn, updateFn }) => [
  {
    condition: () => !!updateFn,
    Icon: () => EditIcon,
    title: () => "edit",
    actionFn: (row) => setSelectedRow(row),
    color: () => "blue",
    confirm: false,
  },
  {
    condition: () => !!deleteFn,
    Icon: () => DeleteIcon,
    title: () => "delete",
    actionFn: (row) => deleteFn(row._id),
    color: () => "red",
  },
]

export default actionsMap
