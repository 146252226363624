import React, { useEffect } from "react"
import ReactRouterPrompt from "react-router-prompt"
import ConfirmationDialog from "./DirtyFormConfirmationDialog"
import { useTranslation } from "react-i18next"

function ReactRouterPromptComp({ isFormDirty }) {
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isFormDirty) {
        e.preventDefault()
        e.returnValue = ""
      }
    }

    window.addEventListener("beforeunload", handleBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [isFormDirty])
  const { t } = useTranslation()
  return (
    <ReactRouterPrompt when={isFormDirty}>
      {({ isActive, onConfirm, onCancel }) => (
        <ConfirmationDialog
          openTrigger={isActive}
          onCancel={onConfirm}
          onConfirm={onCancel}
          message={t("unsavedChanges")}
          confirmButtonText={t("leave")}
          cancelButtonText={t("stay")}
        />
      )}
    </ReactRouterPrompt>
  )
}

export default ReactRouterPromptComp
