import { Checkbox, FormControlLabel, Stack } from "@mui/material"
import { reduxFormDataHook } from "../reactCustomHooks"

export function MuiCheckbox({ column }) {
  const { accessorKey, required, value, onChange2, t, label } =
    reduxFormDataHook(column)

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <FormControlLabel
        required={required}
        key={`column-${accessorKey}`}
        label={label}
        control={
          <Checkbox
            checked={value || false}
            name={accessorKey}
            onChange={(e) => onChange2( e.target.checked)}
          />
        }
      />
    </Stack>
  )
}
