import * as actionType from "../actionTypes"
import * as API from "../axiosFunctions"
import { dispatch } from "../"
import { socket } from "../../App"

export const setOrders = (data) => {
  dispatch({ type: actionType.SET_ORDERS, payload: data })
  return data
}

export const fetchOrderQuery = async (query) => {
  const data = await API.fetchOrderQuery(query)
  dispatch({ type: actionType.SET_ORDERS, payload: data })
  if (!data.length) deleteOrderReducer()
}

export const createOrder = async (newOrder) => {
  const data = await API.createOrder(newOrder)
  dispatch({ type: actionType.ADD_ORDER, payload: data })
  localStorage.setItem("userOrder", JSON.stringify(data))
}

export const updateOrderClient = async (id, updatedOrder) => {
  const data = await API.updateOrderClient(id, updatedOrder)
  console.log(data)
  dispatch({ type: actionType.UPDATE_ORDER, payload: data })
  localStorage.setItem("userOrder", JSON.stringify(data))
}
export const updateOrder = async (id, updatedOrder) => {
  const data = await API.updateOrder(id, updatedOrder)
  dispatch({ type: actionType.UPDATE_ORDER, payload: data })
}

export const deleteOrder = async (id) => {
  await API.deleteOrder(id)
  dispatch({ type: actionType.DELETE_ORDER, payload: id })
}

export async function initOrder() {
  const localOrder = JSON.parse(localStorage.getItem("userOrder"))

  if (localOrder) {
    const data = await API.getOrderById(localOrder._id)
    if (!data) deleteOrderReducer()
    else dispatch({ type: actionType.SET_ORDERS, payload: [data] })
  }
}

export function deleteOrderReducer() {
  setOrders([])
  localStorage.removeItem("userOrder")
}
