import { SupervisorRole, managerRole, waiterRole } from "../config"
import { store } from "../store"

export const IsClient = () => {
  const role = store?.getState()?.auth?.authData?.role

  return !role
}
export const IsManager = () => {
  const role = store?.getState()?.auth?.authData?.role

  return role === managerRole
}
export const IsWaiter = () => {
  const role = store?.getState()?.auth?.authData?.role

  return role === waiterRole
}
export const IsSupervisor = () => {
  const role = store?.getState()?.auth?.authData?.role

  return role === SupervisorRole
}
