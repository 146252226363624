import swal from "sweetalert2"
import i18n from "../i18n"

const sweetAlertUtils = ({
  title,
  text,
  type,
  dangerMode,
  timer,
  icon,
  html,
  preConfirm,theme
}) =>
  swal.fire({
    title: i18n.t(title),
    text: i18n.t(text),
    html,
    type,
    timer,
    icon: icon || type,
    dangerMode,
    preConfirm: () => {
      preConfirm && preConfirm()
    },
    customClass: {
      container: `sweetalert2-${theme}`, 
    },
  })

export const areYouSureAlert = ({ title, actionFn }) => {
  sweetAlertUtils({
    title: "are-you-sure",
    html: `${i18n.t(
      "are-you-sure-that-you-want-to-continue-this-action"
    )} <strong>${i18n.t(title)}</strong> ?`,
    icon: "warning",
    preConfirm: actionFn,
  })
}

export default sweetAlertUtils
