
export const cartPrice = (cart) => {
  var totalPrice = 0

  cart.forEach((product) => {
    const { extras, price, quantity } = product
    const extrasPrice = extras?.length
      ? extras.reduce((acc, cur) => acc + cur.price, 0)
      : 0

    totalPrice += (extrasPrice + price) * quantity
  })
  return totalPrice 
}
