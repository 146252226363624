import { Grid, Paper, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { moneyCurrency } from "../../../../config"
import { createCart } from "../../../../store/actions/cart"
import { resetSelectedRow } from "../../../../store/actions/misc"
import { MuiDialog } from "../../../../utils/MuiDialog"
import MuiFormMap from "../../../../utils/MuiFormMap"
import CarouselGallery from "../../../../utils/carouselSwiper/CarouselGallery"
import sweetAlertUtils from "../../../../utils/sweetAlertUtils"
import { useMemo } from "react"

function Content() {
  const { selectedRow } = useSelector((state) => state.misc)
  const { orders } = useSelector((state) => state.order)
  const { t } = useTranslation()

  const { name, category, images, description, price, components, extras } =
    selectedRow || {}

  const formColumns = useMemo(
    () => [
      ...(extras?.length
        ? [
            {
              accessorKey: "selectedExtras",
              type: "autocomplete",
              multiple: "true",
              // options: extras,
              options: extras.map((extra) => {
                return {
                  ...extra,
                  optionName: extra.name + " | " + extra.price + moneyCurrency,
                }
              }),
              fullWidth: true,
            },
          ]
        : []),
      {
        accessorKey: "quantity",
        type: "numberInput",
        required: true,
        fullWidth: true,
      },
    ],
    []
  )

  const { formDataMap } = useSelector((state) => state.misc2)
  const { quantity, selectedExtras } = formDataMap

  const extraPrice = selectedExtras?.length
    ? selectedExtras.reduce((acc, cur) => acc + cur.price, 0)
    : 0
  const addItem = () => {
    sweetAlertUtils({
      theme: "dark",
      title: name,
      text: "is-added-to-cart",
      type: "success",
      timer: 2000,
    })
    createCart({
      ...selectedRow,
      extraPrice,
      addedToCart: orders.length ? true : false,
      ...formDataMap,
    })
    resetSelectedRow()
  }

  if (!selectedRow) return <></>
  return (
    <MuiDialog
      theme="dark"
      Content={
        <Paper sx={{ p: 6 }}>
          <Grid container spacing={6}>
            <Grid item sm={6} xs={12}>
              <CarouselGallery images={images} />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Stack spacing={2}>
                <h2>{name}</h2>
                <strong style={{ fontSize: 30 }}>
                  {price?.toFixed(3)} {moneyCurrency}
                </strong>
                <p>
                  <strong>{t("category")}: </strong>
                  {category.name}
                </p>
                <p>{description}</p>
                {components.length !== 0 && (
                  <p>
                    <strong>{t("ingredient")}: </strong>
                    {components.join(", ")}
                  </p>
                )}
                <MuiFormMap
                  theme="dark"
                  additionalData={{
                    quantity: 1,
                  }}
                  columns={formColumns}
                  onSubmit={addItem}
                  options={{
                    resetButtonOptions: { show: false },
                  }}
                />
                <strong style={{ textAlign: "center", fontSize: 30 }}>
                  {" "}
                  {t("total")}: {(price + extraPrice) * quantity}
                  {moneyCurrency}
                </strong>

                {/* {price * formDataMap?.quantity}{" "}
                  {extraPrice
                    ? " + " + extraPrice + " (" + t("extra") + ") "
                    : ""}{" "} */}
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      }
      forceOpen={selectedRow ? true : false}
      maxWidth={"lg"}
      reset={resetSelectedRow}
    ></MuiDialog>
  )
}

const ProductDetails = () => {
  const { selectedRow } = useSelector((state) => state.misc)
  return selectedRow ? <Content /> : <></>
}

export default ProductDetails
