import { Stack } from "@mui/material"
import { useEffect } from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Route, Routes } from "react-router"
import { setCategories } from "../../store/reducers/category"
import { setEcommerceOrdersClient } from "../../store/reducers/ecommerceOrder"
import { setProducts } from "../../store/reducers/product"
import { IsClient } from "../../utils/useIsRole"
import { ClothingScriptJs } from "./ClothingScriptJs"
import "./ClothingScriptJs.js"
import BlogDetail from "./components/BlogDetail"
import { Cart } from "./components/Cart"
import { Categories } from "./components/Categories"
import { Footer } from "./components/Footer"
import { Header } from "./components/Header"
import { Hero } from "./components/Hero"
import ProductModal from "./components/ProductModal"
import { ProductOverview } from "./components/ProductOverview"
import About from "./pages/About"
import Blog from "./pages/Blog"
import Contact from "./pages/Contact"
import EcommerceClientOrdersPage from "./pages/EcommerceClientOrdersPage"
import ShoppingCartPage from "./pages/ShoppingCartPage"
import { setBlogs } from "../../store/reducers/blog"

function ClothingModelHome() {
  return (
    <div>
      <Hero />
      <About />
      <Categories />
      <ProductOverview />
      <ProductModal />
      <Blog />
    </div>
  )
}

function ClothingModel() {
  const { establishment } = useSelector((state) => state.establishment)
  const { cart } = useSelector((state) => state.cart)

  useEffect(() => {
    IsClient() && setEcommerceOrdersClient()

    ClothingScriptJs()
    setProducts()
    setCategories()
    setBlogs()
  }, [])

  return (
    <div style={{ minHeight: "100vh" }}>
      <Helmet title={establishment.name}>
        {/* <!-- Font --> */}
        <link
          rel="stylesheet"
          type="text/css"
          href="/models/clothing/fonts/font-awesome-4.7.0/css/font-awesome.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/models/clothing/fonts/iconic/css/material-design-iconic-font.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/models/clothing/fonts/linearicons-v1.0.0/icon-font.min.css"
        />
        {/* <!-- css --> */}
        <link
          rel="stylesheet"
          type="text/css"
          href="/models/clothing/vendor/bootstrap/css/bootstrap.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/models/clothing/vendor/animate/animate.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/models/clothing/vendor/css-hamburgers/hamburgers.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/models/clothing/vendor/animsition/css/animsition.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/models/clothing/vendor/select2/select2.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/models/clothing/vendor/daterangepicker/daterangepicker.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/models/clothing/vendor/slick/slick.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/models/clothing/vendor/MagnificPopup/magnific-popup.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/models/clothing/vendor/perfect-scrollbar/perfect-scrollbar.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/models/clothing/css/util.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/models/clothing/css/main.css"
        />

        {/* Script */}
        <script src="https://unpkg.com/isotope-layout@3/dist/isotope.pkgd.min.js"></script>
      </Helmet>
      <Header />

      <Routes>
        <Route path="/" element={<ClothingModelHome />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/shop"
          element={
            <div style={{ paddingTop: 50 }}>
              <ProductOverview />
            </div>
          }
        />
        <Route
          path="/blog/:blogId"
          element={
            <div style={{ paddingTop: 50 }}>
              <BlogDetail />
            </div>
          }
        />
        <Route path="/blog" element={<Blog />} />
        {cart.length && (
          <Route path="/shopping_cart" element={<ShoppingCartPage />} />
        )}
        <Route
          path="/ecommerce_order"
          element={<EcommerceClientOrdersPage />}
        />
        <Route
          path="/ecommerce_order/:token"
          element={<EcommerceClientOrdersPage />}
        />
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>

      <Footer />
      {/* Back to top */}
      <div className="btn-back-to-top" id="myBtn">
        <span className="symbol-btn-back-to-top">
          <i className="zmdi zmdi-chevron-up" />
        </span>
      </div>
      <Cart />
    </div>
  )
}

const NotFound = () => {
  const { t } = useTranslation()
  return (
    <Stack
      sx={{ height: "90vh" }}
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <h2>{t("404-not-found")}</h2>
      <p>{t("the-page-you-are-looking-for-does-not-exist")}</p>
    </Stack>
  )
}
export default ClothingModel
