import { Card, CardContent, Chip, Grid, Typography } from "@mui/material"
import dayjs from "dayjs"
import { useSelector } from "react-redux"
import { grayGradient, redGradient } from "gradients"
import {
  setEstablishments,
  updateEstablishmentSupervisor,
} from "../../../store/reducers/establishment"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useConfirm } from "../../../components/ConfirmDialogPromise"
import CountdownTimer from "../../../utils/CountdownTimer"
import { calculateTimeRemaining } from "../../../utils/calculateTimeRemaining"

export function NextPayment() {
  const { establishments } = useSelector((state) => state.establishment)

  useEffect(() => {
    setEstablishments()
  }, [])
  const confirm = useConfirm()

  const handlePayment = async (establishment) => {
    try {
      await confirm({ title: t("confirm") })
      updateEstablishmentSupervisor(establishment._id, {
        payment: dayjs().format("YYYY-MM-DD"),
      })
    } catch (error) {
      return
    }
  }
  const { t } = useTranslation()
  return (
    <div>
      <Grid container spacing={2}>
        {establishments.map((establishment, key) => {
          const lastPaymentDate =
            establishment?.payments?.[establishment?.payments.length - 1]

          const nextPaymentDate = dayjs(lastPaymentDate).add(1, "year")

          const needToPay = dayjs().isAfter(nextPaymentDate)

          return (
            <Grid
              sx={{
                textAlign: "center",
              }}
              item
              xs={6}
              sm={6}
              md={4}
              lg={3}
              key={key}
            >
              <Card>
                <CardContent
                  sx={{
                    color: "#FFFFFF",
                    background: needToPay ? redGradient : grayGradient,
                  }}
                >
                  <Typography variant="h3">{establishment.name}</Typography>
                  {/* <Typography>
                  {order.status === "waiting"
                   ? "Actif (En attente)"
                   : order.status}
                  </Typography> */}
                </CardContent>
              </Card>

              {!needToPay && (
                <Chip
                  color="info"
                  label={
                    <CountdownTimer
                      startDate={dayjs()}
                      endDate={nextPaymentDate}
                    />
                  }
                ></Chip>
              )}
              {needToPay && (
                <Chip
                  onClick={() => handlePayment(establishment)}
                  color="success"
                  label={t("pay")}
                ></Chip>
              )}
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}
