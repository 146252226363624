import jsPDF from "jspdf"
import "jspdf-autotable"

function exportPDF({ fileTitle, title, body, head, html, image, text }) {
  const unit = "pt"
  const size = "A4" // Use A1, A2, A3 or A4
  const orientation = "portrait" // portrait or landscape

  const marginLeft = 40
  const doc = new jsPDF(orientation, unit, size)

  doc.setFontSize(15)

  let y = 10

  title.forEach((line) => {
    y += 20
  })

  let content = {
    startY: y,
    head,
    bodyStyles: { minCellHeight: 100 },
    body,
    html,
    didDrawCell: function (data) {
      if (html && data.column.index === 0 && data.cell.section === "body") {
        var td = data.cell.raw
        var img = td.getElementsByTagName("img")[0]
        var textPos = data.cursor
        doc.addImage(img?.src, textPos.x, textPos.y, 100, 100)
      }
    },
    styles: {
      // Customize the cell styles
      cellPadding: { top: 8, right: 8, bottom: 8, left: 8 },
      fontSize: 12,
      halign: "center", // Center align the text horizontally
      valign: "middle", // Center align the text vertically
    },
  }
  doc.text(title, marginLeft, 40)
  doc.autoTable(content)

  var img = new Image()
  img.src = image
  doc.addImage(img, "PNG", 440, 10, 125, 125)

  let finalY = doc.lastAutoTable.finalY
  doc.text(["Mode de paiement:","à livraison"], 40, finalY + 20)
  doc.text(text, 400, finalY + 20)

  doc.save((fileTitle || title) + ".pdf")
}

export default exportPDF
