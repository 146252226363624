const { isLocalHost } = require("../../store/axiosFunctions")
const { setFormDataMap } = require("../../store/reducers/misc2")

if (isLocalHost) {
  let lastKeyPressTime = 0

  document.addEventListener("keydown", function (event) {
    const currentTime = new Date().getTime()
    const getLastForm = () => {
      const result = JSON.parse(localStorage.getItem("lastForm"))
      console.log(result[window.location.href])
      setFormDataMap(result[window.location.href])
    }

    if (event.key === "F2" && currentTime - lastKeyPressTime < 500) {
      console.log(`Key ${event.key} pressed twice within 1 second`)
      getLastForm()
    }

    lastKeyPressTime = currentTime
  })
}
