const { useState, useEffect } = require("react")

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return scrollPosition
}

export const detectEndPage = () => {
  // Get the current scroll position
  const scrollY = useScrollPosition()

  // Get the total height of the page, including the part not visible
  const totalHeight = document.documentElement.scrollHeight

  // Get the height of the visible part of the page
  const visibleHeight = window.innerHeight

  // Calculate the remaining height to scroll
  const remainingHeight = totalHeight - (scrollY + visibleHeight)

  // Define a threshold (e.g., 100 pixels) to consider as "reached the end"
  const threshold = 1

  // Check if the remaining height is less than the threshold
  return remainingHeight < threshold
}
