import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

// material-ui
import { AppBar, Grid, Toolbar, Typography } from "@mui/material"

// project imports
import { useTranslation } from "react-i18next"
import MainCard from "../MainCard"
// ==============================|| BREADCRUMBS ||============================== //

const Breadcrumbs = ({ navigation, title, ...others }) => {
  const { t } = useTranslation()

  const location = useLocation()
  const [main, setMain] = useState()
  const [item, setItem] = useState()

  // set active item state
  const getCollapse = (menu) => {
    if (menu.children) {
      menu.children.filter((collapse) => {
        if (collapse.type && collapse.type === "collapse") {
          getCollapse(collapse)
        } else if (collapse.type && collapse.type === "item") {
          if (location.pathname === "/dashboard/" + collapse.path) {
            setMain(menu)
            setItem(collapse)
          }
        }
        return false
      })
    }
  }

  useEffect(() => {
    navigation?.map((menu) => {
      if (menu.type && menu.type === "group") {
        getCollapse(menu)
      }
      return false
    })
  })

  // only used for component demo breadcrumbs
  if (location.pathname === "/breadcrumbs") {
    location.pathname = "/dashboard/analytics"
  }

  let mainContent
  let itemContent
  let breadcrumbContent = <Typography />
  let itemTitle = ""

  // collapse item
  if (main && main.type === "collapse") {
    mainContent = (
      <Typography
        component={Link}
        to={document.location.pathname}
        variant="h6"
        sx={{ textDecoration: "none" }}
        color="textSecondary"
      >
        {main.title}
      </Typography>
    )
  }

  // items
  if (item && item.type === "item") {
    itemTitle = item.title
    itemContent = (
      <Typography variant="subtitle1" color="textPrimary">
        {itemTitle}
      </Typography>
    )
    const Icon = item.icon
    // main
    if (item.breadcrumbs !== false) {
      breadcrumbContent = (
        <MainCard
          border={false}
          sx={{
            mb: 3,
            bgcolor: "transparent",
            boxShadow: 3,

            transition: "all 0.3s", // Adding a transition effect for smoother hover

            "&:hover": {
              transform: "scale(1.02)",
              boxShadow: 5,
            },
          }}
          {...others}
          content={false}
        >
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            {/* <Grid item>
              <MuiBreadcrumbs aria-label="breadcrumb">
                <Typography component={Link} to="/" color="textSecondary" variant="h6" sx={{ textDecoration: 'none' }}>
                  Accueil
                </Typography>
                {mainContent}
                {itemContent}
              </MuiBreadcrumbs>
            </Grid> */}
            {title && (
              <Grid item sx={{ width: "100%", p: 0 }}>
                <AppBar
                  position="static"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #00395d, #8f8f8c)",
                  }}
                >
                  <Toolbar>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        fontFamily: "Poppins",
                        margin: "auto",
                      }}
                      variant="h3"
                    >
                      <Icon /> {t(item.title)}
                    </Typography>
                  </Toolbar>
                </AppBar>
                {/* <Paper sx={{ p: 2 }}>
                  <Typography variant="h4">{item.title}</Typography>
                </Paper> */}
              </Grid>
            )}
          </Grid>
        </MainCard>
      )
    }
  }

  return breadcrumbContent
}

Breadcrumbs.propTypes = {
  navigation: PropTypes.array,
  title: PropTypes.bool,
}

export default Breadcrumbs
