import { createRoot } from "react-dom/client"

import "simplebar/src/simplebar.css"

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/zoom';


// third-party
import { Provider as ReduxProvider } from "react-redux"

// apex-chart
import "assets/third-party/apex-chart.css"

// project import
import { store } from "store"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "utils/doubleTap"
import "utils/devTools"
// ==============================|| MAIN - REACT DOM RENDER  ||============================== //
document.addEventListener("wheel", function (event) {
  if (document.activeElement.type === "number") {
    document.activeElement.blur()
  }
})
const container = document.getElementById("root")
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
