import PropTypes from "prop-types"

// material-ui
import { Box, Grid } from "@mui/material"

// project import
import AuthCard from "./AuthCard"
import Logo from "components/Logo"
import AuthFooter from "components/cards/AuthFooter"

// assets
import AuthBackground from "assets/images/auth/AuthBackground"
import LanguageSwitcher from "../../components/LanguageSwitcher"

// ==============================|| AUTHENTICATION - WRAPPER ||============================== //

const AuthWrapper = ({ children }) => (
  <>
    <Box sx={{ height: "97vh" }}>
      <AuthBackground />
      <Grid
        container
        direction="column"
        justifyContent="center"
        sx={{
          position: "relative",
          minHeight: "100vh",
        }}
      >
        {/* <Grid item xs={12} sx={{ ml: 3, mt: 3 }}> */}
        {/* <div style={{ position: "absolute", top: 20, left: 20 }}>
          <Logo  width={125} /> */}
        {/* </div> */}
        {/* </Grid> */}
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            // sx={{ minHeight: { xs: 'calc(100vh - 134px)', md: 'calc(100vh - 203px)' } }}
          >
            <Grid item>
            <LanguageSwitcher />
              <AuthCard>{children}</AuthCard>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={12} sx={{ m: 3, mt: 1 }}> */}
        {/* </Grid> */}
      </Grid>
    </Box>
        <div style={{ position: "absolute", bot: 50, left: 10 }}>
          <AuthFooter />
        </div>
  </>
)

AuthWrapper.propTypes = {
  children: PropTypes.node,
}

export default AuthWrapper
