import i18n from "../i18n"
import { changeDayjsLanguage } from "./dates"

export const changeLocales = (lng) => {
  // console.log("Language changed to: " + lng)
  i18n.changeLanguage(lng)
  changeDayjsLanguage(i18n.language)
}
export const supportedLanguages = [
  { code: "english", name: "English", flag: "/dashboard/images/en.png" },
  { code: "fr", name: "Français", flag: "/dashboard/images/fr.png" },
  { code: "ar", name: "العربية", flag: "/dashboard/images/ar.png" },
]
