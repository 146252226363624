import { createSlice } from "@reduxjs/toolkit"
import { axiosAPI } from "../axiosFunctions"
import { dispatch } from ".."

const initialState = {
  users: [],
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsersReducer(state, action) {
      state.users = action.payload
    },

    addUserReducer(state, action) {
      state.users.unshift(action.payload)
    },

    updateUserReducer(state, action) {
      state.users = state.users.map((document) =>
        document._id === action.payload._id ? action.payload : document
      )
    },

    deleteUserReducer(state, action) {
      state.users = state.users.filter(
        (document) => document._id !== action.payload
      )
    },
  },
})

const {
  setUsersReducer,
  addUserReducer,
  updateUserReducer,
  deleteUserReducer,
} = userSlice.actions

/* #region  Manager */
export const setUsers = (query) =>
  axiosAPI
    .get("/user/", { params: query })
    .then((data) => dispatch(setUsersReducer(data)))

export const addUser = (body) =>
  axiosAPI
    .post("/user/", body)
    .then((data) => data && dispatch(addUserReducer(data)))
    .catch((e) => Promise.reject(e))

export const updateUser = (id, body) =>
  axiosAPI
    .patch(`/user/${id}`, body)
    .then((data) => data && dispatch(updateUserReducer(data)))
    .catch((e) => Promise.reject(e))

export const deleteUser = (id) =>
  axiosAPI.delete(`/user/${id}`).then((_) => dispatch(deleteUserReducer(id)))
/* #endregion */

/* #region  Supervisor */
export const fetchUserQuerySupervisor = (query) =>
  axiosAPI
    .get("/user/fetchUserQuerySupervisor", { params: query })
    .then((data) => dispatch(setUsersReducer(data)))

export const createManager = (body) =>
  axiosAPI
    .post("/user/createManager/", body)
    .then((data) => data && dispatch(addUserReducer(data)))
    .catch((e) => Promise.reject(e))

export const updateManager = (id, body) =>
  axiosAPI
    .patch(`/user/updateManager/${id}`, body)
    .then((data) => data && dispatch(updateUserReducer(data)))
    .catch((e) => Promise.reject(e))

export const deleteManager = (id) =>
  axiosAPI
    .delete(`/user/deleteManager/${id}`)
    .then((_) => dispatch(deleteUserReducer(id)))
/* #endregion */

export default userSlice.reducer
