import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormLabel from "@mui/material/FormLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import { reduxFormDataHook } from "../reactCustomHooks"

export default function MuiRadioButton({ column }) {
  const { t, value, onChange, accessorKey, required, options, disabled } =
    reduxFormDataHook(column)
  return (
    <FormControl>
      <FormLabel>{t(accessorKey)}</FormLabel>
      <RadioGroup
        defaultValue={options[0]}
        name={accessorKey}
        value={value}
        onChange={onChange}
      >
        {options.map((option, key) => (
          <FormControlLabel
            key={key}
            value={option}
            control={<Radio required={required} disabled={disabled} />}
            label={t(option)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
