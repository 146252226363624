import styled from "@emotion/styled"
import CloseIcon from "@mui/icons-material/Close"
import {
  Box,
  IconButton,
  ThemeProvider
} from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import * as React from "react"
import { useTranslation } from "react-i18next"
import { darkTheme, lightTheme } from "../config"
import { tradSentence } from "./tradSentence"
import useIsMobile from "./useIsMobile"

const CustomDialogTitle = styled(DialogTitle)({
  fontSize: "24px",
  fontWeight: "bold",
  color: "white",
  background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  padding: "16px",
  paddingBottom: "24px",
  borderRadius: "4px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
  fontFamily: "Poppins",
  // marginBottom: 32,
  overflowX: "hidden",
  transition: "transform 0.2s",
  // "&:hover": {
  //   transform: "scale(1.05)",
  // },
})

export function MuiDialog({
  title,
  Content,
  children,
  forceOpen,
  maxWidth = "sm",
  reset,
  theme = "light",
}) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(!open)
  }

  const onClose = () => {
    setOpen(false)
    reset && reset()
  }
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const noForceOpen = forceOpen === undefined

  return (
    <>
      {noForceOpen &&
        React.cloneElement(children, { onClick: handleClickOpen })}
      <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
        <Dialog
          onClose={onClose}
          open={forceOpen || open}
          fullWidth
          fullScreen={isMobile}
          maxWidth={maxWidth}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            style={{ zIndex: 100, position: "absolute", top: 10, right: 20 }}
          >
            <CloseIcon />
          </IconButton>
          {typeof title === "string" ? (
            <CustomDialogTitle>
              <div style={{ padding: 0 }} className="section-title">
                <p>{tradSentence(title)}</p>
              </div>
            </CustomDialogTitle>
          ) : (
            title
          )}
          {/* <DialogContent> */}
          <Box sx={{ pt: 0 }}>{Content}</Box>
          {/* {Content && React.cloneElement(Content, { handleClickOpen: handleClickOpen })} */}
          {/* {noForceOpen ? <Content handleClickOpen={handleClickOpen} /> : children} */}
          {/* </DialogContent> */}
        </Dialog>
      </ThemeProvider>
    </>
  )
}
