import { ThemeProvider } from "@emotion/react"
import CloseIcon from "@mui/icons-material/Close"
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  createTheme,
} from "@mui/material"
import dayjs from "dayjs"
import { useState } from "react"
import { useSelector } from "react-redux"
import OrangeButton from "../../../../../../components/Buttons/orangeButton/OrangeButton"
import { AvatarPath } from "../../../../../../components/ImagePath"
import { moneyCurrency, orderStatusServed } from "../../../../../../config"
import TypographyTrad from "../../../../../../utils/TypographyTrad."

const Bill = ({ order, handlePay }) => {
  const [open, setOpen] = useState(false)
  const { establishment } = useSelector((state) => state.establishment)
  const queryParams = new URLSearchParams(location.search)
  const tableNumber = queryParams.get("tableNumber")
  const handleOpen = () => {
    setOpen(!open)
  }

  const disabledPayButton = order.status !== orderStatusServed

  const { cart, price, waiter } = order

  const whiteTheme = createTheme({
    typography: {
      // color: "black",
      fontSize: 16,
    },
    palette: {
      // mode: "dark",
      // text: {
      //   primary: "#000",
      //   secondary: "#BBB",
      //   disabled: "#777",
      // },
    },
  })

  return (
    <ThemeProvider theme={whiteTheme}>
      <Stack
        className="pointerCursor"
        onClick={() => setOpen(true)}
        direction="row"
        alignItems="center"
      >
        <IconButton>
          <ReceiptLongIcon
            sx={{
              fontSize: 50,
              color: "white",
            }}
          />
        </IconButton>
        <TypographyTrad>bill</TypographyTrad>
      </Stack>
      <Dialog maxWidth="xs" fullWidth open={open} onClose={handleOpen}>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleOpen}
          style={{ position: "absolute", top: 10, right: 20 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle sx={{ pb: 0 }}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <AvatarPath
              sx={{ width: 75, height: 75 }}
              src={establishment.logo}
            />
            <h1 className="logo me-auto me-lg-0">
              <a href="/auth/login">{establishment.name}</a>
            </h1>
          </Stack>
        </DialogTitle>
        <Divider></Divider>

        <DialogContent sx={{ pt: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ width: "100%" }}
            >
              <img
                width="50px"
                src="/models/restaurant/assets/img/table-de-cercle.png"
                alt=""
              />
              <Typography variant="subtitle1" gutterBottom>
                Table: {tableNumber}
              </Typography>
            </Stack>

            <Typography
              sx={{ textAlign: "center" }}
              variant="subtitle2"
              gutterBottom
            >
              {dayjs().format("DD/MM/YYYY")}
              <br />
              {dayjs().format("HH:mm")}
            </Typography>
          </Stack>
          <Divider />
          {/* <Typography variant="subtitle2" gutterBottom>
              Reference: {reference}
            </Typography> */}
          <TableContainer style={{ color: "black" }}>
            <Table style={{ color: "black" }}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cart.map((item, index) => {
                  var extraString
                  if (item?.extras.length)
                    extraString =
                      "(" +
                      item?.extras.map((extra) => extra.name).join(", ") +
                      ")"

                  return (
                    <TableRow key={index}>
                      <TableCell>
                        {item.name}
                        {extraString}
                      </TableCell>
                      <TableCell align="right"> X {item.quantity}</TableCell>
                      <TableCell align="right">
                        {(item.price + item.extraPrice) * item.quantity}{" "}
                        {moneyCurrency}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <Typography
              variant="h6"
              gutterBottom
              style={{ textAlign: "left", marginTop: "20px" }}
            >
              Total: {price + moneyCurrency}
            </Typography>
            <img
              width="50px"
              src="/models/restaurant/assets/img/money.png"
              alt=""
            />
          </Stack>
          <Stack
            direction="row"
            //  justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <img
              width="50px"
              src="/models/restaurant/assets/img/serveur.png"
              alt=""
            />
            <Typography
              variant="body2"
              gutterBottom
              style={{ textAlign: "left", marginTop: "20px" }}
            >
              {waiter?.firstName + " " + waiter?.lastName}
            </Typography>
          </Stack>
          <Divider sx={{ my: 2 }} />

          <OrangeButton
            onClick={() => {
              handlePay()
              handleOpen()
            }}
            style={{ margin: "auto" }}
          >
            pay
            {/* {disabledPayButton ?"youCanPayOnceServed" : "pay"} */}
          </OrangeButton>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  )
}

export default Bill
