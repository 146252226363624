import React, { useEffect, useState } from "react"
import {
  TextField,
  Popover,
  InputAdornment,
  IconButton,
  styled,
  Box,
} from "@mui/material"
import DateRangeIcon from "@mui/icons-material/DateRange"
import moment from "moment"
import { DateRange } from "react-date-range"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file
import { useTranslation } from "react-i18next"

const CalendarWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
}))

function IntervalPicker({ setData, defaultRows }) {
  const dateFormat = "DD/MM/YYYY"

  const [displayCalendar, setDisplayCalendar] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [anchorEl, setAnchorEl] = useState(null)
  const [fromDate, setFromDate] = useState(undefined)
  const [toDate, setToDate] = useState(undefined)

  const onAdornmentClick = (e) => {
    setDisplayCalendar(true)
    setAnchorEl(e.currentTarget)
  }


  useEffect(() => {
    if (!inputValue) {
      // If inputValue is empty, set the data to the default rows
      setData(defaultRows);
      return;
    }

    const [startDateStr, endDateStr] = inputValue.split(" - ");

    // Specify the date format when parsing
    const startDate = moment(startDateStr, "DD/MM/YYYY");
    const endDate = moment(endDateStr, "DD/MM/YYYY").add(1, "days");

    
    const filterProcess = defaultRows.filter((row) =>
      moment(row.createdAt).isBetween(startDate, endDate, null, "[]")
    );

    setData(filterProcess);
  }, [inputValue, defaultRows]);

  const onInputChange = (e) => {
    const value = e.target.value
    const { fromDate, toDate } = processInputValue(value)

    setInputValue(value)
    setFromDate(fromDate)
    setToDate(toDate)
  }

  const onPopoverClose = (e, reason) => {
    setDisplayCalendar(false)
    setAnchorEl(null)
  }

  const onSelectDateRanges = ({ selection }) => {
    let { startDate, endDate } = selection

    startDate = moment(startDate)
    startDate = startDate.isValid() ? startDate.toDate() : undefined

    endDate = moment(endDate)
    endDate = endDate.isValid() ? endDate.toDate() : undefined

    let inputValue = ""
    if (startDate) inputValue += moment(startDate).format(dateFormat)
    if (endDate) inputValue += " - " + moment(endDate).format(dateFormat)

    setInputValue(inputValue)
    setFromDate(startDate)
    setToDate(endDate)
  }

  const processInputValue = (value) => {
    let [fromDate, toDate] = value.split("-").map((elm) => elm.trim())

    fromDate = moment(fromDate, dateFormat)
    fromDate = fromDate.isValid() ? fromDate.toDate() : undefined

    toDate = moment(toDate, dateFormat)
    toDate = toDate.isValid() ? toDate.toDate() : undefined

    return { fromDate, toDate }
  }
const { t } = useTranslation()
  return (
    <Box  sx={{mb :2}} >
      <TextField
        label={t("dateInterval")}
        fullWidth={true}
        value={inputValue}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={onAdornmentClick}>
                <DateRangeIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={onInputChange}
      />
      <Popover
        open={displayCalendar}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={onPopoverClose}
      >
        <CalendarWrapper>
          <DateRange
            ranges={[
              {
                startDate: fromDate,
                endDate: toDate,
                key: "selection",
              },
            ]}
            onChange={onSelectDateRanges}
            // maxDate={new Date()}
            showMonthAndYearPickers={true}
            moveRangeOnFirstSelection={false}
            showDateDisplay={false}
            scroll={{ enabled: true }}
          />
        </CalendarWrapper>
      </Popover>
    </Box>
  )
}

export default IntervalPicker
