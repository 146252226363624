import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { formatPhoneNumberWithSpaces } from "../../../../../../utils/formatText"
import OrangeButton from "../../../../../../components/Buttons/orangeButton/OrangeButton"
import { createClaim } from "../../../../../../store/actions/claim"
function ClaimForm({ handleClickOpen }) {
  const { establishment } = useSelector((state) => state.establishment)
  const { orders } = useSelector((state) => state.order)
  const { t } = useTranslation()
  const { tableNumber } = useSelector((state) => state.misc)

  // Initialize state variables for form fields
  const [formData, setFormData] = useState({
    tableNumber: "",
    type: "",
    message: "",
    establishment,
    tableNumber,
  })

  // Handle changes in form fields
  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()

    createClaim(formData)
    handleClickOpen()
  }
  const types = ["delay", "quality", "other"]

  if (!orders) return <></>

  return (
    <section style={{ padding: 0 }} id="contact" className="contact">
      <div className="container" data-aos="fade-up">
        <form role="form" className="php-email-form" onSubmit={onSubmit}>
          <div className="row">
            {/* <div className="col-md-6 form-group">
                    <input
                      type="number"
                      name="tableNumber"
                      className="form-control"
                      id="tableNumber"
                      placeholder={t("tableNumberPlaceholder")}
                      required
                      value={formData.tableNumber} // Bind value to state
                      onChange={handleChange} // Handle change
                    />
                  </div> */}
            <div className="col-md-6 form-group">
              <label htmlFor="">{t("type")} </label>
              <select
                className="form-select"
                name="type"
                id="type"
                required
                value={formData.type} // Bind value to state
                onChange={handleChange} // Handle change
              >
                <option value="" disabled defaultValue>
                  {t("typePlaceholder")}
                </option>

                {types.map((type) => (
                  <option value={type}>{t(type)}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group mt-3">
            <label htmlFor="">{t("message")} </label>

            <textarea
              className="form-control"
              name="message"
              rows={8}
              placeholder={t("messagePlaceholder")}
              required
              value={formData.message} // Bind value to state
              onChange={handleChange} // Handle change
            />
          </div>
          <div className="centerButton">
            <OrangeButton type="submit">{t("send")}</OrangeButton>
          </div>
        </form>
      </div>
    </section>
  )
}

export default ClaimForm
