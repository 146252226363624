import * as React from "react"
import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import { useTranslation } from "react-i18next"


export default function MuiStepper({ steps, active = 1 }) {
  const { t } = useTranslation()
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper orientation="vertical" activeStep={active} >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{t(label)}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
