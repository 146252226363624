import i18n from "i18next"

export const tradSentence = (key) => {
  const translation = i18n.t(key)

  if (key === translation) {
    const words = key.replaceAll(".", "").split("|")

    const translatedWords = words.map(
      (word) => (i18n.t(word) === "" ? word : i18n.t(word))
      )

    return translatedWords.join("")
  } else return translation
}
