import { socket } from "../App"
import { socketRefreshInterval } from "../config"
import { dispatch } from "../store"
import { joinRoom } from "./socket"

export const waiterSocket = (authData) => {
  if (authData?.accessToken) {
    joinRoom(authData.establishment)
    console.log("Connecting registerUserToTheList")
    socket.emit("registerUserToTheList", authData.accessToken)

    setInterval(() => {
      console.log("Connecting registerUserToTheList")
      socket.emit("registerUserToTheList", authData.accessToken)
    }, socketRefreshInterval)

    // socket?.on("redux-update", (dataToDispatch) => {
    //   const { payload, model, method } = dataToDispatch
    //   const type =
    //     model + "/" + crudMap[method] + capitalizeFirstLetter(model) + "Reducer"
    //   dispatch({ type, payload })
    // })
  }
}

export const managerSocket = (authData) => {
    joinRoom(authData.establishment)
    socket?.on("redux-update-manager", (dataToDispatch) => {
      receiveData(dataToDispatch)
    })
}

export const clientInitSocket = () => {
  const clientEcommerceOrderToken = localStorage.getItem(
    "clientEcommerceOrderToken"
  )
  const queryParams = new URLSearchParams(location.search)
  const establishmentId = queryParams.get("establishmentId")
  joinRoom(establishmentId)

  if (clientEcommerceOrderToken)
    socket.emit("registerClient", clientEcommerceOrderToken)

  socket?.on("redux-update", (dataToDispatch) => {
    receiveData(dataToDispatch)
  })
}

export const clientConnectWithWaiterSocket = (order) => {
  if (!order) return
  console.log("Connecting registerClient")
  socket.emit("registerClient", order._id)
  const keepConnection = setInterval(() => {
    console.log("Connecting registerClient")
    socket.emit("registerClient", order._id)
  }, socketRefreshInterval)
  if (!order) return clearInterval(keepConnection)
}

const crudMap = {
  POST: "add",
  PATCH: "update",
  DELETE: "delete",
}

function receiveData(dataToDispatch) {
  const { payload, model, method } = dataToDispatch
  console.log(dataToDispatch)
  const type = model + "/" + crudMap[method] + capitalizeFirstLetter(model) + "Reducer"
  dispatch({ type, payload })
}

function capitalizeFirstLetter(string) {
  if (!string) return ""
  return string.charAt(0).toUpperCase() + string.slice(1)
}
