const playSound = (src) => {
  const audio = new Audio(src);

  // Function to play the audio
  const playAudio = () => {
    audio.play().then(() => {
      console.log("Sound is playing, src: " + src);
    }).catch((error) => {
      console.error('Error playing audio:', error);
    });
  };

  // Event listener for a user interaction (e.g., click)
  const playHandler = () => {
    playAudio();
    // Remove the event listener after the first interaction
    document.removeEventListener('click', playHandler);
  };

  // Add the event listener to play the audio on user interaction
  document.addEventListener('click', playHandler);

  // Return cleanup function
  return () => {
    audio.pause();
    audio.currentTime = 0;
    // Remove the event listener if cleanup is called
    document.removeEventListener('click', playHandler);
  };
};
const bellsNotification = () => playSound("/sounds/bellsNotification.wav")

const notificationSound = () => playSound("/sounds/reduxUpdateSound.wav")

const doorBellSound = () => playSound("/sounds/doorBell.wav")

export { bellsNotification, notificationSound, doorBellSound }
