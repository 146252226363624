import { logoutCleanup } from "../../utils/logoutCleanup"
import * as actionType from "../actionTypes"

export default (
  state = {
    authData: JSON.parse(localStorage.getItem("localUserProfile")),
  },
  action
) => {
  const payload = action.payload
  if (state.authData === "error") localStorage.removeItem("localUserProfile")
  switch (action.type) {
    case actionType.AUTH:
      localStorage.setItem("localUserProfile", JSON.stringify(payload))

      return { authData: payload }

    case actionType.LOGOUT:
      logoutCleanup()
      return { authData: null }

    default:
      return state
  }
}
