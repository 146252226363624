// material-ui
import { styled } from "@mui/material/styles"
import Drawer from "@mui/material/Drawer"

// project import
import { drawerWidth } from "config"
import { drawerColor } from "../../../config"

const drawerBgImage = "/dashboard/images/drawerBg.avif"

const openedMixin = (theme) => ({
  width: drawerWidth,
  // "& *": {
  //   color: "#FFFFFF",
  // },
  background: drawerColor + `, url(${drawerBgImage})`,
  boxShadow:
    "rgba(0, 0, 0, 0.06) 0px 5px 5px -3px, rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px",

  borderRight: `0px solid ${theme.palette.divider}`,
  // borderRight: `1px solid ${theme.palette.divider}`,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  // boxShadow: "none",
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: drawerColor + `, url(${drawerBgImage})`,

  overflowX: "hidden",
  width: 0,
  borderRight: "none",
  boxShadow: theme.customShadows.z1,
})

// ==============================|| DRAWER - MINI STYLED ||============================== //

const MiniDrawerStyled = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}))

export default MiniDrawerStyled
