import * as actionType from "../actionTypes"
import * as API from "../axiosFunctions"
import { dispatch } from "../"

export const fetchClaimQuery = async (query) => {
  const data = await API.fetchClaimQuery(query)
  dispatch({ type: actionType.SET_CLAIMS, payload: data })
}

export const createClaim = async (newClaim) => {
  const data = await API.createClaim(newClaim)
  await dispatch({ type: actionType.ADD_CLAIM, payload: data })
}

export const updateClaim = async (id, updatedClaim) => {
  const data = await API.updateClaim(id, updatedClaim)
  await dispatch({ type: actionType.UPDATE_CLAIM, payload: data })
}

export const deleteClaim = async (id) => {
  await API.deleteClaim(id)
  await dispatch({ type: actionType.DELETE_CLAIM, payload: id })
}
