import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { ImgPath } from "../../../components/ImagePath"
import { moneyCurrency } from "../../../config"
import { setCart, updateCart } from "../../../store/actions/cart"
import { addEcommerceOrder } from "../../../store/reducers/ecommerceOrder"
import MuiFormMap from "../../../utils/MuiFormMap"
import { cartPrice } from "../../../utils/calculatePrices"
import sweetAlertUtils from "../../../utils/sweetAlertUtils"
import { IsManager } from "../../../utils/useIsRole"
import { toast } from "react-toastify"

const ShippingFormColumns = [
  {
    accessorKey: "firstName",
    required: true,
    pattern: "[a-zA-Z]+",
    maxLength: 32,
  },
  {
    accessorKey: "lastName",
    required: true,
    pattern: "[a-zA-Z]+",
    maxLength: 32,
  },
  {
    accessorKey: "address",
    required: true,
    maxLength: 32,
  },
  {
    accessorKey: "postalCode",
    required: true,
    pattern: "[0-9]{4}",
    maxLength: 4,
  },
  {
    accessorKey: "email",
    type: "email",
    required: true,
    maxLength: 32,
  },
  {
    accessorKey: "phoneNumber",
    required: true,
    pattern: "[0-9]{8}",
    maxLength: 8,
  },
  {
    accessorKey: "paymentMethod",
    type: "radioButton",
    options: ["onDelivery"],
    // options: ["withCard", "onDelivery"],
    required: true,
  },
  {
    accessorKey: "orderComment",
    fullWidth: true,
    multiline: true,
  },
]

function ShippingForm() {
  const { cart } = useSelector((state) => state.cart)
  const { formDataMap } = useSelector((state) => state.misc2)
  const { establishment } = useSelector((state) => state.establishment)

  const totalPrice = cartPrice(cart)
  const navigate = useNavigate()

  const { t } = useTranslation()
  // const buyerData = userEcommerceOrder?.buyerData

  const onSubmit = async () => {
    if (IsManager()) return toast.error(t("you-are-in-manager-mode"))
    const data = {
      cart,
      buyerData: formDataMap,
    }
    await addEcommerceOrder(data)
    setCart([])
    navigate("../ecommerce_order")

    sweetAlertUtils({
      title: "order-received",
      text: "we-will-treat-your-order-within-a-short-time-an-email-will-be-sent-for-further-instructions",
      type: "success",
    })
  }

  return (
    <div className="col-sm-10 col-lg-7 col-xl-5 m-lr-auto m-b-50">
      <div className="bor10 p-lr-40 p-t-30 p-b-40 m-l-63 m-r-40 m-lr-0-xl p-lr-15-sm">
        <h4 className="mtext-109 cl2 p-b-30">{t("cart-totals")}</h4>
        <MuiFormMap
          columns={ShippingFormColumns}
          onSubmit={onSubmit}
          options={{
            resetButtonOptions: { show: false },
            confirmButtonOptions: { show: false },
          }}
        />

        <div className="flex-w flex-t p-t-27 p-b-33">
          <div className="size-208">
            <span className="mtext-101 cl2">{t("subTotal")}:</span>
          </div>
          <div className="size-209 p-t-1">
            <span className="mtext-110 cl2">{totalPrice + moneyCurrency}</span>
          </div>
          <div className="size-208">
            <span className="mtext-101 cl2">{t("deliveryFee")}:</span>
          </div>
          <div className="size-209 p-t-1">
            <span className="mtext-110 cl2">
              {establishment.deliveryFee + moneyCurrency}
            </span>
          </div>
          <div className="size-208">
            <span className="mtext-101 cl2">{t("total")}:</span>
          </div>
          <div className="size-209 p-t-1">
            <span className="mtext-110 cl2">
              {totalPrice + establishment.deliveryFee + moneyCurrency}
            </span>
          </div>
        </div>
        <button
          form="formMap"
          type="submit"
          className="flex-c-m stext-101 cl0 size-116 bg3 bor14 hov-btn3 p-lr-15 trans-04 pointer"
        >
          {t("proceed-to-checkout")}{" "}
        </button>
      </div>
    </div>
  )
}

function ShoppingCartPage() {
  const { t } = useTranslation()
  const { cart } = useSelector((state) => state.cart)

  return (
    <div>
      <div className="container">
        <div className="bread-crumb flex-w p-l-25 p-r-15 p-t-30 p-lr-0-lg">
          <Link to="../" className="stext-109 cl8 hov-cl1 trans-04">
            {t("header.home")}{" "}
            <i className="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true" />
          </Link>
          <span className="stext-109 cl4">{t("shoping-cart")}</span>
        </div>
      </div>

      <div className="bg0 p-t-75 p-b-85">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-xl-7 m-lr-auto m-b-50">
              <div className="m-l-25 m-r--38 m-lr-0-xl">
                <div className="wrap-table-shopping-cart">
                  <table className="table-shopping-cart">
                    <tbody>
                      <tr className="table_head">
                        <th className="column-1">
                          {t("gestionDuStockItems.product")}
                        </th>
                        <th className="column-2" />
                        <th className="column-3">{t("foodDetails.price")}</th>
                        <th className="column-4">{t("quantity")}</th>
                        <th className="column-5">{t("total")}</th>
                      </tr>
                      {cart.map((product, key) => {
                        const handleDecrease = () => {
                          updateCart({
                            ...product,
                            quantity: quantity - 1,
                          })
                        }

                        const handleIncrease = () => {
                          updateCart({
                            ...product,
                            quantity: quantity + 1,
                          })
                        }

                        const {
                          name,
                          price,
                          images,
                          description,
                          size,
                          color,
                          quantity,
                          category,
                        } = product || {}
                        return (
                          <tr key={key} className="table_row">
                            <td className="column-1">
                              <div className="how-itemcart1">
                                <ImgPath src={images} alt="IMG" />
                              </div>
                            </td>
                            <td className="column-2">{name}</td>
                            <td className="column-3">
                              {price + moneyCurrency}
                            </td>
                            <td className="column-4">
                              <div className="wrap-num-product flex-w m-l-auto m-r-0">
                                <div
                                  onClick={handleDecrease}
                                  className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m"
                                >
                                  <i className="fs-16 zmdi zmdi-minus" />
                                </div>
                                <input
                                  className="mtext-104 cl3 txt-center num-product"
                                  type="number"
                                  name="num-product1"
                                  value={quantity}
                                />
                                <div
                                  onClick={handleIncrease}
                                  className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m"
                                >
                                  <i className="fs-16 zmdi zmdi-plus" />
                                </div>
                              </div>
                            </td>
                            <td className="column-5">
                              {quantity * price} {moneyCurrency}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                {/* <div className="flex-w flex-sb-m bor15 p-t-18 p-b-15 p-lr-40 p-lr-15-sm">
                  <div className="flex-w flex-m m-r-20 m-tb-5">
                    <input
                      className="stext-104 cl2 plh4 size-117 bor13 p-lr-20 m-r-10 m-tb-5"
                      type="text"
                      name="coupon"
                      placeholder="Coupon Code"
                    />
                    <div className="flex-c-m stext-101 cl2 size-118 bg8 bor13 hov-btn3 p-lr-15 trans-04 pointer m-tb-5">
                      Apply coupon
                    </div>
                  </div>
                  <div className="flex-c-m stext-101 cl2 size-119 bg8 bor13 hov-btn3 p-lr-15 trans-04 pointer m-tb-10">
                    Update Cart
                  </div>
                </div> */}
              </div>
            </div>
            <ShippingForm></ShippingForm>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShoppingCartPage
