import axios from "axios"
import { axiosInterceptorResponse } from "./axiosInterceptorResponse"
import { axiosInterceptorSend } from "./axiosInterceptorSend"

export const isLocalHost = process.env.NODE_ENV === "development"

const productionUrl = [
  "https://smart-menuqr.pages.dev",
  "http://brbstoreonline.com",
  "https://brbstoreonline.com",
]
export const isProduction = productionUrl.includes(window.location.origin)

export var baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8001/"
    : isProduction
    ? "https://smart-menuqr.onrender.com/"
    : "https://smart-menuqr-dev.onrender.com/"

export const axiosAPI = axios.create({ baseURL })

export const axiosAPIHook = (method, path, data, then) => {
  axiosAPI[method](path, data)
    .then((data) => then && then(data))
    .catch((e) => console.log(e))
}

export const axiosAPIHook2 = (x) => x.catch((e) => console.log(e))

axiosInterceptorSend()
axiosInterceptorResponse()

/* //! -------------- Auth ------------------ */
export const signIn = (formData) => axiosAPI.post("/auth/signin", formData)

export const forgetPassword = (formData) =>
  axiosAPI.post(`/auth/forgetPassword`, formData)

export const resetPassword = (formData) =>
  axiosAPI.post(`/auth/resetPassword`, formData)

export const checkPassword = (id, password) =>
  axiosAPI.post(`/auth/checkPassword/${id}`, password)

/* #region User */

export const updateSelfUser = (updatedUser) =>
  axiosAPI.patch(`/user/updateSelfUser/updateSelfUser`, updatedUser)

/* #endregion */

/* #region Notification */
export const fetchNotificationQuery = () =>
  axiosAPI.post("/notification/fetchNotificationQuery")

export const createNotification = (newNotification) =>
  axiosAPI.post("/notification/", newNotification)

export const updateNotification = (id, updatedNotification) =>
  axiosAPI.patch(`/notification/${id}`, updatedNotification)

export const deleteNotification = (id) => axiosAPI.delete(`/notification/${id}`)
export const deleteAllNotification = (id) =>
  axiosAPI.delete(`/notification//deleteAllNotification/delete`)
/* #endregion */

/* #region Order */
export const getOrderById = (id) => axiosAPI.get(`/order/${id}`)

export const fetchOrderQuery = (query) =>
  axiosAPI.post("/order/fetchOrderQuery", query)

export const basicOrderStatistics = (query) =>
  axiosAPI.post("/order/basicOrderStatistics/", query)
export const orderStatistics = (query) =>
  axiosAPI.post("/order/orderStatistics/", query)
export const createOrder = (newOrder) => axiosAPI.post("/order/", newOrder)

export const updateOrderClient = (id, updatedOrder) =>
  axiosAPI.patch(`/order/updateOrderClient/${id}`, updatedOrder)
export const updateOrder = (id, updatedOrder) =>
  axiosAPI.patch(`/order/${id}`, updatedOrder)

export const deleteOrder = (id) => axiosAPI.delete(`/order/${id}`)
/* #endregion */

/* #region Establishment */

export const getEstablishmentById = (id) => axiosAPI.get(`/establishment/${id}`)

export const updateEstablishment = (id, updatedEstablishment) =>
  axiosAPI.patch(`/establishment/${id}`, updatedEstablishment)

/* #endregion */

/* #region Review */
export const fetchReviewQuery = (query) =>
  axiosAPI.post("/review/fetchReviewQuery", query)

export const createReview = (newReview) => axiosAPI.post("/review/", newReview)

export const updateReview = (id, updatedReview) =>
  axiosAPI.patch(`/review/${id}`, updatedReview)

export const deleteReview = (id) => axiosAPI.delete(`/review/${id}`)
/* #endregion */

/* #region Claim */
export const fetchClaimQuery = (query) =>
  axiosAPI.get("/claim/fetchClaimQuery", { params: query })

export const createClaim = (newClaim) => axiosAPI.post("/claim/", newClaim)

export const updateClaim = (id, updatedClaim) =>
  axiosAPI.patch(`/claim/${id}`, updatedClaim)

export const deleteClaim = (id) => axiosAPI.delete(`/claim/${id}`)
/* #endregion */

/* #region Booking */
export const getBookingById = (id) => axiosAPI.get(`/booking/${id}`)

export const fetchBookingQuery = (query) =>
  axiosAPI.get("/booking/fetchBookingQuery", { params: query })

export const createBooking = (newBooking) =>
  axiosAPI.post("/booking/", newBooking)

export const updateBooking = (id, updatedBooking) =>
  axiosAPI.patch(`/booking/${id}`, updatedBooking)

export const deleteBooking = (id) => axiosAPI.delete(`/booking/${id}`)
/* #endregion */
