import { Box, useMediaQuery } from "@mui/material"

// project import
import LanguageSwitcher from "../../../../components/LanguageSwitcher"
import MobileSection from "./MobileSection"
import Notification from "./Notification"
import Profile from "./Profile"

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("sm"))
  return (
    <>
      {/* {!matchesXs && <Search />} */}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}
      <Box sx={{ width: "100%", ml: 1 }} />

      <LanguageSwitcher sx={{mr  :1}} />
      <Notification />
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  )
}

export default HeaderContent
