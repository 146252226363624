import { useEffect } from "react"
import { useLocation } from "react-router"
import { setDisplayDataFormMap, setFormDataMap } from "../store/reducers/misc2"
import { setSelectedRow } from "../store/actions/misc"

function onPageChangeHook() {

  useEffect(() => {
    setSelectedRow(null)
    setFormDataMap({})
    setDisplayDataFormMap(null)

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }, [useLocation()])


}

export default onPageChangeHook
