import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { ImgPath } from "../../../components/ImagePath"
import { localizeDate } from "../../../utils/dates"
import { setBlogs } from "../../../store/reducers/blog"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import EditCMSBackgroundImage from "../../../components/EditCMSBackgroundImage"

function Blog() {
  const { blogs } = useSelector((state) => state.blog)
  const { t } = useTranslation()

  if (!blogs.length) return <></>

  return (
    <div>
      {/* Title page */}
      <EditCMSBackgroundImage field={"blog_bgImage"}>
        <section
          className="bg-img1 txt-center p-lr-15 p-tb-92"
        >
          <h2 className="ltext-105 cl0 txt-center">Blog</h2>
        </section>
      </EditCMSBackgroundImage>
      {/* Content page */}
      <section className="bg0 p-t-62 p-b-60">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 p-b-80">
              <div className="p-r-45 p-r-0-lg">
                {/* item blog */}
                {blogs.map((blog, key) => {
                  const { image, title, description, createdAt, _id } = blog
                  const path = `./${
                    window.location.href.includes("blog") ? "" : "blog/"
                  }${_id}`

                  return (
                    <div key={key} className="p-b-63">
                      <Link to={path} className="hov-img0 how-pos5-parent">
                        <ImgPath
                          // style={{ maxHeight: 500 }}
                          src={image}
                          alt="IMG-BLOG"
                        />
                        <div className="flex-col-c-m size-123 bg9 how-pos5">
                          <span className="ltext-107 cl2 txt-center">
                            {dayjs(createdAt).format("DD")}
                          </span>
                          <span className="stext-109 cl3 txt-center">
                            {dayjs(createdAt).format("MM YYYY")}
                          </span>
                        </div>
                      </Link>
                      <div className="p-t-32">
                        <h4 className="p-b-15">
                          <Link
                            to={path}
                            className="ltext-108 cl2 hov-cl1 trans-04"
                          >
                            {title}
                          </Link>
                        </h4>
                        <p
                          dangerouslySetInnerHTML={{ __html: description }}
                        ></p>
                        <div className="flex-w flex-sb-m p-t-18">
                          {/* <span className="flex-w flex-m stext-111 cl2 p-r-30 m-tb-10">
                            <span>
                              <span className="cl4">By</span> Admin
                              <span className="cl12 m-l-4 m-r-6">|</span>
                            </span>
                            <span>
                              StreetStyle, Fashion, Couple
                              <span className="cl12 m-l-4 m-r-6">|</span>
                            </span>
                            <span>8 Comments</span>
                          </span> */}
                          <Link
                            to={path}
                            className="stext-101 cl2 hov-cl1 trans-04 m-tb-10"
                          >
                            {t("continue-reading")}{" "}
                            <i className="fa fa-long-arrow-right m-l-9" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                })}
                {/* Pagination */}
                {/* <div className="flex-l-m flex-w w-full p-t-10 m-lr--7">
                    <a
                      href="#"
                      className="flex-c-m how-pagination1 trans-04 m-all-7 active-pagination1"
                    >
                      1
                    </a>
                    <a
                      href="#"
                      className="flex-c-m how-pagination1 trans-04 m-all-7"
                    >
                      2
                    </a>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Blog
