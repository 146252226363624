import { Tooltip } from "@mui/material"
import { useSelector } from "react-redux"
import { useLocation } from "react-router"
import { Link } from "react-router-dom"
import { ImgPath } from "../../../components/ImagePath"
import ToggleThemeButtons from "../../../layout/MainLayout/Header/HeaderContent/ToggleThemeButtons"
import { LoginIcon } from "../../../utils/MuiIcons"
import { changeLocales } from "../../../utils/i18nUtilities"
import { IsManager } from "../../../utils/useIsRole"
import { useTranslation } from "react-i18next"

export function Header() {
  const { t } = useTranslation()
  const { establishment } = useSelector((state) => state.establishment)
  const { cart } = useSelector((state) => state.cart)
  const { pathname } = useLocation()
  const isShoppingCartPage =
    pathname?.includes("shopping_cart") || pathname?.includes("ecommerce_order")
  const { ecommerceOrders } = useSelector((state) => state.ecommerceOrder)
  return (
    <header className={isShoppingCartPage ? "header-v4" : ""}>
      {/* Header desktop */}
      <div className="container-menu-desktop">
        {/* Topbar */}
        <div className="top-bar">
          <div className="content-topbar flex-sb-m h-full container">
            <div className="right-top-bar flex-w h-full">
              <a className="flex-c-m trans-04 p-lr-25">
                <ToggleThemeButtons variant="IconToggle" />
              </a>
              <button
              style={{color : "gray"}}
                // href="#"
                onClick={() => changeLocales("en")}
                className="flex-c-m trans-04 p-lr-25"
              >
                EN
              </button>
              <button
              style={{color : "gray"}}
                // href="#"
                onClick={() => changeLocales("fr")}
                className="flex-c-m trans-04 p-lr-25"
              >
                FR
              </button>
              <a
                href="/auth/login"
                className="flex-c-m trans-04 p-lr-25"
              >
                <LoginIcon sx={{ color: "lightgray" }} />
              </a>
            </div>
          </div>
        </div>
        <div
          className={
            "wrap-menu-desktop " + (isShoppingCartPage ? "how-shadow1" : "")
          }
        >
          {/* <ManagerModeBar /> */}

          <nav className="limiter-menu-desktop container">
            {/* Logo desktop */}
            <Link to={IsManager() ? "/auth/login" : "./"} className="logo">
              {<ImgPath src={establishment?.logo} width={75} alt="IMG-LOGO" />}
            </Link>

            {/* Menu desktop */}
            <div className="menu-desktop">
              <ul className="main-menu">
                <li className={pathname === "/" ? "active-menu" : ""}>
                  <Link to="./">{t("home")}</Link>
                </li>
                <li
                  className={
                    pathname.indexOf("/shop") !== -1 ? "active-menu" : ""
                  }
                >
                  <Link to="./shop">{t("shop")}</Link>
                </li>
                <li
                  className={
                    pathname.indexOf("/blog") !== -1 ? "active-menu" : ""
                  }
                >
                  <Link to="./blog">{t("blog")}</Link>
                </li>
                <li
                  className={
                    pathname.indexOf("/about") !== -1 ? "active-menu" : ""
                  }
                >
                  <Link to="./about">{t("about")}</Link>
                </li>
                <li
                  className={
                    pathname.indexOf("/contact") !== -1 ? "active-menu" : ""
                  }
                >
                  <Link to="./contact">{t("contact")}</Link>
                </li>
              </ul>
            </div>
            {/* Icon header */}
            <div className="wrap-icon-header flex-w flex-r-m">
              {/* <div className="icon-header-item cl2 hov-cl1 trans-04 p-l-22 p-r-11 js-show-modal-search">
                <i className="zmdi zmdi-search" />
              </div> */}
              <Tooltip title={t("cart")}>
                <div
                  style={{ marginRight: 16 }}
                  className="icon-header-item cl2 hov-cl1 trans-04 p-l-22 p-r-11 icon-header-noti js-show-cart"
                  data-notify={cart?.length}
                >
                  <i className="zmdi zmdi-shopping-cart" />
                </div>
              </Tooltip>
              {ecommerceOrders?.length !== 0 && (
                <Link to="./ecommerce_order">
                  <Tooltip title={t("order")}>
                    <div
                      style={{ marginRight: 16 }}
                      className="icon-header-item cl2 hov-cl1 trans-04 p-l-22 p-r-11 icon-header-noti"
                      data-notify={ecommerceOrders?.length}
                    >
                      <i className="zmdi zmdi-truck" />
                    </div>
                  </Tooltip>
                </Link>
              )}
            </div>
          </nav>
        </div>
      </div>
      {/* Header Mobile */}
      <div className="wrap-header-mobile">
        {/* Logo moblie */}
        <div className="logo-mobile">
          <Link to={IsManager() ? "/auth/login" : "./"} className="logo">
            <img src={establishment?.logo} width ={50} alt="IMG-LOGO" />
          </Link>
          <a href="index.html"></a>
        </div>
        {/* Icon header */}
        <div className="wrap-icon-header flex-w flex-r-m m-r-15">
          <div
            className="icon-header-item cl2 hov-cl1 trans-04 p-r-11 p-l-10 icon-header-noti js-show-cart"
            data-notify={cart?.length}
          >
            <i className="zmdi zmdi-shopping-cart" />
          </div>
          {ecommerceOrders?.length !== 0 && (
            <Link to="./ecommerce_order">
              <div
                style={{ marginRight: 16 }}
                className="icon-header-item cl2 hov-cl1 trans-04 p-l-22 p-r-11 icon-header-noti"
                data-notify={ecommerceOrders?.length}
              >
                <i className="zmdi zmdi-truck" />
              </div>
            </Link>
          )}
        </div>
        {/* Button show menu */}
        <div className="btn-show-menu-mobile hamburger hamburger--squeeze">
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </div>
      </div>
      {/* Menu Mobile */}
      <div className="menu-mobile">
        <ul className="topbar-mobile">
          <li>
            <div
              className="right-top-bar flex-w h-full align-items-center
"
            >
              <a className="flex-c-m p-lr-10 trans-04">
                <ToggleThemeButtons variant="IconToggle" />
              </a>
              <a href="#" className="flex-c-m p-lr-10 trans-04">
                EN
              </a>
              <a href="#" className="flex-c-m p-lr-10 trans-04">
                USD
              </a>
            </div>
          </li>
        </ul>
        <ul className="main-menu-m">
          <li>
            <Link to="./">{t("home")}</Link>
            <span className="arrow-main-menu-m">
              <i className="fa fa-angle-right" aria-hidden="true" />
            </span>
          </li>
          <li>
            <Link to="./shop">{t("shop")}</Link>
          </li>
          <li>
            <Link to="./about">{t("about")}</Link>
          </li>
          <li>
            <Link to="./blog">{t("blog")}</Link>
          </li>
          <li>
            <Link to="./contact">{t("contact")}</Link>
          </li>
        </ul>
      </div>
    </header>
  )
}
