import "moment/locale/ar"
import "moment/locale/fr"

import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import isBetween from "dayjs/plugin/isBetween.js"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import weekday from "dayjs/plugin/weekday"
import { isBase64 } from "../utils/convertToBase64"

dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(customParseFormat)
dayjs.extend(weekday)

export function returnModifiedFields(formData, originalData = {}) {
  if (!originalData  || !Object?.keys(originalData).length) return formData

  const ignoreFields = ["updatedAt"]
  const modifiedFields = {}

  if (typeof formData === "string") return formData != originalData

  for (const key in formData) {
    const formDataValue = formData[key]
    const originalDataValue = originalData[key]
    if (ignoreFields.includes(key)) continue

    if (typeof formDataValue === "object" || Array.isArray(formDataValue)) {
      if (JSON.stringify(formDataValue) !== JSON.stringify(originalDataValue)) {
        modifiedFields[key] = formDataValue
      }
    } else if (formDataValue !== originalDataValue) {
      modifiedFields[key] = formDataValue
    }
  }

  return Object.keys(modifiedFields).length > 0 ? modifiedFields : false
}

export function hoverScale({
  scale = "05",
  transformOrigin = "center",
  color,
  border,
}) {
  return {
    width: "fit-content",
    transformOrigin,
    transition: "all 0.3s ease-in-out",
    ...(border && { border: "1px transparent" }),
    "&:hover": {
      transform: `scale(1.${scale})`,
      color,
      border,
    },
  }
}

export const getObjectLength = (obj) => Object.keys(obj).length


