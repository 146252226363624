import PropTypes from "prop-types"
import { Link } from "react-router-dom"

// material-ui
import { ButtonBase, Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

// project import
import { useTranslation } from "react-i18next"
import { activeItem } from "store/reducers/menu"
import { ArrowForwardIcon } from "../../utils/MuiIcons"
import { IsManager, IsSupervisor, IsWaiter } from "../../utils/useIsRole"
import CustomButton from "../Buttons/CustomButton"
import Logo from "./Logo"
import uniquePageWebsite from "../../pages/uniquePageWebsite"

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ sx }) => {
  const { defaultId } = useSelector((state) => state.menu)
  const { establishment } = useSelector((state) => state.establishment)
  const to = IsSupervisor()
    ? "#"
    :  uniquePageWebsite() ? "/" :`/establishment/${establishment?._id}`
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const disabled = IsSupervisor() || IsWaiter()
if (!establishment) return <></>

  return (
    <div>
      <ButtonBase
        disableRipple
        component={!disabled ? Link : "div"}
        onClick={() => {
          dispatch(activeItem({ openItem: [defaultId] }))
        }}
        to={to}
        sx={sx}
        className={disabled ? "" : "custom-hover-05"}
        style={{ pointer: disabled ? "none" : "cursor" }}
      >
        {establishment?.logo ? (
          <Logo width={75} />
        ) : (
          // <ImgPath width={75} src={establishment.logo} />
          <Logo width={75} />
        )}
        <Typography
          sx={{
            fontFamily: "Poppins",
            width: 150,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "white",
          }}
          variant="h4"
        >
          {establishment?.name}
        </Typography>
      </ButtonBase>
      <br />
      {IsManager() && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Link to={to}>
            <CustomButton
              sx={{ fontSize: 16, p: 0, px: 2 }}
              icon={<ArrowForwardIcon sx={{ color: "white" }} />}
            >
              go-to-the-front-site
            </CustomButton>
          </Link>
        </Stack>
      )}
    </div>
  )
}

LogoSection.propTypes = {
  sx: PropTypes.object,
  to: PropTypes.string,
}

export default LogoSection
