import { createTheme } from "@mui/material"
import RestaurantModel from "./pages/dashboard/establishmentSite/RestaurantModel"
import ClothingModel from "./pages/clothSite/ClothingModel"
import { darkBlueGradient2, darkBlueGradientLight } from "gradients"

// Theme Configuration
export const lightTheme = createTheme({
  palette: {
    mode: "light",
    // Add your light theme color configurations here
  },
  // Other light theme options
})

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    // Add your dark theme color configurations here
  },
  // Other dark theme options
})

export const copyright = "Smart MenuQr"
// Shadow
export const shadow1 =
  "rgba(0, 0, 0, 0.06) 0px 5px 5px -3px, rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px"

// UI Constants
export const miniDrawer = false
export const container = true
export const drawerWidth = 260
export const drawerColor = darkBlueGradientLight

// Paths and URLs
export const imageRepositoryNameDB = "DBImages"
export const defaultPath = "/dashboard/default"
export const dashboardPath = "/dashboard/"
export const establishmentPath = "/establishment/"
export const logoPath = "/logo/3.png"

// Typography and Language
export const fontFamily = `'Public Sans', sans-serif`
export const i18n = "en"
// Currency and Date Formats
export const moneyCurrency = " DT"
export const dateFormat = "DD/MM/YYYY"
export const dateTimeFormat = "DD/MM/YYYY HH:mm"

// User Roles and Order Status
export const managerRole = "Gérant"
export const waiterRole = "Serveur"
export const clientRole = "Client"
export const SupervisorRole = "SUPERVISOR"

export const orderStatusCanceled = "canceled"
export const orderStatusPending = "waiting"
export const orderStatusConfirmed = "confirmed"
export const orderStatusPreparing = "preparing"
export const orderStatusServed = "served"
export const orderStatusPayed = "payed"

// Social Media Colors
export const twitterColor = "#1DA1F2"
export const facebookColor = "#3b5998"
export const linkedInColor = "#0e76a8"

// Confirmation Dialog Option
export const confirmOptionDelete = {
  title: "Are you sure to delete this item?",
}

// Socket
export const socketRefreshInterval = 1000 * 30

// export const siteModelOptions = ["clothing", "restaurant"]
export const siteModelOptions = {
  clothing: <ClothingModel />,
  restaurant: <RestaurantModel />,
}

export const enableNestedCategories = ["clothing"]
export const imageInputTypes = ["JPEG", "JPG", "PNG", "GIF"]
