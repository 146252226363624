// third-party
import { combineReducers } from "redux"

// project import
import menu from "./menu"

import auth from "./auth"
import user from "./user"
import notification from "./notification"
import misc from "./misc"
import misc2 from "./misc2"
import blog from "./blog"

import category from "./category"
import product from "./product"
import establishment from "./establishment"
import order from "./order"
import claim from "./claim"
import cart from "./cart"
import booking from "./booking"
import ecommerceOrder from "./ecommerceOrder"

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  user,
  auth,
  category,
  product,
  establishment,
  order,
  claim,
  cart,
  booking,
  misc,
  misc2,
  notification,
  ecommerceOrder,
  blog,
})

export default reducers
