import { InputAdornment, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import { reduxFormDataHook } from "../reactCustomHooks"

export function MuiTextField3({ column }) {
  var {
    type,
    helperText,
    pattern,
    header,
    multiline,
    minLength,
    maxLength,
    disabled,
    Icon,
    min,
    max,
  } = column

  const { accessorKey, required, value, onChange, t } =
    reduxFormDataHook(column)

  helperText = t(helperText)

  return (
    <TextField
      {...{
        type,
        helperText,
        required,
        multiline,
        disabled,
      }}
      fullWidth
      key={`column-${accessorKey}`}
      label={t(header || accessorKey)}
      name={accessorKey}
      value={value}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        ...(Icon && {
          startAdornment: (
            <InputAdornment position="start">
              <Icon />
            </InputAdornment>
          ),
        }),
      }}
      inputProps={{
        pattern,
        title: helperText,
        minLength,
        maxLength,
        min,
        max,
      }}
      onChange={onChange}
    />
  )
}
