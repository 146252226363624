import { createSlice } from "@reduxjs/toolkit"
import { axiosAPI, axiosAPIHook, axiosAPIHook2 } from "../axiosFunctions"
import { dispatch } from ".."

const initialState = {
  ecommerceOrders: [],
}

const ecommerceOrderSlice = createSlice({
  name: "ecommerceOrder",
  initialState,
  reducers: {
    setEcommerceOrdersReducer(state, action) {
      state.ecommerceOrders = action.payload
    },

    addEcommerceOrderReducer(state, action) {
      state.ecommerceOrders.unshift(action.payload)
    },

    updateEcommerceOrderReducer(state, action) {
      state.ecommerceOrders = state.ecommerceOrders.map((document) =>
        document._id === action.payload._id ? action.payload : document
      )
    },

    deleteEcommerceOrderReducer(state, action) {
      state.ecommerceOrders = state.ecommerceOrders.filter(
        (document) => document._id !== action.payload
      )
    },
  },
})

const {
  setEcommerceOrdersReducer,
  addEcommerceOrderReducer,
  updateEcommerceOrderReducer,
  deleteEcommerceOrderReducer,
} = ecommerceOrderSlice.actions

// Manager
export const basicEcommerceOrderStatistics = (query) =>
  axiosAPIHook2(
    axiosAPI.post("ecommerceOrder/basicEcommerceOrderStatistics", query)
  )

export const EcommerceOrderStatistics = (query) =>
  axiosAPIHook2(axiosAPI.post("ecommerceOrder/EcommerceOrderStatistics", query))

export const setEcommerceOrders = (query) => {
  axiosAPIHook("get", "/ecommerceOrder/", { params: query }, (data) =>
    dispatch(setEcommerceOrdersReducer(data))
  )
}

export const updateEcommerceOrder = (id, body) =>
  axiosAPIHook("patch", `/ecommerceOrder/${id}`, body, (data) =>
    dispatch(updateEcommerceOrderReducer(data))
  )

export const deleteEcommerceOrder = (id) =>
  axiosAPIHook("delete", `/ecommerceOrder/${id}`, _, (data) =>
    dispatch(deleteEcommerceOrderReducer(id))
  )

// Client
export const setEcommerceOrdersClient = (query) => {
  if (localStorage.getItem("clientEcommerceOrderToken") === "undefined")
    localStorage.removeItem("clientEcommerceOrderToken")
  axiosAPIHook(
    "get",
    "/ecommerceOrder/findAllEcommerceOrderClient/",
    { params: query },
    (data) => dispatch(setEcommerceOrdersReducer(data))
  )
}

export const addEcommerceOrder = (body) =>
  axiosAPIHook(
    "post",
    "/ecommerceOrder/createEcommerceOrderClient/",
    body,
    (data) => {
      dispatch(addEcommerceOrderReducer(data))

      localStorage.setItem("clientEcommerceOrderToken", data.token)
    }
  )

export const updateEcommerceOrderClient = (id, body) =>
  axiosAPIHook(
    "patch",
    `/ecommerceOrder/updateEcommerceOrderClient/${id}`,
    body,
    (data) => {
      dispatch(updateEcommerceOrderReducer(data))
      localStorage.setItem("clientEcommerceOrderToken", data.token)
    }
  )

export const deleteEcommerceOrderClient = (id) =>
  axiosAPIHook(
    "delete",
    `/ecommerceOrder/deleteOneEcommerceOrderClient/${id}`,
    _,
    (data) => {
      dispatch(deleteEcommerceOrderReducer(id))

      localStorage.setItem("clientEcommerceOrderToken", data.token)
    }
  )

export default ecommerceOrderSlice.reducer
