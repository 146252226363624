import React from "react"
import EditCMS from "../../../components/EditCMS"
import EditCMSBackgroundImage from "../../../components/EditCMSBackgroundImage"
import { useTranslation } from "react-i18next"

function About() {
  const { t } = useTranslation()
  return (
    <>
      {/* Title page */}
      <EditCMSBackgroundImage field={"about_bgImage"}>
        <section className="bg-img1 txt-center p-lr-15 p-tb-92">
          <h2 className="ltext-105 cl0 txt-center">{t('about')}</h2>
        </section>
      </EditCMSBackgroundImage>
      {/* Content page */}
      <section className="bg0 p-t-75 p-b-120">
        <div className="container">
          <div className="row p-b-148">
            <div className="col-md-7 col-lg-8">
              <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md">
                <h3 className="mtext-111 cl2 p-b-16">
                  <EditCMS field={"aboutTitle_1"} />
                </h3>

                <p className="stext-113 cl6 p-b-26">
                  <EditCMS field={"aboutText_1"} />
                </p>
              </div>
            </div>
            <div className="col-11 col-md-5 col-lg-4 m-lr-auto">
              <div className="how-bor1 ">
                <div className="hov-img0">
                  <EditCMS type="image" field={"aboutImage_1"} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="order-md-2 col-md-7 col-lg-8 p-b-30">
              <div className="p-t-7 p-l-85 p-l-15-lg p-l-0-md">
                <h3 className="mtext-111 cl2 p-b-16">
                  <EditCMS field={"aboutTitle_2"} />
                </h3>

                <p className="stext-113 cl6 p-b-26">
                  <EditCMS field={"aboutText_2"} />
                </p>
              </div>
            </div>
            <div className="order-md-1 col-11 col-md-5 col-lg-4 m-lr-auto p-b-30">
              <div className="how-bor2">
                <div className="hov-img0">
                  <EditCMS type="image" field={"aboutImage_2"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About
