import { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// material-ui
import { useTheme } from "@mui/material/styles"
import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Toolbar,
  useMediaQuery,
} from "@mui/material"

// project import
import Drawer from "./Drawer"
import Header from "./Header"
import Breadcrumbs from "components/@extended/Breadcrumbs"
import "./dashboard.css"

// types
import { openDrawer } from "store/reducers/menu"
import { drawerWidth } from "../../config"
import { Helmet } from "react-helmet"
import LoadingOverlay from "../../pages/dashboard/myEstablishment/LoadingPopup"
import {
  ConfirmProvider,
  lightTheme,
} from "../../components/ConfirmDialogPromise"
import { menuItems } from "../../routes/MainRoutes"
import { IsManager, IsSupervisor, IsWaiter } from "../../utils/useIsRole"
import { setEstablishment } from "../../store/reducers/establishment"
import onPageChangeHook from "../../utils/onPageChangeHook"

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const { establishment } = useSelector((state) => state.establishment)

  const theme = useTheme()
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"))
  const dispatch = useDispatch()

  useEffect(() => {
    if (!IsSupervisor()) setEstablishment()
  }, [])
  
  onPageChangeHook()

  const { drawerOpen } = useSelector((state) => state.menu)

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen)
  const handleDrawerToggle = () => {
    setOpen(!open)
    dispatch(openDrawer({ drawerOpen: !open }))
  }

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG)
    dispatch(openDrawer({ drawerOpen: !matchDownLG }))
  }, [matchDownLG])

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen)
  }, [drawerOpen])

  const mainBoxWidth = open ? `calc(100% - ${drawerWidth}px)` : "100%"

  const establishmentLoadingCompleted = IsSupervisor()
  ? true
  : (IsManager() || IsWaiter()) && establishment
  
  return (
    <ConfirmProvider theme={lightTheme}>
      <LoadingOverlay />
      <Box
        sx={{
          overflowX: "hidden",
          display: "flex",
          width: "100%",
          position: "relative",
        }}
      >
        <Helmet title={establishment?.name}></Helmet>
        <Header open={open} handleDrawerToggle={handleDrawerToggle} />
        <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
        <Box
          component="main"
          sx={{height : "calc(100vh - 40px)", width: mainBoxWidth, flexGrow: 1, p: { xs: 2, sm: 3 } }}
        >
          <Toolbar />
          <Breadcrumbs navigation={menuItems} title />
          {establishmentLoadingCompleted && <Outlet />}
        </Box>
      </Box>
    </ConfirmProvider>
  )
}

export default MainLayout
