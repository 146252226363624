import AddIcon from "@mui/icons-material/Add"
import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import { Box, CardContent, Stack, Tooltip } from "@mui/material"
import { MaterialReactTable } from "material-react-table"
import { MRT_Localization_EN } from "material-react-table/locales/en"
import { MRT_Localization_FA } from "material-react-table/locales/fa"
import { MRT_Localization_FR } from "material-react-table/locales/fr"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { resetSelectedRow, setSelectedRow } from "../../store/actions/misc"
import CustomButton from "../Buttons/CustomButton"

import dayjs from "dayjs"
import i18next from "i18next"
import { useTranslation } from "react-i18next"
import { moneyCurrency } from "../../config"
import { setReduxPagination } from "../../store/reducers/misc"
import {
  setDisplayDataFormMap,
  setFormDataMap,
} from "../../store/reducers/misc2"
import BaublesGrid from "../../utils/BaublesGrid"
import DisplayDataMap from "../../utils/DisplayDataMap"
import MuiAccordion from "../../utils/MuiAccordion"
import TooltipAction from "../../utils/MuiTooltipAction"
import TypographyTrad from "../../utils/TypographyTrad."
import { arrayFirstElement } from "../../utils/shortCuts"
import { useDidMountEffect } from "../../utils/useDidMountEffect"
import useIsMobile from "../../utils/useIsMobile"
import CardMediaPath from "../ImagePath"
import { CrudTableDisplayRow } from "./CrudTableDisplayRow"
import IntervalPicker from "./IntervalPicker"
import actionsMap from "./actionsMap"

const checkboxColumn = {
  Cell: ({ cell }) =>
    cell.getValue(cell.column.id) ? (
      <DoneIcon color="success" fontSize="large" />
    ) : (
      <CloseIcon color="error" fontSize="large" />
    ),
}

const imageColumn = {
  header: i18next.t("image"),
  Cell: ({ renderedCellValue }) =>
    arrayFirstElement(renderedCellValue) ? (
      <CardMediaPath
        component="img"
        width="200"
        // height="200"
        image={renderedCellValue}
      />
    ) : (
      <CardContent>
        <TypographyTrad variant="body2" color="text.secondary">
          no-image-saved
        </TypographyTrad>
      </CardContent>
    ),
}
const createdAtColumn = {
  muiTableHeadCellProps: {
    align: "center",
  },
  muiTableBodyCellProps: {
    align: "center",
  },
  accessorKey: "createdAt",
  header: "Date",
  // enableColumnFilter: false,
  // size: 100,

  // filterFn: (row, id, filterValue) =>
  //   row.getValue(id).indexOf(filterValue) !== -1,

  Cell: ({ renderedCellValue }) => {
    const value = renderedCellValue
    if (typeof value === "string")
      return (
        <div>
          {dayjs(value).format("DD/MM/YYYY")}
          <br />
          {/* {dayjs(value).format("HH:mm")} */}
        </div>
      )
    else return <span>{renderedCellValue}</span>
  },
}
const ellipsisColumn = {
  Cell: ({ renderedCellValue }) => {
    const value = renderedCellValue
    if (typeof value === "string")
      return (
        <span>
          <Tooltip title={value}>
            <span>
              {value?.substring(0, 10)}
              {value.length > 10 && "..."}
            </span>
          </Tooltip>
        </span>
      )
    else return <span>{renderedCellValue}</span>
  },
}
const currencyColumn = {
  Cell: ({ renderedCellValue }) => (
    <span>
      {renderedCellValue} {moneyCurrency}
    </span>
  ),
}

{
  /* //! -------------- Main ------------------ */
}
// column props editHidden
const CRUDTable = (props) => {
  var {
    rows: defaultRow = [],
    columns: defaultColumns = [],
    createFn,
    updateFn,
    deleteFn,
    fetchFn,
    addCustomActionElement,
    additionalData,
    addNewLine,
    enableIntervalPicker,
    enableRowActions = true,
    actionSize = 120,
    addedActionMap,
    searchField = "name",
    pageSize = 10,
    initFetch = true,
    enableCreatedAt,
    collapsable,
    rowStyle,
    tableName,
    allowMobileUI,
    removedFieldConfig,
    DisplayDataMapElement = (
      <DisplayDataMap
        actions={addedActionMap}
        removedFieldConfig={removedFieldConfig}
      />
    ),
    onRowClickDisplayElement = (
      <CrudTableDisplayRow
        updateFn={updateFn}
        createFn={createFn}
        columns={defaultColumns}
        additionalData={additionalData}
      />
    ),
  } = props

  const { t, i18n } = useTranslation()
  var language
  switch (i18n.language) {
    case "ar":
      language = MRT_Localization_FA
      break
    case "en":
      language = MRT_Localization_EN
      break
    case "fr":
      language = MRT_Localization_FR
      break

    default:
      break
  }
  const { totalCount } = useSelector((state) => state.misc)
  const { displayDataFormMap } = useSelector((state) => state.misc2)
  const [data, setData] = useState(defaultRow)
  const actionArray = [
    ...actionsMap({ deleteFn, updateFn }),
    ...(addedActionMap ? addedActionMap : []),
  ]

  useEffect(() => {
    if (defaultRow) setData(defaultRow)
  }, [defaultRow])

  var columnToShow = [
    // {
    //   accessorKey: "newReduxUpdate",
    // },
  ]
  defaultColumns.forEach((col) => {
    const { type, hiddenColumn, ellipsis, currency } = col
    if (hiddenColumn) return

    columnToShow.push({
      ...col,
      header: t(col?.header || col?.accessorKey),
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },

      ...(currency === true && currencyColumn),
      ...(ellipsis === true && ellipsisColumn),
      ...(type === "checkbox" && checkboxColumn),
      ...(["image", "images", "logo", "avatar"].includes(type) && imageColumn),
    })
  })
  if (enableIntervalPicker || enableCreatedAt)
    columnToShow.unshift(createdAtColumn)

  //store pagination state in your own state
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSize, //customize the default page size
  })
  const [globalFilter, setGlobalFilter] = useState("")

  useEffect(() => {
    if (fetchFn && initFetch) fetchFn()
  }, [])

  useDidMountEffect(() => {
    if (fetchFn) {
      setReduxPagination(pagination)
      const query = globalFilter
        ? { [searchField]: { $regex: new RegExp(globalFilter, "i") } }
        : null
      fetchFn(query)
    }
  }, [pagination.pageIndex, pagination.pageSize, globalFilter])

  return (
    <>
      {useIsMobile() && allowMobileUI ? (
        <BaublesGrid
          rows={defaultRow}
          onRowClick={(row) => {
            console.log(row)

            setDisplayDataFormMap(row)
          }}
        />
      ) : (
        <MuiAccordion title={collapsable}>
          <div>
            <MaterialReactTable
              manualFiltering //turn off client-side filtering
              onGlobalFilterChange={setGlobalFilter}
              data={data}
              initialState={{
                columnVisibility: { newReduxUpdate: false },
                // showColumnFilters: toggleFilter
              }}
              enableFacetedValues
              muiTableProps={{
                sx: {
                  tableLayout: "fixed",
                },
              }}
              // state={{ isLoading: loading }}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  muiTableHeadCellProps: {
                    align: "center",
                  },
                  size: actionSize,
                },
              }}
              enableColumnActions={false}
              localization={language}
              // positionActionsColumn="last"
              enableRowActions={enableRowActions}
              renderRowActions={({ row, table }) => ActionColumn(row.original)}
              renderTopToolbarCustomActions={() => (
                <>
                  {createFn && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={2}
                      // sx={{ width: "100%" }}
                    >
                      {addNewLine !== false && (
                        <CustomButton
                          icon={<AddIcon />}
                          onClick={() => setSelectedRow({})}
                          bgcolor="blue"
                        >
                          {t("add") + (tableName ? " : " : "") + t(tableName)}
                        </CustomButton>
                      )}
                      {addCustomActionElement}
                    </Stack>
                  )}
                  {enableIntervalPicker && (
                    <IntervalPicker
                      defaultRows={defaultRow}
                      setData={setData}
                    />
                  )}
                </>
              )}
              muiTableBodyRowProps={({ row }) => ({
                onClick: (event) => {
                  setDisplayDataFormMap(row.original)
                },
                // onMouseEnter: (event) => {
                // if (row.getValue("newReduxUpdate"))
                // updateFn
                // console.info(event, row.id);
                // },
                sx: {
                  cursor: "pointer",
                  bgcolor: row.original?.newReduxUpdate ? "#FFCCCC" : "inherit", //add a border between columns
                  ...(rowStyle && rowStyle(row.original)),
                },
              })}
              {...props}
              columns={columnToShow}
              onPaginationChange={setPagination}
              state={{ pagination, globalFilter }}
              manualPagination
              rowCount={totalCount}
              // pageCount = {5}
            />
          </div>
        </MuiAccordion>
      )}
      {displayDataFormMap && DisplayDataMapElement}
      {onRowClickDisplayElement}
    </>
  )
  function ActionColumn(row) {
    return (
      <Box
        sx={{ display: "flex", justifyContent: "center", gap: "1rem" }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {actionArray.map((action, key) => {
          const { Icon, title, actionFn, color, condition, confirm } = action
          if (condition ? condition(row) : true)
            return (
              <TooltipAction
                key={key}
                Icon={Icon(row)}
                title={title(row)}
                actionFn={() => actionFn(row)}
                color={color(row)}
                confirm={confirm}
              />
            )
        })}
      </Box>
    )
  }
}

export default CRUDTable
