import { useSelector } from "react-redux"
import { ClothingScriptJs } from "../ClothingScriptJs"
import { useEffect, useState } from "react"
import { moneyCurrency } from "../../../config"
import { ImgPath } from "../../../components/ImagePath"
import $ from "jquery"
import { setSelectedRow } from "../../../store/actions/misc"
import { useHandleChange } from "../../../utils/reactCustomHooks"
import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { arrayFirstElement, arrayLength } from "../../../utils/shortCuts"

export function ProductOverview() {
  const { t } = useTranslation()
  const { categories } = useSelector((state) => state.category)
  const { products } = useSelector((state) => state.product)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [search, setSearch] = useState("")
  const handleChange = useHandleChange(search, setSearch)

  var filteredProducts = products.filter((product) =>
    product.available && selectedCategory !== null
      ? product.category?._id === selectedCategory._id
      : true
  )

  filteredProducts = filteredProducts.filter((product) =>
    search
      ? product.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
      : true
  )

  useEffect(() => {
    $(".js-show-modal1").on("click", function (e) {
      e.preventDefault()
      $(".js-modal1").addClass("show-modal1")
    })

    $(".js-hide-modal1").on("click", function () {
      $(".js-modal1").removeClass("show-modal1")
    })

    $(".js-addwish-b2, .js-addwish-detail").on("click", function (e) {
      e.preventDefault()
    })

    $(".js-addwish-b2").each(function () {
      var nameProduct = $(this).parent().parent().find(".js-name-b2").html()
      $(this).on("click", function () {
        swal(nameProduct, "is added to wishlist !", "success")

        $(this).addClass("js-addedwish-b2")
        $(this).off("click")
      })
    })

    $(".js-addwish-detail").each(function () {
      var nameProduct = $(this)
        .parent()
        .parent()
        .parent()
        .find(".js-name-detail")
        .html()

      $(this).on("click", function () {
        swal(nameProduct, "is added to wishlist !", "success")

        $(this).addClass("js-addedwish-detail")
        $(this).off("click")
      })
    })

    $(".js-addcart-detail").each(function () {
      var nameProduct = $(this)
        .parent()
        .parent()
        .parent()
        .parent()
        .find(".js-name-detail")
        .html()
      $(this).on("click", function () {
        swal(nameProduct, "is added to cart !", "success")
      })
    })
  }, [products])
  return (
    <section className="bg0 p-t-23 p-b-140">
      <div className="container">
        <div className="p-b-10">
          <h3 className="ltext-103 cl5">{t("product-overview")}</h3>
        </div>
        <div className="flex-w flex-sb-m p-b-52">
          <div className="flex-w flex-l-m filter-tope-group m-tb-10">
            <button
              onClick={() => setSelectedCategory(null)}
              className="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5 how-active1"
              data-filter="*"
            >
              {t("all-products")}
            </button>
            {categories.map((category, key) => (
              <button
                onClick={() => setSelectedCategory(category)}
                key={key}
                className="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5"
                data-filter={"." + category.name}
              >
                {category.name}
              </button>
            ))}
          </div>
          <div className="flex-w flex-c-m m-tb-10">
            {/* <div className="flex-c-m stext-106 cl6 size-104 bor4 pointer hov-btn3 trans-04 m-r-8 m-tb-4 js-show-filter">
              <i className="icon-filter cl2 m-r-6 fs-15 trans-04 zmdi zmdi-filter-list" />
              <i className="icon-close-filter cl2 m-r-6 fs-15 trans-04 zmdi zmdi-close dis-none" />
              Filter
            </div> */}
            <div className="flex-c-m stext-106 cl6 size-105 bor4 pointer hov-btn3 trans-04 m-tb-4 js-show-search">
              <i className="icon-search cl2 m-r-6 fs-15 trans-04 zmdi zmdi-search" />
              <i className="icon-close-search cl2 m-r-6 fs-15 trans-04 zmdi zmdi-close dis-none" />
              {t("search-0")}
            </div>
          </div>
          {/* Search product */}
          <div className="dis-none panel-search w-full p-t-10 p-b-15">
            <div className="bor8 dis-flex p-l-15">
              <button className="size-113 flex-c-m fs-16 cl2 hov-cl1 trans-04">
                <i className="zmdi zmdi-search" />
              </button>
              <input
                className="mtext-107 cl2 size-114 plh2 p-r-15"
                type="text"
                name="search-product"
                placeholder="Search"
                value={search}
                onChange={handleChange}
              />
            </div>
          </div>
          {/* Filter */}
          {/* <div className="dis-none panel-filter w-full p-t-10">
            <div className="wrap-filter flex-w bg6 w-full p-lr-40 p-t-27 p-lr-15-sm">
              <div className="filter-col1 p-r-15 p-b-27">
                <div className="mtext-102 cl2 p-b-15">Sort By</div>
                <ul>
                  <li className="p-b-6">
                    <a href="#" className="filter-link stext-106 trans-04">
                      Default
                    </a>
                  </li>
                  <li className="p-b-6">
                    <a href="#" className="filter-link stext-106 trans-04">
                      Popularity
                    </a>
                  </li>
                  <li className="p-b-6">
                    <a href="#" className="filter-link stext-106 trans-04">
                      Average rating
                    </a>
                  </li>
                  <li className="p-b-6">
                    <a
                      href="#"
                      className="filter-link stext-106 trans-04 filter-link-active"
                    >
                      Newness
                    </a>
                  </li>
                  <li className="p-b-6">
                    <a href="#" className="filter-link stext-106 trans-04">
                      Price: Low to High
                    </a>
                  </li>
                  <li className="p-b-6">
                    <a href="#" className="filter-link stext-106 trans-04">
                      Price: High to Low
                    </a>
                  </li>
                </ul>
              </div>
              <div className="filter-col2 p-r-15 p-b-27">
                <div className="mtext-102 cl2 p-b-15">Price</div>
                <ul>
                  <li className="p-b-6">
                    <a
                      href="#"
                      className="filter-link stext-106 trans-04 filter-link-active"
                    >
                      All
                    </a>
                  </li>
                  <li className="p-b-6">
                    <a href="#" className="filter-link stext-106 trans-04">
                      $0.00 - $50.00
                    </a>
                  </li>
                  <li className="p-b-6">
                    <a href="#" className="filter-link stext-106 trans-04">
                      $50.00 - $100.00
                    </a>
                  </li>
                  <li className="p-b-6">
                    <a href="#" className="filter-link stext-106 trans-04">
                      $100.00 - $150.00
                    </a>
                  </li>
                  <li className="p-b-6">
                    <a href="#" className="filter-link stext-106 trans-04">
                      $150.00 - $200.00
                    </a>
                  </li>
                  <li className="p-b-6">
                    <a href="#" className="filter-link stext-106 trans-04">
                      $200.00+
                    </a>
                  </li>
                </ul>
              </div>
              <div className="filter-col3 p-r-15 p-b-27">
                <div className="mtext-102 cl2 p-b-15">Color</div>
                <ul>
                  <li className="p-b-6">
                    <span
                      className="fs-15 lh-12 m-r-6"
                      style={{
                        color: "#222",
                      }}
                    >
                      <i className="zmdi zmdi-circle" />
                    </span>
                    <a href="#" className="filter-link stext-106 trans-04">
                      Black
                    </a>
                  </li>
                  <li className="p-b-6">
                    <span
                      className="fs-15 lh-12 m-r-6"
                      style={{
                        color: "#4272d7",
                      }}
                    >
                      <i className="zmdi zmdi-circle" />
                    </span>
                    <a
                      href="#"
                      className="filter-link stext-106 trans-04 filter-link-active"
                    >
                      Blue
                    </a>
                  </li>
                  <li className="p-b-6">
                    <span
                      className="fs-15 lh-12 m-r-6"
                      style={{
                        color: "#b3b3b3",
                      }}
                    >
                      <i className="zmdi zmdi-circle" />
                    </span>
                    <a href="#" className="filter-link stext-106 trans-04">
                      Grey
                    </a>
                  </li>
                  <li className="p-b-6">
                    <span
                      className="fs-15 lh-12 m-r-6"
                      style={{
                        color: "#00ad5f",
                      }}
                    >
                      <i className="zmdi zmdi-circle" />
                    </span>
                    <a href="#" className="filter-link stext-106 trans-04">
                      Green
                    </a>
                  </li>
                  <li className="p-b-6">
                    <span
                      className="fs-15 lh-12 m-r-6"
                      style={{
                        color: "#fa4251",
                      }}
                    >
                      <i className="zmdi zmdi-circle" />
                    </span>
                    <a href="#" className="filter-link stext-106 trans-04">
                      Red
                    </a>
                  </li>
                  <li className="p-b-6">
                    <span
                      className="fs-15 lh-12 m-r-6"
                      style={{
                        color: "#aaa",
                      }}
                    >
                      <i className="zmdi zmdi-circle-o" />
                    </span>
                    <a href="#" className="filter-link stext-106 trans-04">
                      White
                    </a>
                  </li>
                </ul>
              </div>
              <div className="filter-col4 p-b-27">
                <div className="mtext-102 cl2 p-b-15">Tags</div>
                <div className="flex-w p-t-4 m-r--5">
                  <a
                    href="#"
                    className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5"
                  >
                    Fashion
                  </a>
                  <a
                    href="#"
                    className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5"
                  >
                    Lifestyle
                  </a>
                  <a
                    href="#"
                    className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5"
                  >
                    Denim
                  </a>
                  <a
                    href="#"
                    className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5"
                  >
                    Streetstyle
                  </a>
                  <a
                    href="#"
                    className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5"
                  >
                    Crafts
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <Grid container spacing={2}>
          {filteredProducts.map((product, key) => {
            const firstColor = product?.imagesForEachColor
              ? Object.values(product?.imagesForEachColor)[0]
              : ""
            return (
              <Grid
                key={key}
                item
                xs={6}
                sm={3}
                className="block2"
                onClick={() => setSelectedRow(product)}
              >
                <div className="block2-pic hov-img0 pointerCursor">
                  <ImgPath
                    src={firstColor ? firstColor[0] : " product.images[0]"}
                    alt="IMG-PRODUCT"
                  />
                  {/* <ImgPath
                    src={
                      product?.imagesForEachColor &&
                      product?.imagesForEachColor["black"][0]
                    }
                    alt="IMG-PRODUCT"
                  /> */}
                  {/* <div> */}
                  <a
                  onClick={( event) => event.preventDefault()}
                    href="#"
                    className="block2-btn flex-c-m stext-103 cl2 size-102 bg0 bor2 hov-btn1 p-lr-15 trans-04 js-show-modal1"
                  >
                    {t("quick-view")}
                  </a>
                  {/* </div> */}
                </div>
                <div className="block2-txt flex-w flex-t p-t-14">
                  <div className="block2-txt-child1 flex-col-l ">
                    <a
                    onClick={( event) => event.preventDefault()}
                      href="#"
                      className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6"
                    >
                      {product.name}
                    </a>
                    <span className="stext-105 cl3">
                      {product.price + moneyCurrency}
                    </span>
                  </div>
                  {/* <div className="block2-txt-child2 flex-r p-t-3">
                    <a
                      href="#"
                      className="btn-addwish-b2 dis-block pos-relative js-addwish-b2"
                    >
                      <img
                        className="icon-heart1 dis-block trans-04"
                        src="/models/clothing/images/icons/icon-heart-01.png"
                        alt="ICON"
                      />
                      <img
                        className="icon-heart2 dis-block trans-04 ab-t-l"
                        src="/models/clothing/images/icons/icon-heart-02.png"
                        alt="ICON"
                      />
                    </a>
                  </div> */}
                </div>
              </Grid>
            )
          })}
        </Grid>
        {/* Load more */}
        {/* <div className="flex-c-m flex-w w-full p-t-45">
          <a
            href="#"
            className="flex-c-m stext-101 cl5 size-103 bg2 bor1 hov-btn1 p-lr-15 trans-04"
          >
            Load More
          </a>
        </div> */}
      </div>
    </section>
  )
}
