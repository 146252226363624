import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { siteModelOptions } from "../config"
import { setEstablishment } from "../store/reducers/establishment"
import onPageChangeHook from "../utils/onPageChangeHook"
import LoadingOverlay from "./dashboard/myEstablishment/LoadingPopup"
import uniquePageWebsite from "./uniquePageWebsite"

function EstablishmentMap() {
  const { establishment } = useSelector((state) => state.establishment)

  const establishmentId = useParams()?.establishmentId || uniquePageWebsite()

  useEffect(() => {
    setEstablishment(establishmentId)
  }, [])

  onPageChangeHook()

  if (establishment?.isBlocked)
    return (
      <p>This establishment is unavailable for now... Please come back later</p>
    )

  const temp = { width: "calc(100% - 20vw)", marginLeft: "20vw" }
  return (
    (
      <div>
        {/* <MuiDrawer />  */}

        <div>{siteModelOptions[establishment?.siteModel]}</div>
        {/* <BottomInfoBar /> */}
      </div>
    ) || <LoadingOverlay />
  )
}

export default EstablishmentMap
