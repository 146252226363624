import * as React from "react"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DateTimePicker } from "@mui/x-date-pickers"
import { reduxFormDataHook } from "../reactCustomHooks"
import { TextField } from "@mui/material"

export default function DateTimePickerComp({ column }) {
  const {  required, value, onChange2, label, disablePast } =
    reduxFormDataHook(column)
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        disablePast={disablePast}
        renderInput={(props) => <TextField {...props} fullWidth />}
        label={label}
        value={value || null}
        onChange={(newValue) => onChange2( newValue)}
        required={required}
      />
    </LocalizationProvider>
  )
}
