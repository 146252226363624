import { Stack } from "@mui/material"
import { cloneElement, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { returnModifiedFields } from "../functions/myFunctions"
import { updateEstablishment } from "../store/reducers/establishment"
import { convertToBase64 } from "../utils/convertToBase64"
import { IsManager } from "../utils/useIsRole"
import CustomButton from "./Buttons/CustomButton"
import ReactRouterPromptComp from "./ReactRouterPromptComp"

function EditCMSBackgroundImage({ field, children }) {
  const { i18n, t } = useTranslation()
  const { establishment } = useSelector((state) => state.establishment)

  const authorize = IsManager()

  const siteModelModifications = establishment?.siteModelModifications
  const originalData = siteModelModifications?.[field]

  const [isShown, setIsShown] = useState(false)
  const [editOn, setEditOn] = useState(false)
  const [data, setData] = useState(originalData)

  useEffect(() => {
    setData(siteModelModifications?.[field])
  }, [establishment, i18n.language])

  const onSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setEditOn(false)
    updateEstablishment(establishment._id, {
      siteModelModifications: {
        [field]: data,
      },
    })
  }

  const handleToggle = () => {
    if (authorize) {
      inputElem?.click()
    }
  }

  const cancelEdit = () => {
    setEditOn(false)
    setData(siteModelModifications[field])
  }

  const handleImageChange = async (files) => {
    const image64 = await convertToBase64(files)
    setData(image64)
    setEditOn(true)
  }

  var inputElem = document.getElementById("changeImage" + field)
  useEffect(() => {
    inputElem = document.getElementById("changeImage" + field)
  }, [])

  const ElemChildren = cloneElement(children, {
    style: {
      backgroundImage: `url(${data})`,
    },
  })

  if (!authorize) return ElemChildren
  return (
    <div>
      <ReactRouterPromptComp
        isFormDirty={returnModifiedFields(data, originalData)}
      />
      <div style={{ position: "relative" }}>
        {ElemChildren}
        {authorize && !editOn && (
          <Stack
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              position: "absolute",
              inset: "0",
              transition: "all 1s",
              height: "100%",
              bgcolor: "#55555550",
              opacity: !data ? 1 : isShown ? 1 : 0,
              zIndex: 100,
              // minHeight: 200,
            }}
            spacing={2}
          >
            <CustomButton onClick={handleToggle}>
              {data ? "modify-image" : "add-image"}
            </CustomButton>
          </Stack>
        )}
      </div>

      {editOn && (
        <div>
          <CustomButton bgcolor="red" onClick={cancelEdit}>
            {t("cancel")}
          </CustomButton>
          <CustomButton
            disabled={!returnModifiedFields(data, originalData)}
            onClick={onSubmit}
          >
            {t("confirm")}
          </CustomButton>
        </div>
      )}
      <input
        type="file"
        accept="image/*"
        id={"changeImage" + field}
        style={{ display: "none" }}
        onChange={(e) => handleImageChange(e.target.files)}
      />
    </div>
  )
}

export default EditCMSBackgroundImage
