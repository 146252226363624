import { useEffect } from "react"
import { socket } from "../../App"

export function joinRoom(room) {
  if (!typeof room === "string") return console.log("Room wrong type!")
  const authData = JSON.parse(localStorage.getItem("localUserProfile"))
  socket.emit("join", room, authData?.firstName || "Client")
}
export function leaveRoom(room) {
  if (!typeof room === "string") return console.log("Room wrong type!")
  const authData = JSON.parse(localStorage.getItem("localUserProfile"))
  socket.emit("exit", room, authData?.firstName || "Client")
}
export const socketListenerLayout = ({ listenerName, fn, condition }) => {
  useEffect(() => {
    if (condition && !condition) return
    console.log(listenerName + " is on")

    socket?.on(listenerName, fn)

    return () => {
      console.log(listenerName + " is off")

      socket.off(listenerName)
    }
  }, [condition])
}
