import BlockIcon from "@mui/icons-material/Block"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import dayjs from "dayjs"
import { useSelector } from "react-redux"
import CRUDTable from "../../../components/crudTable/CRUDTable"
import {
  setEstablishments,
  updateEstablishmentSupervisor,
} from "../../../store/reducers/establishment"
import TooltipAction from "../../../utils/MuiTooltipAction"
import { formatPhoneNumberWithSpaces } from "../../../utils/formatText"
import { localizeDate } from "../../../utils/dates"

function EstablishmentsPage() {
  const { establishments } = useSelector((state) => state.establishment)

  const handleBlock = (row) => {
    updateEstablishmentSupervisor(row._id, {
      isBlocked: !row.isBlocked,
    })
  }
  const handlePayment = (row) => {
    updateEstablishmentSupervisor(row._id, {
      payment: dayjs().format("YYYY-MM-DD"),
    })
  }

  const columns = [
    {
      header: "name",
      accessorKey: "name",
    },
    {
      header: "owner",
      accessorKey: "owner.email",
    },
    // {
    //   header: "siteModel",
    //   accessorFn: (row) => t(row.siteModel),
    // },
    {
      header: "phoneNumber",
      accessorFn: (row) => formatPhoneNumberWithSpaces(row.owner?.phoneNumber),
    },
    {
      header: "address",
      accessorKey: "address",
    },
    {
      header: "last-payment",
      accessorFn: (row) =>
        localizeDate(row?.payments?.[row?.payments.length - 1]),
    },
    // {
    //   header: "next-payment",
    //   accessorFn: (row) =>
    //     formatDateDays(
    //       dayjs(row?.payments?.[row?.payments.length - 1])
    //         .add(1, "year")
    //         .format("YYYY-MM-DD")
    //     ),
    // },
    // {
    //   header: "subscriptionDate",
    //   accessorKey: "createdAt",
    // },
  ]
  return (
    <div>
      <CRUDTable
        columns={columns}
        rows={establishments}
        fetchFn={setEstablishments}
        addedActionMap={[
          {
            Icon: ({ isBlocked }) =>
              isBlocked ? CheckCircleOutlineIcon : BlockIcon,
            title: ({ isBlocked }) => (isBlocked ? "unBlock" : "block"),
            actionFn: (row) => handleBlock(row),
            color: ({ isBlocked }) => (isBlocked ? "success" : "error"),
          },
        ]}
        rowStyle={({ isBlocked }) => {
          return { bgcolor: isBlocked ? "#FFCCCC" : "inherit" }
        }}
        // deleteFn={deleteOrder}
        // removedField={["readyToPay"]}
      />
    </div>
  )
}

export default EstablishmentsPage
