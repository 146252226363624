import { Grid, Paper, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { ImgPath } from "../../../components/ImagePath"
import { moneyCurrency } from "../../../config"
import { createCart } from "../../../store/actions/cart"
import { resetSelectedRow } from "../../../store/actions/misc"
import { MuiDialog } from "../../../utils/MuiDialog"
import MuiFormMap from "../../../utils/MuiFormMap"
import sweetAlertUtils from "../../../utils/sweetAlertUtils"
import CarouselGallery from "../../../utils/carouselSwiper/CarouselGallery"

function ProductModal() {
  const { selectedRow } = useSelector((state) => state.misc)

  var {
    _id,
    name,
    price,
    imagesForEachColor,
    description,
    sizes,
    colors,
    category,
  } = selectedRow || {}

  const formColumns = [
    {
      accessorKey: "size",
      type: "select",
      required: true,
      options: sizes,
      fullWidth: true,
    },
    {
      accessorKey: "color",
      type: "select",
      required: true,
      options: colors,
      fullWidth: true,
    },
    {
      accessorKey: "quantity",
      type: "numberInput",
      required: true,
      fullWidth: true,
    },
  ]

  const { formDataMap } = useSelector((state) => state.misc2)
  const { color } = formDataMap
  // images =  images.map((url) =>
  // url.replace("upload/", `upload/e_replace_color:${formDataMap.color}/`)
  // )

  const addItem = () => {
    sweetAlertUtils({
      title: name,
      text: "is-added-to-cart",
      type: "success",
      timer: 2000,
    })
    createCart({
      ...formDataMap,
      _id,
      price,
      name,
      images: imagesForEachColor[color],
    })
    resetSelectedRow()
  }
  const { t } = useTranslation()

  if (!selectedRow) return <></>
  return (
    <div>
      <MuiDialog
        Content={
          <Paper sx={{ p: 6 }}>
            <Grid container spacing={6}>
              <Grid sx={{ mt: { xs: 5, md: 0 } }} item sm={6} xs={12}>
                <CarouselGallery
                  images={
                    imagesForEachColor
                      ? imagesForEachColor[color || colors?.[0]]
                      : ""
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography
                  className="mtext-105 cl2 js-name-detail p-b-14"
                  variant="h4"
                  gutterBottom
                >
                  {name}
                </Typography>
                <Typography
                  className="mtext-106 cl2"
                  variant="subtitle1"
                  gutterBottom
                >
                  {price?.toFixed(3)} {moneyCurrency}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {t("category")}: <span>{category?.name}</span>
                </Typography>
                <Typography sx={{ mb: 4 }}>{description}</Typography>
                <MuiFormMap
                  additionalData={{
                    quantity: 1,
                    size: sizes?.[0],
                    color: colors?.[0],
                  }}
                  columns={formColumns}
                  onSubmit={addItem}
                  options={{
                    resetButtonOptions: { show: false },
                    // confirmButtonOptions: {
                    //   show: false ,
                    //   element: (
                    //     <CustomButton bgcolor="purple" center>
                    //       {t("add-to-cart")}
                    //     </CustomButton>
                    //   ),
                    // },
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        }
        forceOpen={selectedRow ? true : false}
        maxWidth={"lg"}
        reset={resetSelectedRow}
      ></MuiDialog>
    </div>
  )
}

export default ProductModal
