import { useTranslation } from "react-i18next"
import EditCMS from "../../../../components/EditCMS"

function About() {
  const { t } = useTranslation()
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>{t("aboutUs.title")}</h2> {/* Translate the title */}
          <p>{t("aboutUs.subTitle")}</p> {/* Translate the description */}
        </div>
        <div className="row">
          <div
            className="col-lg-6 order-1 order-lg-2"
            data-aos="zoom-in"
            data-aos-delay={100}
          >
            <div className="about-img">
              <EditCMS field="aboutImage" type="image" />
            </div>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <EditCMS field="about" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
