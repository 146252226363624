import { useEffect, useRef, useState } from "react"

// material-ui
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import ReceiptIcon from "@mui/icons-material/Receipt"
import ChatIcon from "@mui/icons-material/Chat"
import { CalendarOutlined } from "@ant-design/icons"

// project import
import Transitions from "components/@extended/Transitions"
import MainCard from "components/MainCard"

// assets
import { BellOutlined, CloseOutlined, GiftOutlined } from "@ant-design/icons"
import moment from "moment"
import { useSelector } from "react-redux"
import {
  deleteAllNotification,
  deleteNotification,
  fetchNotificationQuery,
  updateNotification,
} from "../../../../store/actions/notifications"
import CustomButton from "../../../../components/Buttons/CustomButton"
import { useTranslation } from "react-i18next"

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: "1rem",
}

const actionSX = {
  mt: "6px",
  ml: 1,
  top: "auto",
  right: "auto",
  alignSelf: "flex-start",

  transform: "none",
}

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const processTime = (createdAt) => {
  const days = moment().diff(createdAt, "days")
  const hours = moment().diff(createdAt, "hours")
  const minutes = moment().diff(createdAt, "minutes")
  const seconds = moment().diff(createdAt, "seconds")

  if (days) return `${days} j`
  if (hours) return `${hours} h`
  if (minutes) return `${minutes} m`
  if (seconds) return `${seconds} s`
}

const Notification = () => {
  const theme = useTheme()
  const mode =  theme.palette.mode

  const matchesXs = useMediaQuery(theme.breakpoints.down("md"))
  const [filter, setFilter] = useState("all") // Default filter

  const handleFilter = (type) => {
    setFilter(type)
  }
  const { t } = useTranslation()
  const { notifications } = useSelector((state) => state.notification)

  // Fetch Notification
  useEffect(() => {
    fetchNotificationQuery()
  }, [])

  const handleDeleteAllNotification = () => {
    deleteAllNotification()
  }
  const handleDeleteNotification = async (id) => {
    deleteNotification(id)
  }
  const [firstOpen, setFirstOpen] = useState(false)
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const handleToggle = () => {
    if (!firstOpen) {
      setFirstOpen(true)
      notifications.map((noti) => {
        if (!noti.read) updateNotification(noti._id, { read: true })
      })
    }

    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const iconBackColorOpen = "grey.300"
  const iconBackColor = "grey.100"

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        className="custom-hover"
        disableRipple
        color="secondary"
        sx={{
          border : "1px solid #e9e9e9",
          color: "text.primary",
          // bgcolor: open ? iconBackColorOpen : iconBackColor,
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge
          badgeContent={notifications?.filter((noti) => !noti.read).length}
          color="error"
        >
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? -5 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: "100%",
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down("md")]: {
                  maxWidth: 285,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Notification"
                  elevation={0}
                  border={false}
                  content={false}
                  sx={{
                    textAlign: "center",
                    height: "100%",
                    maxHeight: "80vh",
                    overflow: "scroll",
                    overflowX: "hidden",
                  }}

                  // secondary={}
                >
                  {/* Insert Data */}
                  <List
                    component="nav"
                    sx={{
                      p: 0,
                      "& .MuiListItemButton-root": {
                        py: 0.5,
                        "& .MuiAvatar-root": avatarSX,
                        "& .MuiListItemSecondaryAction-root": {
                          ...actionSX,
                          position: "relative",
                        },
                      },
                    }}
                  >
                    {/* Filter buttons */}
                    <div className="filter-buttons">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <ListItemButton onClick={() => handleFilter("all")}>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color={
                                  filter === "all" ? "primary" : "text.primary"
                                }
                              >
                                All
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton onClick={() => handleFilter("order")}>
                          {/* <ListItemIcon>
                            <ReceiptIcon />
                          </ListItemIcon> */}
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color={
                                  filter === "order"
                                    ? "primary"
                                    : "text.primary"
                                }
                              >
                                Orders
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton onClick={() => handleFilter("claim")}>
                          {/* <ListItemIcon>
                            <ChatIcon />
                          </ListItemIcon> */}
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color={
                                  filter === "claim"
                                    ? "primary"
                                    : "text.primary"
                                }
                              >
                                Claims
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          onClick={() => handleFilter("reservation")}
                        >
                          {/* <ListItemIcon>
                            <CalendarOutlined />
                          </ListItemIcon> */}
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color={
                                  filter === "reservation"
                                    ? "primary"
                                    : "text.primary"
                                }
                              >
                                Reservations
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Stack>
                    </div>
                    {notifications.map((notification, key) => {
                      // Apply the selected filter
                      if (
                        filter === "all" ||
                        notification.message.toLowerCase().indexOf(filter) !==
                          -1
                      )
                        return (
                          <>
                            <ListItemButton>
                              <ListItemAvatar>
                                <Avatar
                                  sx={{
                                    color: "red",
                                    bgcolor: "success.lighter",
                                  }}
                                >
                                  {(() => {
                                    if (
                                      notification.message
                                        .toLowerCase()
                                        .indexOf("order") !== -1
                                    )
                                      return <ReceiptIcon />
                                    if (
                                      notification.message
                                        .toLowerCase()
                                        .indexOf("claim") !== -1
                                    )
                                      return <ChatIcon />
                                    if (
                                      notification.message
                                        .toLowerCase()
                                        .indexOf("reservation") !== -1
                                    )
                                      return <CalendarOutlined />
                                  })()}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <Typography variant="h6">
                                    {t(notification.message)}
                                  </Typography>
                                }
                                secondary={
                                  "Il y'a " +
                                  processTime(notification.createdAt)
                                }
                                // secondary={notification.read ? "Vu" : "Non lue"}
                              />
                              <ListItemSecondaryAction>
                                <Typography variant="caption" noWrap>
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      handleDeleteNotification(notification._id)
                                    }
                                  >
                                    <CloseOutlined />
                                  </IconButton>
                                </Typography>
                              </ListItemSecondaryAction>
                            </ListItemButton>
                            <Divider />
                          </>
                        )
                    })}
                    {!notifications.length && (
                      <Typography variant="h5" sx={{ textAlign: "center" }}>
                        {t("notification.noNotification")}
                      </Typography>
                    )}
                  </List>
                  <CustomButton
                    bgcolor="red"
                    onClick={handleDeleteAllNotification}
                  >
                    deleteAll
                  </CustomButton>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  )
}

export default Notification
