import { lazy } from "react"

// project import
import Loadable from "components/Loadable"
import MainLayout from "layout/MainLayout"
import QrCodePage from "../pages/dashboard/qrCode/QrCodePage"
import TermsAndConditions from "../pages/dashboard/termsAndCondition/TermsAndConditions"
import ProtectedRoutes from "../utils/ProtectedRoutes"

// Lazy load your components
const ReservationPage = Loadable(
  lazy(() => import("../pages/dashboard/reservation/ReservationPage"))
)
const MyProfile = Loadable(
  lazy(() => import("../pages/dashboard/myProfile/MyProfilePage"))
)
const CategoryPage = Loadable(
  lazy(() => import("../pages/dashboard/category/CategoryPage"))
)
const ClaimPage = Loadable(
  lazy(() => import("../pages/dashboard/claim/ClaimPage"))
)
const StatisticPage = Loadable(
  lazy(() => import("../pages/dashboard/statistic/StatisticPage"))
)
const GalleryPage = Loadable(
  lazy(() => import("../pages/dashboard/gallery/GalleryPage"))
)
const MyEstablishment = Loadable(
  lazy(() => import("../pages/dashboard/myEstablishment/MyEstablishment"))
)
const OrderPage = Loadable(
  lazy(() => import("../pages/dashboard/order/OrderPage"))
)
const ProductPage = Loadable(
  lazy(() => import("../pages/dashboard/product/ProductPage"))
)
const StaffPage = Loadable(
  lazy(() => import("../pages/dashboard/staff/StaffPage"))
)
const ExtraPage = Loadable(
  lazy(() => import("../pages/dashboard/extra/ExtraPage"))
)

// render - dashboard
const EstablishmentHome = Loadable(
  lazy(() => import("../pages/dashboard/establishmentSite/RestaurantModel"))
)

import { CalendarOutlined, DashboardOutlined } from "@ant-design/icons"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import CategoryIcon from "@mui/icons-material/Category"
import ChatIcon from "@mui/icons-material/Chat"
import CollectionsIcon from "@mui/icons-material/Collections"
import FastfoodIcon from "@mui/icons-material/Fastfood"
import FindInPageIcon from "@mui/icons-material/FindInPage"
import GroupIcon from "@mui/icons-material/Group"
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy"
import PaymentIcon from "@mui/icons-material/Payment"
import QrCodeIcon from "@mui/icons-material/QrCode"
import ReceiptIcon from "@mui/icons-material/Receipt"
import StoreIcon from "@mui/icons-material/Store"
import { SupervisorRole, managerRole, waiterRole } from "../config"
import EstablishmentMap from "../pages/EstablishmentMap"
import EstablishmentsPage from "../pages/dashboard/establishments/EstablishmentsPage"
import ManagerPage from "../pages/dashboard/manager/ManagerPage"
import { NextPayment } from "../pages/dashboard/nextPayment/NextPayment"
import Home from "../pages/home/Home"
import EcommerceOrderPage from "../pages/dashboard/order/EcommerceOrderPage"
import BlogPage from "../pages/dashboard/blog/BlogPage"
import { BookIcon, ProductionQuantityLimitsIcon } from "../utils/MuiIcons"
import uniquePageWebsite from "../pages/uniquePageWebsite"
const icons = {
  CalendarOutlined,
  DashboardOutlined,
}

// ==============================|| MAIN ROUTING ||============================== //

const waiterAndManager = [managerRole, waiterRole]

const dashboardItems = [
  {
    path: "default",
    element: <StatisticPage />,
    roles: managerRole,
    title: "statistics",
    type: "item",
    icon: icons.DashboardOutlined,
  },

  {
    path: "profile",
    element: <MyProfile />,
    // roles: allRoles,
    title: "profile",
    type: "item",
    icon: AccountCircleIcon,
  },
  {
    path: "establishments",
    element: <EstablishmentsPage />,
    roles: SupervisorRole,
    title: "establishments",
    type: "item",
    icon: StoreIcon,
  },
  {
    path: "next_payments",
    element: <NextPayment />,
    roles: SupervisorRole,
    title: "next-payment",
    type: "item",
    icon: PaymentIcon,
  },
  {
    path: "managers",
    element: <ManagerPage />,
    roles: SupervisorRole,
    title: "managers",
    type: "item",
    icon: GroupIcon,
  },

  // {
  //   path: "terms_and_conditions",
  //   element: <TermsAndConditions />,
  //   roles: managerRole,
  //   title: "termsAndCondition",
  //   type: "item",
  //   icon: FindInPageIcon,
  // },
]

const establishmentGroup = [
  {
    path: "my_establishment",
    element: <MyEstablishment />,
    roles: managerRole,
    title: "myEstablishment",
    type: "item",
    icon: StoreIcon,
  },
  {
    path: "gallery",
    element: <GalleryPage />,
    roles: managerRole,
    title: "gallery",
    type: "item",
    icon: CollectionsIcon,
    siteModel: ["restaurant", "clothing"],
  },
  {
    path: "qrcode",
    element: <QrCodePage />,
    roles: managerRole,
    title: "Qr Code",
    type: "item",
    icon: QrCodeIcon,
    // siteModel: ["restaurant"],
  },
]

const reservationGroup = [
  {
    path: "reservation",
    element: <ReservationPage />,
    roles: waiterAndManager,
    title: "reservation",
    type: "item",
    icon: icons.CalendarOutlined,
  },
]

const gestionDesCommandesItems = [
  {
    path: "order",
    element: <OrderPage />,
    roles: waiterAndManager,
    title: "gestionDesCommandesItems.order",
    type: "item",
    icon: ReceiptIcon,
    chip: { label: "!", color: "error" },
    siteModel: ["restaurant"],
  },
  {
    path: "ecommerce_order",
    element: <EcommerceOrderPage />,
    roles: managerRole,
    title: "gestionDesCommandesItems.order",
    type: "item",
    icon: ReceiptIcon,
    chip: { label: "!", color: "error" },
    siteModel: ["clothing"],
  },
  {
    path: "blog_page",
    element: <BlogPage />,
    roles: managerRole,
    title: "blog",
    type: "item",
    icon: BookIcon,
    siteModel: ["clothing"],
  },
  {
    path: "claim",
    element: <ClaimPage />,
    roles: waiterAndManager,
    title: "gestionDesCommandesItems.claim",
    type: "item",
    icon: ChatIcon,
    siteModel: ["restaurant"],
  },
]

const gestionDuStockItems = [
  {
    path: "category",
    element: <CategoryPage />,
    roles: managerRole,
    title: "gestionDuStockItems.category",
    type: "item",
    icon: CategoryIcon,
  },
  {
    path: "product",
    element: <ProductPage />,
    roles: managerRole,
    title: "gestionDuStockItems.product",
    type: "item",
    icon: ProductionQuantityLimitsIcon,
  },
  {
    path: "extra",
    element: <ExtraPage />,
    roles: managerRole,
    title: "gestionDuStockItems.extra",
    type: "item",
    icon: LocalPharmacyIcon,
    siteModel: ["restaurant"],
  },
]

const gestionDuPersonnelItems = [
  {
    path: "staff",
    element: <StaffPage />,
    roles: managerRole,
    title: "staff",
    type: "item",
    icon: GroupIcon,
  },
]

// For Dashboard navigation
export const menuItems = [
  {
    title: "groups.general",
    type: "group",
    // roles: [waiterAndManager],
    children: dashboardItems,
  },
  // {
  //   title: "groups.general",
  //   type: "group",
  //   roles: SupervisorRole,
  //   children: supervisorItems,
  // },
  {
    title: "establishment",
    type: "group",
    roles: managerRole,
    children: establishmentGroup,
  },
  {
    title: "reservationManagement",
    type: "group",
    roles: waiterAndManager,
    siteModel: ["restaurant"],
    children: reservationGroup,
  },
  {
    title: "groups.gestionDesCommandes",
    type: "group",
    roles: waiterAndManager,
    children: gestionDesCommandesItems,
  },
  {
    title: "menuManagement",
    type: "group",
    roles: managerRole,
    children: gestionDuStockItems,
  },
  {
    title: "groups.gestionDuPersonnel",
    type: "group",
    roles: managerRole,
    children: gestionDuPersonnelItems,
  },
]

export const mainRoutesData = [
  ...dashboardItems,
  ...reservationGroup,
  ...establishmentGroup,
  ...gestionDesCommandesItems,
  ...gestionDuStockItems,
  ...gestionDuPersonnelItems,
]

const MainRoutes = [
  {
    path: uniquePageWebsite() ? "/*" : "/",
    element: uniquePageWebsite() ? <EstablishmentMap /> : <Home />,
    // path: "/",
    // element: <Home />,
  },
  ...(!uniquePageWebsite()
    ? [
        {
          path: "/establishment/:establishmentId/*",
          element: <EstablishmentMap />,
        },
      ]
    : []),
  {
    path: "/dashboard",
    element: (
      <ProtectedRoutes status="CONNECTED">
        <MainLayout />
      </ProtectedRoutes>
    ),
    children: mainRoutesData.map((route) => ({
      path: route.path,
      element: (
        <ProtectedRoutes siteModel={route?.siteModel} roles={route.roles}>
          {route.element}
        </ProtectedRoutes>
      ),
    })),
  },
]

export default MainRoutes
