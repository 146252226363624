import * as actionType from "../actionTypes"

import { dispatch } from "../"
import { toast } from "react-toastify"
import { tradSentence } from "../../utils/tradSentence.js"

export const setOpenTrigger = (number) =>
  dispatch({
    type: actionType.SET_OPEN_TRIGGER,
    payload: number,
  })

export const feedbackSuccess = (feedback) => {
  const translatedFeedback = tradSentence(feedback)
  toast.success(translatedFeedback)
}

export const feedbackError = (feedback) => {
  const translatedFeedback = tradSentence(feedback)
  toast.error(translatedFeedback)
}

export const startLoading = () =>
  dispatch({
    type: actionType.START_LOADING,
  })
export const endLoading = () =>
  dispatch({
    type: actionType.END_LOADING,
  })

export const resetSelectedRow = () =>
  dispatch({ type: actionType.SET_SELECTED_ROW, payload: null })

export const setSelectedRow = (data) =>
  dispatch({ type: actionType.SET_SELECTED_ROW, payload: data })

export const feedbackInfo = (feedback) => {
  const translatedFeedback = tradSentence(feedback)
  toast.info(translatedFeedback)
}

export default {
  feedbackSuccess,
  feedbackError,
  resetSelectedRow,
  setSelectedRow,
  feedbackInfo,
}
