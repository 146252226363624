import imageCompression from "browser-image-compression"
import { baseURL } from "../store/axiosFunctions"

// export const convertToBase64 = (files, compress = true) =>
//   new Promise(async (resolve, reject) => {
//     const compressedFiles = []

//     if (!files || !typeof files === "object") return
//     if (!files.length) files = [files]

//     for (const file of files) {
//       const compressedFile = compress
//         ? await imageCompression(file, {
//             maxSizeMB: 1,
//             maxWidthOrHeight: 1000,
//           })
//         : file
//       const reader = new FileReader()
//       reader.readAsDataURL(compressedFile)
//       reader.onload = () => compressedFiles.push(reader.result)
//       reader.onerror = (error) => reject(error)
//     }
//     setTimeout(() => {
//       resolve(
//         compressedFiles.length === 1 ? compressedFiles[0] : compressedFiles
//       )
//     },  500)
//   })

export async function convertToBase64(files, compress = false) {
  const compressedFiles = []

  if (!files instanceof FileList && !Array.isArray(files)) files = [files]

  const filePromises = Array.from(files).map(async (file) => {
    const reader = new FileReader()
    const compressedFile = compress
      ? await imageCompression(file, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1000,
        })
      : file

    return new Promise((resolve) => {
      reader.readAsDataURL(compressedFile)

      reader.onloadend = () => {
        compressedFiles.push(reader.result)
        resolve()
      }
    })
  })

  await Promise.all(filePromises)

  return compressedFiles.length === 1 ? compressedFiles[0] : compressedFiles
}

export function isBase64(str) {
  if (typeof str !== "string") return false

  return str?.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
}
export function base64OrPath(imageString) {
  if (!imageString) return ""
  return imageString?.indexOf("DBImages") !== -1
    ?  imageString.replaceAll("\\", "/")
    // ? baseURL + imageString.replaceAll("\\", "/")
    : imageString
}
