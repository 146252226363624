import { useState } from "react"
import { useTranslation } from "react-i18next" // Import useTranslation hook
import { ImgPath } from "../../../../components/ImagePath"
import { setSelectedRow } from "../../../../store/actions/misc"
import { moneyCurrency } from "../../../../config"
import { useSelector } from "react-redux"

function MenuSection() {
  const { categories } = useSelector((state) => state.category)
  const products = useSelector((state) =>
    state.product.products.filter((product) => !product.extra)
  )
  const [selectedCategory, setSelectedCategory] = useState("ALL")
  const { t } = useTranslation() // Use the t function for translations

  const handleAddToCart = (product) => {
    setSelectedRow(product)
  }

  const filterProduct = products.filter((product) =>
    selectedCategory !== "ALL"
      ? product.category?._id === selectedCategory
      : true
  )

  if (!products.length) return <></>

  return (
    <section id="menu" className="menu section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>{t("menu.menuTitle")}</h2>
          <p>{t("menu.checkOurMenu")}</p>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay={100}>
          <div className="col-lg-12 d-flex justify-content-center">
            <ul id="menu-flters">
              <li
                data-filter="*"
                className={
                  "custom-hover-10 " +
                  (selectedCategory === "ALL" ? "filter-active" : "")
                }
                onClick={() => setSelectedCategory("ALL")}
              >
                {t("menu.all")}
              </li>
              {categories.map((category, key) => (
                <li
                  key={key}
                  onClick={() => setSelectedCategory(category._id)}
                  className={
                    "custom-hover-10 " +
                    (selectedCategory === category._id ? "filter-active" : "")
                  }
                  data-filter=".filter-starters"
                >
                  {category.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          className="row menu-container"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          {filterProduct.length ? (
            filterProduct.map((product, key) => (
              <div
                key={key}
                className={`col-lg-6 menu-item filter-starters custom-hover-05`}
                onClick={() => setSelectedRow(product)}
              >
                <ImgPath
                  src={product.images}
                  className="custom-hover-20 menu-img"
                  alt=""
                />
                <div className="menu-content">
                  <a>{product.name}</a>
                  <span>{product.price + moneyCurrency}</span>
                </div>
                <div className="menu-ingredients">
                  {product.components.join(", ")}
                  <button
                    className="addButton button-hover"
                    style={{ marginLeft: "auto" }}
                    onClick={() => handleAddToCart(product)}
                  >
                    {t("menu.addToCart")}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <h3 style={{ textAlign: "center" }}>{t("menu.noProductYet")}</h3>
          )}
        </div>
      </div>
    </section>
  )
}

export default MenuSection
