import React from "react"
import {Typography} from "@mui/material"
import { useTranslation } from "react-i18next"
import { tradSentence } from "./tradSentence"

function TypographyTrad(props) {
  const { t } = useTranslation()
  var text = props.children
  if (Array.isArray(text)) text = text.join("")

  return <Typography {...props}>{tradSentence(text)}</Typography>
}

export default TypographyTrad
