import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { formatPhoneNumberWithSpaces } from "utils/formatText"
import { createClaim } from "../../../../store/actions/claim"
import OrangeButton from "../../../../components/Buttons/orangeButton/OrangeButton"
import { useSelector } from "react-redux"
import { Grid } from "@mui/material"

function ContactSection() {
  const { establishment } = useSelector((state) => state.establishment)
  const { t } = useTranslation()
  const { tableNumber } = useSelector((state) => state.misc)
  const { twitter, facebook, instagram } = establishment

  // Initialize state variables for form fields
  const [formData, setFormData] = useState({
    tableNumber: "",
    type: "",
    message: "",
    establishment,
    tableNumber,
  })

  // Handle changes in form fields
  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()

    createClaim(formData)
  }

  return (
    <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>{t("contact")}</h2> {/* Translate the title */}
          <p>{t("contact")}</p> {/* Translate the description */}
        </div>

        <div className="container" data-aos="fade-up">
          <div className="row mt-5">
            <div className="">
              <div className="info">
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <i className="bi bi-geo-alt" />
                    <h4>{t("location")}:</h4>
                    <p>{establishment.address}</p>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <i className="bi bi-clock" />
                    <h4>{t("openHours")}:</h4>
                    <p>
                      {t("topBar.openingHours", {
                        openingTime: establishment?.openingTime,
                        closingTime: establishment?.closingTime,
                      })}
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <i className="bi bi-envelope" />
                    <h4>{t("email")}:</h4>
                    <p>{establishment.email}</p>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <i className="bi bi-phone" />
                    <h4>{t("call")}:</h4>
                    <p>
                      +216{" "}
                      {formatPhoneNumberWithSpaces(establishment.phoneNumber)}
                    </p>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div style={{ fontSize: "1.5rem" }} className="social-links mt-3">
              {twitter && (
                <a href={twitter} className="twitter">
                  <i className="bx bxl-twitter" />
                </a>
              )}
              {facebook && (
                <a href={facebook} className="facebook">
                  <i className="bx bxl-facebook" />
                </a>
              )}
              {instagram && (
                <a href={instagram} className="instagram">
                  <i className="bx bxl-instagram" />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactSection
