import * as actionType from "../actionTypes"
export default (
  state = {
    booking : null,
    bookings: [],
  },
  action
) => {
  const payload = action.payload
  switch (action.type) {

    case actionType.SET_BOOKING:
      return { ...state, booking: payload }

    case actionType.SET_BOOKINGS:
      return { ...state, bookings: payload }

    case actionType.ADD_BOOKING:
      return {
        ...state,
        bookings: [payload,...state.bookings],
      }

    case actionType.UPDATE_BOOKING:
      return {
        ...state,
        bookings: state.bookings.map((document) =>
         document._id === payload._id ? payload : document
        ),
      }

    case actionType.DELETE_BOOKING:
      return {
        ...state,
        bookings: state.bookings.filter(
          (document) => document._id !== payload
        ),
      }

    default:
      return state
  }
}
