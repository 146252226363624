import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { formatPhoneNumberWithSpaces } from "../../../utils/formatText"

function Contact() {
  const { establishment } = useSelector((state) => state.establishment)
  const { t } = useTranslation()
  const {
    googleMapMarker,
    address,
    phoneNumber,
    email,
  } = establishment
  return (
    <>
      {/* Title page */}
      <section
        className="bg-img1 txt-center p-lr-15 p-tb-92"
        style={{ backgroundImage: 'url("/models/clothing/images/bg-01.jpg")' }}
      >
        <h2 className="ltext-105 cl0 txt-center">{t("contact")}</h2>
      </section>
      {/* Content page */}
      <section className="bg0 p-t-104 p-b-116">
        <div className="container">
          <div className="flex-w flex-tr">
            <div className="size-210 bor10 p-lr-70 p-t-55 p-b-70 p-lr-15-lg w-full-md">
              <form>
                <h4 className="mtext-105 cl2 txt-center p-b-30">
                  {t("send-us-a-message")}{" "}
                </h4>
                <div className="bor8 m-b-20 how-pos4-parent">
                  <input
                    className="stext-111 cl2 plh3 size-116 p-l-62 p-r-30"
                    type="text"
                    name="email"
                    placeholder={t("your-email-address")}
                  />
                  <img
                    className="how-pos4 pointer-none"
                    src="/models/clothing/images/icons/icon-email.png"
                    alt="ICON"
                  />
                </div>
                <div className="bor8 m-b-30">
                  <textarea
                    className="stext-111 cl2 plh3 size-120 p-lr-28 p-tb-25"
                    name="msg"
                    placeholder={t("how-can-we-help")}
                    defaultValue={""}
                  />
                </div>
                <button disabled className="flex-c-m stext-101 cl0 size-121 bg3 bor1 hov-btn3 p-lr-15 trans-04 pointer">
                  {t("submit")}{" "}
                </button>
              </form>
            </div>
            <div className="size-210 bor10 flex-w flex-col-m p-lr-93 p-tb-30 p-lr-15-lg w-full-md">
              <div className="flex-w w-full p-b-42">
                <span className="fs-18 cl5 txt-center size-211">
                  <span className="lnr lnr-map-marker" />
                </span>
                <div className="size-212 p-t-2">
                  <span className="mtext-110 cl2">{t("address")}</span>
                  <p className="stext-115 cl6 size-213 p-t-18">{address}</p>
                </div>
              </div>
              <div className="flex-w w-full p-b-42">
                <span className="fs-18 cl5 txt-center size-211">
                  <span className="lnr lnr-phone-handset" />
                </span>
                <div className="size-212 p-t-2">
                  <span className="mtext-110 cl2">{t("lets-talk")}</span>
                  <p className="stext-115 cl1 size-213 p-t-18">
                    +216 {formatPhoneNumberWithSpaces(phoneNumber)}
                  </p>
                </div>
              </div>
              {email && (
                <div className="flex-w w-full">
                  <span className="fs-18 cl5 txt-center size-211">
                    <span className="lnr lnr-envelope" />
                  </span>
                  <div className="size-212 p-t-2">
                    <span className="mtext-110 cl2">{t("sale-support")}</span>
                    <p className="stext-115 cl1 size-213 p-t-18">{email}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* Map */}
      <div className="map">
        {googleMapMarker && (
          <div data-aos="fade-up">
            <iframe
              style={{ border: 0, width: "100%", height: 350 }}
              src={googleMapMarker}
              frameBorder={0}
              allowFullScreen=""
            />
          </div>
        )}
        {/* <div
        className="size-303"
        id="google_map"
        data-map-x="40.691446"
        data-map-y="-73.886787"
        data-pin="/models/clothing/images/icons/pin.png"
        data-scrollwhell={0}
        data-draggable={1}
        data-zoom={11}
      /> */}
      </div>
    </>
  )
}

export default Contact
