import JSZip from "jszip"


export function downloadAllArchive(archiveName = "download") {
  const extension = ".png"
  var zip = new JSZip()
  ;[...document.getElementsByClassName("dl")].forEach((img, i) =>
    zip.file(img?.alt + extension, img.src.replace(/data:.*?;base64,/, ""), {
      base64: true,
    })
  )
  zip.generateAsync({ type: "base64" }).then(download)

  function download(data) {
    const a = document.createElement("a")
    a.href = "data:application/zip;base64," + data
    a.setAttribute("download", archiveName+".zip")
    a.style.display = "none"
    a.addEventListener("click", (e) => e.stopPropagation()) // not relevant for modern browsers
    document.body.appendChild(a)
    setTimeout(() => {
      // setTimeout - not relevant for modern browsers
      a.click()
      document.body.removeChild(a)
    }, 0)
  }
}
