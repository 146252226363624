import TextIcon from "@mui/icons-material/TextFields"
import { Chip, Grid, Paper, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import CustomButton from "../components/Buttons/CustomButton"
import { ImgPath } from "../components/ImagePath"
import { setDisplayDataFormMap } from "../store/reducers/misc2"
import { ActionBar } from "./ActionBar"
import CustomCard from "./CustomCard"
import { MuiDialog } from "./MuiDialog"
import {
  AccessTimeIcon,
  DataArrayIcon,
  ImageIcon,
  LayersIcon,
  NumericValueIcon,
  RuleIcon,
  SearchIcon,
} from "./MuiIcons"
import { MuiTextField3 } from "./MuiFormComponents/MuiTextField3"
import { localizeDate } from "./dates"

const defaultRemovedFieldConfig = [
  "elapsedTimeBeforeCheckout",
  "elapsedTimeBeforeConfirmation",
  "elapsedTimeBeforePreparation",
  "elapsedTimeBeforeServed",
  "elapsedTimeBeforePayment",
  "checkoutTime",
  "confirmationTime",
  "preparationStartTime",
  "servedStartTime",
  "paymentTime",
  "orderDuration",
  "productInCartId",
  "addedToCart",
  "_id",
  "__v",
  "updatedAt",
  "createdAt",
  "password",
  "isBlocked",
  "accessToken",
  "available",
]

function DisplayDataMap({ actions, removedFieldConfig }) {
  removedFieldConfig = [
    ...defaultRemovedFieldConfig,
    ...(removedFieldConfig ? removedFieldConfig : []),
  ]
  const { t } = useTranslation()
  const { displayDataFormMap } = useSelector((state) => state.misc2)

  return (
    <MuiDialog
      title="display-data"
      forceOpen={displayDataFormMap ? true : false}
      maxWidth={"md"}
      reset={() => setDisplayDataFormMap(null)}
      Content={
        <div>
          <Content
            data={displayDataFormMap}
            removedFieldConfig={removedFieldConfig}
          />
          {actions && displayDataFormMap && (
            <ActionBar
              actions={actions}
              displayDataFormMap={displayDataFormMap}
            />
          )}
        </div>
      }
    ></MuiDialog>
  )
}

export default DisplayDataMap

function Content({ removedFieldConfig, data }) {
  const { formDataMap } = useSelector((state) => state.misc2)
  const { search } = formDataMap
  const typeMap = {
    number: {
      icon: NumericValueIcon,
      element: (data) => data,
    },
    image: {
      icon: ImageIcon,
      element: (data) => <ImgPath width="100" src={data} />,
    },
    object: {
      icon: LayersIcon,
      element: (data, keyTitle) => (
        <MuiDialog
          Content={
            <Content removedFieldConfig={removedFieldConfig} data={data} />
          }
          title={keyTitle || "display-data"}
          maxWidth={"md"}
        >
          <Chip color="primary" label={t("view")}></Chip>
          {/* <CustomButton helperText={"click-for-more-informations"}> */}
          {/* view */}
          {/* </CustomButton> */}
          {/* view|: |{data?.name ? data.name : "|" + keyTitle} */}
        </MuiDialog>
      ),
    },
    array: {
      icon: DataArrayIcon,
      // element: (data) => data.join(", "),
      element: (data, mainKey) =>
        data.length
          ? data.every((val) => typeof val !== "object")
            ? data.join(", ")
            : data.map((value, key) => {
                if (removedFieldConfig.includes(key)) return <></>
                const result = typeMap[getValueType(value)]
                if (!result) return <></>
                const Icon = result.icon
                const Element = result.element(value, mainKey)
                return (
                  // <CustomCard>
                  <Stack
                  key={key} 
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    flexWrap={"wrap"}
                    sx={{ width: "100%" }}
                    spacing={2}
                  >
                    <Icon sx={{ mr: 2 }} />
                    <span variant="body1">
                      {/* {t("element") + " " + (key + 1) + "°"} */}
                      {key + 1}: {Element}
                    </span>
                  </Stack>
                  // </CustomCard>
                )
              })
          : t("no-data"),
    },
    date: {
      icon: AccessTimeIcon,
      element: (data) => localizeDate(data),
    },
    boolean: {
      icon: RuleIcon,
      element: (data) => t(data.toString()),
    },
    text: {
      icon: TextIcon,
      element: (data) => (!data ? t("no-data") : t(data)),
    },
  }
  const { t } = useTranslation()
  if (data)
    return (
      <Grid sx={{ p: 2 }} container spacing={2}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <MuiTextField3
              column={{ accessorKey: "search", Icon: SearchIcon }}
            />
          </Paper>
        </Grid>
        {Object.keys(data)
          .filter((key) =>
            search
              ? t(key).toLowerCase().indexOf(t(search)) !== -1 ||
                key.toLowerCase().indexOf(search) !== -1
              : true
          )
          .map((key) => {
            if (removedFieldConfig.includes(key)) return <></>

            const value = data[key]
            const result = typeMap[getValueType(value)]
            if (!result) return <></>
            const Icon = result.icon
            const Element = result.element(value, key)
            return (
              <Grid key={key} item xs={12} sm={6}>
                <CustomCard>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Icon sx={{ mr: 2 }} />
                    <span variant="body1">
                      <strong>{t(key)}</strong>: {Element}
                    </span>
                  </Stack>
                </CustomCard>
              </Grid>
            )
          })}
      </Grid>
    )
}

function isDate(str) {
  const date = new Date(str)

  return !isNaN(date.getTime()) && date.toString() !== "Invalid Date"
}

const getValueType = (value) => {
  const IMAGE_EXTENSIONS = /\.(jpeg|jpg|gif|png)$/

  if (value === "") return null
  if (isMongoObjectId(value)) return null
  if (Array.isArray(value) && !value.length) return null
  // if (!Object?.keys(value)?.length) return null
  var type = "text"
  if (IMAGE_EXTENSIONS.test(value)) type = "image"
  else if (Array.isArray(value)) type = "array"
  else if (typeof value === "boolean") type = "boolean"
  else if (typeof value === "object") type = "object"
  else if (typeof value === "number") type = "number"
  else if (isDate(value)) type = "date"

  return type
}

function isMongoObjectId(str) {
  const objectIdRegex = /^[0-9a-fA-F]{24}$/
  return objectIdRegex.test(str)
}
