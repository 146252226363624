// action - account reducer
export const LOGIN = "@auth/LOGIN"
export const REGISTER = "@auth/REGISTER"

{
  /* //! -------- Authentification ------------ */
}
export const AUTH = "AUTH"
export const LOGOUT = "LOGOUT"
export const SET_STAY_CONNECTED = "SET_STAY_CONNECTED"

//! ------------- USER -----------------
export const LOADING_USER = "LOADING_USER"
export const END_LOADING_USER = "END_LOADING_USER"
export const SET_USER = "SET_USER"
export const SET_USERS = "SET_USERS"
export const ADD_USER = "ADD_USER"
export const UPDATE_USER = "UPDATE_USER"
export const DELETE_USER = "DELETE_USER"

{
  /* //! -------------- Misc ------------------ */
}

export const SET_OPEN_TRIGGER = "SET_OPEN_TRIGGER"
export const SET_TABLE_NUMBER = "SET_TABLE_NUMBER"
export const SET_SELECTED_ROW = "SET_SELECTED_ROW"
export const SET_FEEDBACK_OBJECT = "SET_FEEDBACK_OBJECT"
export const SET_MODAL_VIEW = "SET_MODAL_VIEW"

export const START_LOADING = "START_LOADING"
export const END_LOADING = "END_LOADING"

/* #region NOTIFICATION  */
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS"
export const ADD_NOTIFICATION = "ADD_NOTIFICATION"
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION"
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION"
export const DELETE_ALL_NOTIFICATION = "DELETE_ALL_NOTIFICATION"

/* #endregion */


/* #region Order  */
export const SET_ORDER = 'SET_ORDER';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_OLD_ORDERS = 'SET_OLD_ORDERS';
export const ADD_OLD_ORDER = 'ADD_OLD_ORDER';
export const ADD_ORDER = 'ADD_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';

 /* #endregion */ 

/* #region claim  */
export const SET_CLAIMS = 'SET_CLAIMS';
export const ADD_CLAIM = 'ADD_CLAIM';
export const UPDATE_CLAIM = 'UPDATE_CLAIM';
export const DELETE_CLAIM = 'DELETE_CLAIM';

/* #endregion */
 
/* #region category  */
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';

/* #endregion */
 
/* #region Product  */
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';

/* #endregion */
 
/* #region establishment  */
export const SET_ESTABLISHMENT_IS_LOADING = 'SET_ESTABLISHMENT_IS_LOADING';
export const SET_ESTABLISHMENT_IS_NOT_LOADING = 'SET_ESTABLISHMENT_IS_NOT_LOADING';
export const SET_ESTABLISHMENT = 'SET_ESTABLISHMENT';
export const SET_ESTABLISHMENTS = 'SET_ESTABLISHMENTS';
export const ADD_ESTABLISHMENT = 'ADD_ESTABLISHMENT';
export const UPDATE_ESTABLISHMENT = 'UPDATE_ESTABLISHMENT';
export const DELETE_ESTABLISHMENT = 'DELETE_ESTABLISHMENT';

 /* #endregion */
 
/* #region Review  */
export const SET_REVIEWS = 'SET_REVIEWS';
export const ADD_REVIEW = 'ADD_REVIEW';
export const UPDATE_REVIEW = 'UPDATE_REVIEW';
export const DELETE_REVIEW = 'DELETE_REVIEW';

/* #endregion */
 
/* #region Booking  */
export const SET_BOOKING = 'SET_BOOKING';
export const SET_BOOKINGS = 'SET_BOOKINGS';
export const ADD_BOOKING = 'ADD_BOOKING';
export const UPDATE_BOOKING = 'UPDATE_BOOKING';
export const DELETE_BOOKING = 'DELETE_BOOKING';

/* #endregion */
 
/* #region CART  */
export const SET_CART = 'SET_CART';
export const SET_CARTS = 'SET_CARTS';
export const ADD_CART = 'ADD_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const DELETE_CART = 'DELETE_CART';

 /* #endregion */