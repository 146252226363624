import { createSlice } from "@reduxjs/toolkit"
import { axiosAPIHook } from "../axiosFunctions"
import { dispatch } from ".."

const initialState = {
  blog: null,
  blogs: [],
}

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setBlogReducer(state, action) {
      state.blog = action.payload
    },
    setBlogsReducer(state, action) {
      state.blogs = action.payload
    },

    addBlogReducer(state, action) {
      state.blogs.unshift(action.payload)
    },

    updateBlogReducer(state, action) {
      state.blogs = state.blogs.map((document) =>
        document._id === action.payload._id ? action.payload : document
      )
    },

    deleteBlogReducer(state, action) {
      state.blogs = state.blogs.filter(
        (document) => document._id !== action.payload
      )
    },
  },
})

const {
  setBlogReducer,
  setBlogsReducer,
  addBlogReducer,
  updateBlogReducer,
  deleteBlogReducer,
} = blogSlice.actions

export const setBlog = (id) => {
  
  axiosAPIHook("get", `/blog/${id}`,{},  (data) => dispatch(setBlogReducer(data)))
}

export const setBlogs = (query) => {
  axiosAPIHook("get", "/blog", { params: query }, (data) =>
    dispatch(setBlogsReducer(data))
  )
}

export const addBlog = (body) =>
  axiosAPIHook("post", "/blog", body, (data) => dispatch(addBlogReducer(data)))

export const updateBlog = (id, body) =>
  axiosAPIHook("patch", `/blog/${id}`, body, (data) =>
    dispatch(updateBlogReducer(data))
  )

export const deleteBlog = (id) =>
  axiosAPIHook("delete", `/blog/${id}`, _, dispatch(deleteBlogReducer(id)))

export default blogSlice.reducer
