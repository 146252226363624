import { Autocomplete, Chip, TextField } from "@mui/material"
import React from "react"
import { reduxFormDataHook } from "./reactCustomHooks"

function RenderChipsInput({ column }) {
  const { required, value, onChange2, label } = reduxFormDataHook(column)

  return (
    <Autocomplete
      multiple
      freeSolo
      fullWidth
      options={[] /* Your autocomplete options here */}
      value={value || []}
      onChange={(e, newValue) => {
        const lastElement = newValue[newValue.length - 1]

        if (lastElement?.toString().trim() || !lastElement) onChange2(newValue)
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            variant="outlined"
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          // variant="filled"
          label={label}
          placeholder={label}
        />
      )}
    />
  )
}

export default RenderChipsInput
