import { dispatch } from ".."
import * as actionType from "../actionTypes"
export default (
  state = {
    feedbackObject: null,
    selectedRow: null,
    stayConnected: null,
    isLoading: false,
    tableNumber: null,
    openTrigger: null,
    pagination: {},
    totalCount: null,
    themeMode: localStorage.getItem("themeMode") || "light",
  },
  action
) => {
  const payload = action.payload
  switch (action.type) {
    case SET_TOTAL_COUNT:
      return { ...state, totalCount: payload }
    case SET_PAGINATION:
      return { ...state, pagination: payload }
    case SET_THEME_MODE:
      return { ...state, themeMode: payload }
    case actionType.SET_OPEN_TRIGGER:
      return { ...state, openTrigger: payload }
    case actionType.SET_TABLE_NUMBER:
      return { ...state, tableNumber: payload }
    case actionType.START_LOADING:
      return { ...state, isLoading: true }
    case actionType.END_LOADING:
      return { ...state, isLoading: false }
    case actionType.SET_SELECTED_ROW:
      return { ...state, selectedRow: payload }

    case actionType.SET_STAY_CONNECTED:
      return { ...state, stayConnected: payload }

    case actionType.SET_FEEDBACK_OBJECT:
      return { ...state, feedbackObject: payload }

    default:
      return state
  }
}

export const setReduxPagination = (data) => {
  dispatch({ type: SET_PAGINATION, payload: data })
}
export const setReduxTotalCount = (data) => {
  dispatch({ type: SET_TOTAL_COUNT, payload: data })
}

const SET_TOTAL_COUNT = "SET_TOTAL_COUNT"
const SET_PAGINATION = "SET_PAGINATION"
const SET_THEME_MODE = "SET_THEME_MODE"
