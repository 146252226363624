import React from "react"
import {CKEditor} from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { useTranslation } from "react-i18next"
import { reduxFormDataHook } from "../reactCustomHooks"
import { TextField, Typography } from "@mui/material"
function MuiCKEditor({column}) {
  const { i18n } = useTranslation()

  const editorConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "alignment",
        "|",
        "link",
        "|",
        "undo",
        "redo",
      ],
    },
    language: i18n.language,
  }

  const { accessorKey, required, value, onChange2, t } =
    reduxFormDataHook(column)
  return (
    
    <div>
      <Typography>{t(accessorKey)} </Typography>
      <CKEditor
        required={required}
        editor={ClassicEditor}
        data={value}
        onChange={(event, editor) => {
          const data = editor.getData()
          onChange2(data)
        }}
        config={editorConfig}
      />
    </div>
  )
}

export default MuiCKEditor
