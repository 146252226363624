import {
  CircularProgress,
  Stack
} from "@mui/material"
import { useSelector } from "react-redux"
import { drawerWidth } from "../../../config"
import { useTranslation } from "react-i18next"

function LoadingOverlay() {
  const { drawerOpen } = useSelector((state) => state.menu)
  const { isLoading } = useSelector((state) => state.misc)
  const { t } = useTranslation()

  if (isLoading)
    return (
      <Stack
        sx={{
          position: "absolute",
          inset: 0,
          zIndex: "10",
          bgcolor: "#AAAAAA50",
          height: "100%",
        }}
        justifyContent="center"
        alignItems="center"
      >
        <div
          style={{
            transition: "all 0.25s",
            marginLeft: drawerOpen ? drawerWidth : 0,
            textAlign: "center"
          }}
        >
          <CircularProgress
            size={100}
          />
          <h4 style={{ marginLeft: "10px", textAlign: "center" }}>
            {t("loading-please-wait")}
           
          </h4>
        </div>
      </Stack>
    )
}

export default LoadingOverlay
