import { Autoplay, FreeMode, Navigation, Thumbs, Zoom } from "swiper/modules"
import { ImgPath } from "../../components/ImagePath"
import { Swiper, SwiperSlide } from "swiper/react"
import { useEffect, useState } from "react"
import { convertToArray } from "../shortCuts"

// import 'swiper/css';
// import 'swiper/css/free-mode';
// import 'swiper/css/navigation';
// import 'swiper/css/thumbs';
// import 'swiper/css/zoom';
import "./styles.css"

function CarouselGallery({ images }) {
  if (!images) return <></>
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const value = convertToArray(images)
  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        // autoplay={{
        //   delay: 4000,
        //   disableOnInteraction: true,
        // }}
        zoom={true}
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs, Autoplay, Zoom]}
        className="imageProductCarouselSwiper"
      >
        {value.map((image, key) => (
          <SwiperSlide key={key}>
            {/* <div className="swiper-zoom-container"> */}
              <ImgPath src={image} />
            {/* </div> */}
          </SwiperSlide>
        ))}
      </Swiper>
     {images.length > 1 && <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        zoom={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        {value.map((image, key) => (
          <SwiperSlide key={key}>
            <ImgPath src={image} />
          </SwiperSlide>
        ))}
      </Swiper>}
    </>
  )
}
export default CarouselGallery
