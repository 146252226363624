
import { createSlice } from "@reduxjs/toolkit"
import { axiosAPI } from "../axiosFunctions"
import { dispatch } from ".."

const initialState = {
  products: [],
}

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProductsReducer(state, action) {
      // THIS LINE HAS BEEN MODIFIER
      // state.products = [...action.payload, ...state.products]
      state.products = action.payload
    },

    addProductReducer(state, action) {
      state.products.unshift(action.payload)
    },

    updateProductReducer(state, action) {
      state.products = state.products.map((document) =>
        document._id === action.payload._id ? action.payload : document
      )
    },

    deleteProductReducer(state, action) {
      state.products = state.products.filter(
        (document) => document._id !== action.payload
      )
    },
  },
})

const {
  setProductsReducer,
  addProductReducer,
  updateProductReducer,
  deleteProductReducer,
} = productSlice.actions

const sendedQueries = []
const allowSend = (query) => {
  const stringQuery = JSON.stringify(query)
  const result = sendedQueries.includes(stringQuery)
  if (!result) sendedQueries.push(stringQuery)
  return !result
}

export const setProducts = (query) => {
  // if (allowSend(query))
    axiosAPI
      .put("/product/", query)
      .then((data) => dispatch(setProductsReducer(data)))
}

export const addProduct = (body) => {
  axiosAPI
    .post("/product/", body)
    .then((data) => dispatch(addProductReducer(data)))
}

export const updateProduct = (id, body) => {
  axiosAPI
    .patch(`/product/${id}`, body)
    .then((data) => dispatch(updateProductReducer(data)))
}

export const deleteProduct = (id) => {
  axiosAPI
    .delete(`/product/${id}`)
    .then((_) => dispatch(deleteProductReducer(id)))
}

export default productSlice.reducer
