import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Stack } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { logoPath } from "../../../../config"
import TypographyTrad from "../../../../utils/TypographyTrad."
import { IsManager, IsSupervisor, IsWaiter } from "../../../../utils/useIsRole"
import Carts from "../dialog/cart/cartDialog/CartDialog"
import OrderDialog from "../dialog/cart/orderDialog/OrderDialog"
import BottomInfoBar from "../../../BottomInfoBar"

const menuItems = [
  { id: "home", label: "Home", href: "#hero" },
  { id: "about", label: "About", href: "#about" },
  { id: "menu", label: "Menu", href: "#menu" },
  { id: "specials", label: "Specials", href: "#specials" },
  { id: "events", label: "Events", href: "#events" },
  { id: "chefs", label: "Chefs", href: "#chefs" },
  { id: "gallery", label: "Gallery", href: "#gallery" },
  { id: "contact", label: "Contact", href: "#contact" },
  { id: "bookTable", label: "Book a Table", href: "#book-a-table" },
]
function Header() {
  const { establishment } = useSelector((state) => state.establishment)
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { t } = useTranslation()
  const location = useLocation()

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen)
  }
  const disabled = IsSupervisor() || IsWaiter()

  const mobileMenuClass = isMobileMenuOpen
    ? "navbar order-last order-lg-0 navbar-mobile"
    : "navbar order-last order-lg-0"

  // Define a function to determine if a link is active
  const isLinkActive = (path) => {
    if (!location.hash) location.hash = "#hero"
    return location.hash === path ? "active" : ""
  }

  return (
    <header
      id="header"
      className="fixed-top d-flex  flex-column
    align-items-center"
    >
      <div className="container-fluid container-xl d-flex align-items-center justify-content-lg-between">
        <a href={disabled ? "" : "/auth/login"} className="custom-hover-10">
          <Stack direction="row" alignItems="center" spacing={2}>
            <img
              src={logoPath}
              alt="Smart MenuQR logo"
              width={50}
              style={{ marginRight: 10 }}
            />
            <h1 style={{ color: "#FFFFFF" }} className="logo me-auto me-lg-0">
              {establishment.name}
            </h1>
          </Stack>
        </a>
        <nav id="navbar" className={mobileMenuClass}>
          <ul>
            <ArrowBackIcon
              sx={{ position: "absolute", top: 10, right: 10, color: "black" }}
            />

            {menuItems.map((menuItem) => (
              <li
                onClick={() => isMobileMenuOpen && toggleMobileMenu()}
                key={menuItem.id}
              >
                <a
                  style={{ fontSize: isMobileMenuOpen ? 20 : 16 }}
                  className={`custom-hover-10 nav-link scrollto ${isLinkActive(
                    menuItem.href
                  )}`}
                  href={menuItem.href}
                >
                  {t(`header.${menuItem.id}`)}
                </a>
              </li>
            ))}
            {isMobileMenuOpen && (
              <Stack
                sx={{ borderTop: "solid gray 3px" }}
                justifyContent="space-between"
                alignItems="center"
              >
                <img
                  src={logoPath}
                  alt="Smart MenuQR logo"
                  width={"80%"}
                  style={{ margin: "auto", marginTop: 16, maxWidth: 300 }}
                />
              </Stack>
            )}
          </ul>
        </nav>
        {/* <a
          href="#book-a-table"
          className="custom-hover-10 book-a-table-btn scrollto d-none d-lg-flex"
        >
          {t("header.bookTable")}
        </a> */}
        <div
          style={{ marginLeft: "auto" }}
          className="nav__right d-flex align-items-center gap-4"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Carts />
            <OrderDialog />
            <i
              className="bi bi-list mobile-nav-toggle"
              onClick={toggleMobileMenu}
            />
          </Stack>
          {/* <OldOrderDialog /> */}
        </div>
      </div>
      {/* <ManagerModeBar sx={{bgcolor : "transparent",}} /> */}
      {/* {IsManager() && (
        <TypographyTrad variant="h1">manager-mode</TypographyTrad>
      )} */}
    </header>
  )
}

export default Header
