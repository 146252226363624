import React, { useState } from "react"
import { baseURL } from "../store/axiosFunctions"
import { Avatar, CardMedia, IconButton } from "@mui/material"
import { base64OrPath } from "utils/convertToBase64"
import Lightbox from "yet-another-react-lightbox"
import "yet-another-react-lightbox/styles.css"
import OpenInFullIcon from "@mui/icons-material/OpenInFull"
import { arrayFirstElement } from "../utils/shortCuts"

const CardMediaPath = (props) => {

  var value = base64OrPath(arrayFirstElement(props.image))

  return <CardMedia alt="Image" {...props} src={value} image={value} />
}
export const ImgPath = (props) => {
  var { src, allowLightBox, ...imageProps } = props

  var value = base64OrPath(arrayFirstElement(src))

  const [open, setOpen] = useState(false)

  return (
    <span style={{ position: "relative" }}>
      {allowLightBox && (
        <>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setOpen(true)}
            style={{ zIndex: 100, position: "absolute", top: 10, right: 20 }}
          >
            <OpenInFullIcon />
          </IconButton>
          <Lightbox
            render={{
              iconPrev: () => <></>,
              iconNext: () => <></>,
            }}
            open={open}
            close={() => setOpen(false)}
            slides={[{src}]}
          />
        </>
      )}
      <img
        style={{ cursor: allowLightBox && "pointer" }}
        {...imageProps}
        onClick={() => allowLightBox && setOpen(true)}
        src={value}
      />
    </span>
  )
}
export const AvatarPath = (props) => {
  var value = base64OrPath(arrayFirstElement(props.src))
  return <Avatar {...props} src={value} />
}

export default CardMediaPath
