import LoginIcon from "@mui/icons-material/Login"
import MenuIcon from "@mui/icons-material/Menu"
import { IconButton, Stack, SwipeableDrawer, Typography } from "@mui/material"
import Aos from "aos"
import "aos/dist/aos.css"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next" // Import useTranslation
import { Link, useNavigate } from "react-router-dom"
import LanguageSwitcher from "../../components/LanguageSwitcher"
import { logoPath } from "../../config"
import useIsMobile from "../../utils/useIsMobile"
import uniquePageWebsite from "../uniquePageWebsite"
function Home() {
  const { t } = useTranslation()
  const websiteName = t("websiteName")
  const email = "smart.menuqr@gmail.com"
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
  const navigate = useNavigate()
  function toggleMobileMenu() {
    setMobileMenuOpen(!isMobileMenuOpen)
  }
  // if (uniquePageWebsite()) navigate(`establishment/${uniquePageWebsite()}`)
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    })
  }, [])

  return (
    <>
      <div className="hero_area">
        <header className="header_section">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg custom_nav-container">
              <a className="navbar-brand custom-hover-05">
                <img
                  src={logoPath}
                  alt="Logo"
                  width={50}
                  style={{ marginRight: 10 }}
                />
                <span>{websiteName}</span>
              </a>
              {useIsMobile(992) && (
                <MenuIcon
                  sx={{ color: "#FFFFFF", fontSize: 40 }}
                  onClick={toggleMobileMenu}
                />
              )}

              <SwipeableDrawer
                anchor={"left"}
                open={isMobileMenuOpen ? true : false}
                onClose={toggleMobileMenu}
                onOpen={toggleMobileMenu}
                sx={{ textAlign: "center" }}
              >
                <Stack
                  sx={{ width: 200, fontSize: 30, "& *": { color: "black}" } }}
                  alignItems="center"
                  spacing={2}
                >
                  <ul className="navbar-nav">
                    <li className="nav-item custom-hover-05">
                      <a className="nav-link" href="#home">
                        {t("home")} <span className="sr-only">(actuel)</span>
                      </a>
                    </li>
                    <li className="nav-item custom-hover-05">
                      <a className="nav-link" href="#about">
                        {t("about")}
                      </a>
                    </li>
                    <li className="nav-item custom-hover-05">
                      <a className="nav-link" href="#service">
                        {t("services")}
                      </a>
                    </li>
                    <li className="nav-item custom-hover-05">
                      <a className="nav-link" href="#contact">
                        {t("contact")}
                      </a>
                    </li>
                  </ul>
                  <LanguageSwitcher />
                  <hr />
                  <Link to="/auth/login">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Typography
                        variant="h4"
                        color={"primary"}
                        component={"span"}
                      >
                        {t("login")}
                      </Typography>
                      <IconButton>
                        <LoginIcon />
                      </IconButton>
                    </Stack>
                  </Link>
                </Stack>
              </SwipeableDrawer>

              {!isMobileMenuOpen && (
                <div
                  className={`collapse navbar-collapse`}
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav">
                    <li className="nav-item custom-hover-05">
                      <a className="nav-link" href="#home">
                        {t("home")} <span className="sr-only">(actuel)</span>
                      </a>
                    </li>
                    <li className="nav-item custom-hover-05">
                      <a className="nav-link" href="#about">
                        {t("about")}
                      </a>
                    </li>
                    <li className="nav-item custom-hover-05">
                      <a className="nav-link" href="#service">
                        {t("services")}
                      </a>
                    </li>
                    <li className="nav-item custom-hover-05">
                      <a className="nav-link" href="#contact">
                        {t("contact")}
                      </a>
                    </li>
                  </ul>
                  <LanguageSwitcher />

                  <div className="custom-hover-10 quote_btn-container">
                    <Link
                      to="/auth/login"
                      className="d-flex align-items-center"
                    >
                      <Typography variant="h4">{t("login")}</Typography>
                      <IconButton>
                        <LoginIcon sx={{ color: "#FFFFFF" }} />
                      </IconButton>
                    </Link>
                  </div>
                </div>
              )}
            </nav>
          </div>
        </header>
        <section data-aos="fade-up" id="home" className="slider_section ">
          <div className="container ">
            <div className="row ">
              <div className="col-md-6">
                <div className="img-box custom-hover-05">
                  <img src="home/images/slider-img.png" alt="Hero" />
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="detail-box ">
                  <h1 className="custom-hover-05">
                    {t("digitalMenuManagement")}
                  </h1>
                  <p className="custom-hover-05">
                    {t("digitalMenuManagementDescription")}
                  </p>
                  <div className="d-flex flex-sm-row flex-column justify-content-around">
                    <div className="btn-box custom-hover-05">
                      <a href="#contact" className="btn1">
                        {t("contactUs")}
                      </a>
                    </div>
                    <div className="btn-box custom-hover-05">
                      <a href="#contact" className="btn1">
                        {t("demo")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            id="customCarousel1"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                
              </div>
            </div>
          </div> */}
        </section>
        {/* fin de la section de la diapositive */}
      </div>
      {/* section des services */}
      <section
        data-aos="fade-up"
        id="service"
        className="service_section layout_padding"
      >
        <div className="container">
          <div className="heading_container">
            <h2>{t("discoverOurSolutions")}</h2>
            <p>{t("exploreHowSmartMenuQrCanTransform")}</p>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3 custom-hover-10">
              <div className="box">
                <div className="img-box">
                  <img src="home/images/s1.png" alt={t("qrMenuManagement")} />
                </div>
                <div className="detail-box">
                  <h5>{t("qrMenuManagement")}</h5>
                  <p>{t("simplifyMenuUpdatesAndEnhance")}</p>
                  <a href="#">
                    {/* <span>En savoir plus</span> */}
                    {/* <i className="fa fa-long-arrow-right" aria-hidden="true" /> */}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 custom-hover-10">
              <div className="box">
                <div className="img-box">
                  <img
                    src="home/images/s2.png"
                    alt={t("contactlessOrdering")}
                  />
                </div>
                <div className="detail-box">
                  <h5>{t("contactlessOrdering")}</h5>
                  <p>
                    {t("streamlineOrdersAndPaymentsReducePhysicalContacts")}
                  </p>
                  <a href="#">
                    {/* <span>En savoir plus</span> */}
                    {/* <i className="fa fa-long-arrow-right" aria-hidden="true" /> */}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 custom-hover-10">
              <div className="box">
                <div className="img-box">
                  <img
                    src="home/images/s3.png"
                    alt={t("multilingualSupport")}
                  />
                </div>
                <div className="detail-box">
                  <h5>{t("multilingualSupport")}</h5>
                  <p>
                    {t(
                      "attractDiverseCustomerBaseWithMenusInMultipleLanguages"
                    )}
                  </p>
                  <a href="#">
                    {/* <span>En savoir plus</span> */}
                    {/* <i className="fa fa-long-arrow-right" aria-hidden="true" /> */}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 custom-hover-10">
              <div className="box">
                <div className="img-box">
                  <img src="home/images/s4.png" alt={t("realTimeAnalytics")} />
                </div>
                <div className="detail-box">
                  <h5>{t("realTimeAnalytics")}</h5>
                  <p>
                    {t("getInsightsIntoCustomerPreferencesAndOrderingHabits")}
                  </p>
                  <a href="#">
                    {/* <span>En savoir plus</span> */}
                    {/* <i className="fa fa-long-arrow-right" aria-hidden="true" /> */}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section À propos */}
      <section
        data-aos="fade-up"
        id="about"
        className="about_section layout_padding"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="detail-box ">
                <div className="heading_container">
                  <h2 className="custom-hover-05">{t("whoWeAre")}</h2>
                </div>
                <p className="custom-hover-05">
                  {t("smartMenuQrMissionToRevolutionize")}
                </p>
                {/* <a href="#">En savoir plus</a> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-box custom-hover-10">
                <img src="home/images/about-img.png" alt={t("aboutUs")} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div data-aos="fade-up" className="">
        {/* section d'informations */}
        <section className="info_section">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-6 ">
                <div className="info_detail">
                  <h4>{websiteName}</h4>
                  <p>{t("digitalMenuManagementDescription")}</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-0 ml-auto">
                <div className="info_contact">
                  <h4>{t("address")}</h4>
                  <div className="contact_link_box">
                    <a className="custom-hover-05" href="">
                      <i className="fa fa-phone" aria-hidden="true" />
                      <span>{t("callUs")} +216 92 640 172</span>
                    </a>
                    <a className="custom-hover-05" href="">
                      <i className="fa fa-envelope" aria-hidden="true" />
                      <span>{email}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer id="contact" className="footer_section">
          <div className="container">
            <p>
              © <span id="displayYear" /> {dayjs().format("YYYY")} {websiteName}
            </p>
          </div>
        </footer>
      </div>
    </>
  )
}

export default Home
