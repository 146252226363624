import { feedbackError } from "./misc"
import * as actionType from "../actionTypes"
import { dispatch } from "../"


export const setCart = async (cart) => {
  dispatch({ type: actionType.SET_CART, payload: cart })
}

export const createCart = async (newCart) => {
  var productInCartId = "id" + Math.random().toString(16).slice(2)

  try {
    await dispatch({
      type: actionType.ADD_CART,
      payload: { ...newCart, productInCartId },
    })
  } catch (error) {
    console.log(error)
    feedbackError(error?.response?.data || "Erreur serveur.")
  }
}

export const updateCart = async (updatedCart) => {
  try {
    await dispatch({ type: actionType.UPDATE_CART, payload: updatedCart })
    // feedbackSuccess(updateFeedback)
  } catch (error) {
    feedbackError(error?.response?.data || "Erreur serveur.")
  }
}

export const deleteCart = async (id) => {
  try {
    await dispatch({ type: actionType.DELETE_CART, payload: id })
  } catch (error) {
    feedbackError(error?.response?.data || "Erreur serveur.")
  }
}
