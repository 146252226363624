import { useTranslation } from "react-i18next"
import { setFormDataMap } from "../store/reducers/misc2"
import { useSelector } from "react-redux"
import { isArray } from "./checkType"
import { tradSentence } from "./tradSentence"

export const useHandleChange = (formData, setFormData) => {
  return (event) => {
    var value = event?.target?.value !== undefined ? event.target.value : event
    if (isFloat(value)) value = parseFloat(value)
    // else if (!isNaN(value)) value = parseInt(value)

    var result
    if (typeof formData === "object")
      result = {
        ...formData,
        [event.target.name]: value,
      }
    // if (Array.isArray(formData)) result = [formData]
    else result = value

    setFormData && setFormData(result)
    setFormDataMap(result)
  }
}

export const useProcessInputData = ({ setFormData, formData, column }) => {
  const { accessorKey, required, options, disabled, multiple } = column
  //  const handleChange =  useHandleChange(formData, setFormData)
  const onChange = (e, val) => {
    if (val) {
      e.target.name = accessorKey
      e.target.value = val
    }
    setFormData(e)
  }

  var value = typeof formData === "object" ? formData?.[accessorKey] : formData
  if (value === undefined) value = ""
  const { t } = useTranslation()
  return {
    t,
    value,
    onChange,
    accessorKey,
    required,
    options,
    disabled,
    multiple,
  }
}

export const reduxFormDataHook = (column) => {
  const { formDataMap } = useSelector((state) => state.misc2)
  const handleChange = useHandleChange(formDataMap)
  const { accessorKey, customOnChange, forcedValue } = column

  const onChange2 = (value) => {
    if (!isArray(value) && isFloat(value)) value = parseFloat(value)

    if (customOnChange) return customOnChange(value)
    setFormDataMap({ ...formDataMap, [accessorKey]: value })
  }

  const onChange = (e, val) => {
    const event = {
      target: {
        name: "",
        value: "",
      },
    }
    if (val !== undefined) {
      event.target.name = accessorKey
      event.target.value = val
    }
    handleChange(val !== undefined ? event : e)
  }
  const { t } = useTranslation()
  var value = forcedValue || formDataMap?.[accessorKey] || ""

  const label = tradSentence(accessorKey)

  return { ...column, label, onChange, onChange2, value, t }
}

function isFloat(str) {
  if (str) {
    var floatNumber = parseFloat(str)

    return !isNaN(floatNumber) && str.toString().trim() !== ""
  }
}
