import { IconButton, Stack, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { isProduction } from "../store/axiosFunctions"
import { DashboardIcon, RemoveRedEyeIcon } from "../utils/MuiIcons"
import TypographyTrad from "../utils/TypographyTrad."
import { detectEndPage } from "../utils/scroll"
import { IsManager } from "../utils/useIsRole"

function BottomInfoBar({ sx }) {
  const isEstablishment =
    !location.pathname.includes("dashboard") &&
    !location.pathname.includes("login")

  return (
    <Stack
      direction="row"
      justifyContent={"space-around"}
      sx={{
        // p: 1,
        position: detectEndPage() ? "static" : "sticky",
        bottom: 0,
        // top: 0,
        width: "100%",
        color: "white",
        fontSize: 20,
        // height: 40,
        zIndex: 99999999,
        bgcolor: "#1d2327",
        ...sx,
      }}
    >
      {IsManager() && isEstablishment && <EstablishmentInfoBar />}
      {!isProduction && (
        <TypographyTrad variant="h3" sx={{ textAlign: "center" }}>
          development-platform
        </TypographyTrad>
      )}
    </Stack>
  )
}
function EstablishmentInfoBar() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const seeAsClient = () => {
    dispatch({ type: "LOGOUT" })
  }

  return (
    <Stack direction="row" spacing={2}>
      <TypographyTrad variant="h4" sx={{ textAlign: "center" }}>
        manager-mode|: |
      </TypographyTrad>

      <Tooltip
        PopperProps={{ disablePortal: true }}
        title={t("returnToDashboard")}
      >
        <a href="/auth/login">
          <IconButton>
            <DashboardIcon color="primary" />
          </IconButton>
        </a>
      </Tooltip>

      <Tooltip PopperProps={{ disablePortal: true }} title={t("see-as-client")}>
        <IconButton onClick={seeAsClient}>
          <RemoveRedEyeIcon color="primary" />
        </IconButton>
      </Tooltip>
      {/* <CustomButton to="/auth/login" style={{ fontSize: 16, padding: 16 }}>
        returnToDashboard
      </CustomButton>
      <CustomButton
        onClick={seeAsClient}
        style={{ fontSize: 16, padding: 16 }}
      >
        see-as-client
      </CustomButton> */}
    </Stack>
  )
}

export default BottomInfoBar
