import { useTranslation } from "react-i18next"
import {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

import { Stack } from "@mui/material"
import { useSelector } from "react-redux"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import EditCMS from "../../../../components/EditCMS"
function EventSection() {
  const { t } = useTranslation()
  return (
    <section id="events" className="events">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>{t("events")}</h2>
          <p>{t("organizeEvents")}</p>
        </div>
        <div
          className="events-slider swiper-container"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, Autoplay, A11y]}
            autoplay={{
              delay: 3000,
              disableOnInteraction: true,
            }}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
          >
            {Array.from({ length: 3 }, (_, i) => {
              i = i + 1
              return (
                <SwiperSlide key={i} className="swiper-slide">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                    className="event-item"
                  >
                    <div className="col-lg-6">
                      <EditCMS
                        imageProps={{ width: "100%", className: "img-fluid" }}
                        field={"eventImage" + i}
                        type="image"
                      />
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0 content">
                      <h3 style={{ fontSize: 40 }}>
                        <EditCMS field={"eventTitle" + i} />
                      </h3>
                      <EditCMS field={"eventText" + i} />
                    </div>
                  </Stack>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default EventSection
