import { useSelector } from "react-redux"
import { ImgPath } from "../../../components/ImagePath"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

export function Categories() {
  const { t } = useTranslation()
  const { categories } = useSelector((state) => state.category)

  return (
    <div className="sec-banner bg0 p-t-80 p-b-50">
      <div className="container">
        <div className="row">
          {categories.map((category, key) => (
            <div key={key} className="col-md-6 col-xl-4 p-b-30 m-lr-auto">
              <div className="block1 wrap-pic-w">
                <ImgPath src={category.image} alt={category.name} />
                <Link
                  to="./shop"
                  className="block1-txt ab-t-l s-full flex-col-l-sb p-lr-38 p-tb-34 trans-03 respon3"
                >
                  <div className="block1-txt-child1 flex-col-l">
                    <span className="block1-name ltext-102 trans-04 p-b-8">
                      {category.name}
                    </span>
                    {/* <span className="block1-info stext-102 trans-04">
                      Spring 2018
                    </span> */}
                  </div>
                  <div className="block1-txt-child2 p-b-4 trans-05">
                    <div className="block1-link stext-101 cl0 trans-09">
                      {t("shop-now")}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
