import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useLocation, useParams } from "react-router"
import ProductDetails from "./dialog/ProductDetails"
import "./establishmentStyle.css"
import ContactSection from "./form/ContactSection"
import ReservationSection from "./form/ReservationSection"
import About from "./sections/AboutSection"
import ChefSection from "./sections/ChefSection"
import EventSection from "./sections/EventSection"
import FooterSection from "./sections/FooterSection"
import GallerySection from "./sections/GallerySection"
import Header from "./sections/Header"
import Hero from "./sections/Hero"
import MenuSection from "./sections/MenuSection"
import SpecialSection from "./sections/SpecialSection"
import TopBar from "./sections/TopBar"
import MessageIcon from "@mui/icons-material/Message"
import { Fab, Typography } from "@mui/material"
import AOS from "aos"
import "aos/dist/aos.css"
import { useAutoClosePopup } from "../../../components/AutoClosePopup"
import {
  ConfirmProvider,
  darkTheme,
} from "../../../components/ConfirmDialogPromise"
import { initOrder } from "../../../store/actions/order"
import { setProducts } from "../../../store/reducers/product"
import { setCategories } from "../../../store/reducers/category"
import { IsClient } from "../../../utils/useIsRole"
import { reduxClientOrder } from "../../../utils/socket/modelSockets/restaurant"
import MuiDrawer from "../../../utils/MuiDrawer"

function RestaurantModel() {
  const { i18n } = useTranslation()

  const location = useLocation()
  const autoClosePopup = useAutoClosePopup()

  const queryParams = new URLSearchParams(location.search)
  const tableNumber = queryParams.get("tableNumber")
  const { establishment } = useSelector((state) => state.establishment)
  const { name, numberOfTables } = establishment

  const [block, setBlock] = useState(false)

  useEffect(() => {
    let error
    // if (IsClient() && !tableNumber) error = "Table is missing"
    if (tableNumber && tableNumber > numberOfTables) error = "This table does not exist"
    if (error) {
      autoClosePopup({
        content: <Typography variant="h4">{error}</Typography>,
      })
      setBlock(true)
    }
  }, [establishment])

  useEffect(() => {
    initOrder()

    setProducts()
    setCategories()

    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    })
  }, [])

  const isArabic = i18n.language === "ar"
  if (block) return <></>
  if (!establishment) return <div id="preloader" />

  return (
    <ConfirmProvider
      theme={darkTheme}
      defaultOptions={{
        confirmationButtonProps: { autoFocus: true },
      }}
    >
      <div className={"page" + (isArabic ? "arabic-text" : "")}>
        <Helmet title={name}>
          <link
            href="/models/restaurant/assets/vendor/animate.css/animate.min.css"
            rel="stylesheet"
          />
          <link
            href="/models/restaurant/assets/vendor/aos/aos.css"
            rel="stylesheet"
          />
          <link
            href="/models/restaurant/assets/vendor/bootstrap/css/bootstrap.min.css"
            rel="stylesheet"
          />
          <link
            href="/models/restaurant/assets/vendor/bootstrap-icons/bootstrap-icons.css"
            rel="stylesheet"
          />
          <link
            href="/models/restaurant/assets/vendor/boxicons/css/boxicons.min.css"
            rel="stylesheet"
          />
          <link
            href="/models/restaurant/assets/vendor/glightbox/css/glightbox.min.css"
            rel="stylesheet"
          />
          <link
            href="/models/restaurant/assets/vendor/swiper/swiper-bundle.min.css"
            rel="stylesheet"
          />
          <link
            href="/models/restaurant/assets/css/style.css"
            rel="stylesheet"
          />
        </Helmet>
        {/* <Fab
          sx={{ position: "fixed", bottom: 15, right: 15 }}
          color="primary"
          aria-label="add"
        >
          <MessageIcon />
        </Fab> */}
        <TopBar />
        <Header />
        <ProductDetails />
        <Hero />
        <main id="main">
          <About />
          {/* <WhyUs /> */}
          <MenuSection />
          <SpecialSection />
          <EventSection />
          <ReservationSection />
          {/* <TestimonialsSection /> */}
          <GallerySection />
          <ChefSection />
          <ContactSection />
        </main>
        <FooterSection />

        <a
          href="#"
          className="back-to-top d-flex align-items-center justify-content-center"
        >
          <i className="bi bi-arrow-up-short" />
        </a>
      </div>
    </ConfirmProvider>
  )
}

export default RestaurantModel
