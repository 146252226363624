import * as actionType from "../actionTypes"
import * as API from "../axiosFunctions"
import { dispatch } from "../"

export const getBooking = async (id) => {
  const data = await API.getBookingById(id)
  dispatch({ type: actionType.SET_BOOKING, payload: data })
  return data
}

export const fetchBookingQuery = async (query) => {
  const data = await API.fetchBookingQuery(query)
  dispatch({ type: actionType.SET_BOOKINGS, payload: data })
}

export const createBooking = async (newBooking) => {
  const data = await API.createBooking(newBooking)
   dispatch({ type: actionType.ADD_BOOKING, payload: data })
}

export const updateBooking = async (id, updatedBooking) => {
  const data = await API.updateBooking(id, updatedBooking)
  if (data)
     dispatch({ type: actionType.UPDATE_BOOKING, payload: data })
}

export const deleteBooking = async (id) => {
  await API.deleteBooking(id)
   dispatch({ type: actionType.DELETE_BOOKING, payload: id })
}
