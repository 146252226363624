import { feedbackError, feedbackSuccess } from "./misc"
import * as actionType from "../actionTypes"
import * as API from "../axiosFunctions"
import { dispatch } from "../"

const addFeedback = "Notification ajouté"
const updateFeedback = "Notification modifié"
const deleteFeedback = "Notification supprimé"

/* #region Post */
export const fetchNotificationQuery = async (query) => {
  const data = await API.fetchNotificationQuery(query)
  dispatch({ type: actionType.SET_NOTIFICATIONS, payload: data })
}

export const createNotification = async (newNotification) => {
  try {
    const data = await API.createNotification(newNotification)
    await dispatch({ type: actionType.ADD_NOTIFICATION, payload: data })
    // feedbackSuccess(addFeedback)
  } catch (error) {
    feedbackError(error?.response?.data || "Erreur serveur.")
  }
}

/* #endregion */
/* #region Patch */
export const updateNotification = async (id, updatedNotification) => {
  try {
    const data = await API.updateNotification(id, updatedNotification)
    await dispatch({ type: actionType.UPDATE_NOTIFICATION, payload: data })
    // feedbackSuccess(updateFeedback)
  } catch (error) {
    feedbackError(error?.response?.data || "Erreur serveur.")
  }
}

/* #endregion */
/* #region Delete */
export const deleteNotification = async (id) => {
  try {
    await API.deleteNotification(id)
    await dispatch({ type: actionType.DELETE_NOTIFICATION, payload: id })
    // feedbackSuccess(deleteFeedback)
  } catch (error) {
    feedbackError(error?.response?.data || "Erreur serveur.")
  }
}
export const deleteAllNotification = async () => {
  try {
    await API.deleteAllNotification()
    await dispatch({ type: actionType.DELETE_ALL_NOTIFICATION })
    // feedbackSuccess(deleteFeedback)
  } catch (error) {
    feedbackError(error?.response?.data || "Erreur serveur.")
  }
}
/* #endregion */
