import React, { useState } from "react"
import { useTranslation } from "react-i18next" // Import the translation hook
import { createBooking } from "../../../../store/actions/booking"
import { useSelector } from "react-redux"
import { TextField } from "@mui/material"
import MuiFormMap from "../../../../utils/MuiFormMap"
import { phonePattern } from "../../../../utils/patterns"
const currentDate = new Date().toISOString().slice(0, 16)

function ReservationSection() {
  const { establishment } = useSelector((state) => state.establishment)
  const { t } = useTranslation() 
  const [registrationStatus, setRegistrationStatus] = useState(null)

  const onSubmit = (data) => {
    createBooking(data)
    setRegistrationStatus(true)
  }
  const columns = [
    {
      accessorKey: "firstName",
      required: true,
    },
    {
      accessorKey: "lastName",
      required: true,
    },
    // {
    //   accessorKey: "email",
    //   placeholder: t("bookTable.email"),
    //   type: "email",
    //   required: true,
    //   error: formErrors.email,
    // },
    {
      accessorKey: "phone",
      type: "tel",
      pattern: phonePattern,
      
      required: true,
    },
    {
      accessorKey: "date",
      type: "dateTimePicker",
      required: true,
      disablePast: true,
    },
    {
      accessorKey: "numberOfGuests",
      type: "number",
      required: true,
    },
    {
      accessorKey: "reservationPlacePreferences",
      type: "select",
      options: establishment.reservationPlacePreferences,
    },
    {
      accessorKey: "reservationNotes",
      multiline: true,
    },
  ]

  return (
    <section id="book-a-table" className="book-a-table">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>{t("bookTable.reservation")}</h2> {/* Translate the title */}
          <p>{t("bookTable.bookTable")}</p> {/* Translate the description */}
        </div>
        <MuiFormMap
          theme="dark"
          sm={4}
          columns={columns}
          onSubmit={onSubmit}
        />
        <div className="text-center">
          {registrationStatus === "success" ? (
            <div className="alert alert-success">
              {t("bookTable.registrationSuccessMessage")}
            </div>
          ) : registrationStatus === "error" ? (
            <div className="alert alert-danger">
              {t("bookTable.registrationErrorMessage")}
            </div>
          ) : null}
          {/* <button disabled={registrationStatus === "success"} type="submit">
            {t("bookTable.bookTableButton")}
          </button>{" "} */}
          {/* Translate the button text */}
        </div>
      </div>
    </section>
  )
}

export default ReservationSection
