import PropTypes from "prop-types"
import { useMemo } from "react"

// material-ui
import { CssBaseline, StyledEngineProvider } from "@mui/material"
import { createTheme, ThemeProvider } from "@mui/material/styles"

// project import
import Palette from "./palette"
import Typography from "./typography"
import CustomShadows from "./shadows"
import componentsOverride from "./overrides"
import { useSelector } from "react-redux"

import {
  setFetchMethod,
  enable as enableDarkMode,
  disable as disableDarkMode,
  isEnabled as isDarkReaderEnabled,
} from "darkreader"

setFetchMethod(window.fetch)

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

export default function ThemeCustomization({ children, isDashboard }) {
  const { themeMode } = useSelector((state) => state.misc)

  useMemo(() => {
    if (themeMode === "dark") {
      enableDarkMode()
    } else disableDarkMode()
  }, [themeMode])

  const theme = Palette("light", "default")

  const themeTypography = Typography(`'Public Sans', sans-serif`)
  const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme])

  const themeOptions = useMemo(
    () => ({
      breakpoints: {
        // values: {
        //   xs: 0,
        //   sm: 768,
        //   md: 1024,
        //   lg: 1266,
        //   xl: 1536,
        // },
      },
      direction: "ltr",
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
      palette: theme.palette,
      customShadows: themeCustomShadows,
      typography: themeTypography,
    }),
    [theme, themeTypography, themeCustomShadows]
  )

  const themes = createTheme(themeOptions)
  themes.components = componentsOverride(themes)

  if (!isDashboard)
    return <ThemeProvider theme={themes}>{children}</ThemeProvider>

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

ThemeCustomization.propTypes = {
  children: PropTypes.node,
}
