import { Stack, Tooltip } from "@mui/material"
import QRCode from "qrcode"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import CustomButton from "../../../components/Buttons/CustomButton"
import { establishmentPath } from "../../../config"
import { hoverScale } from "../../../functions/myFunctions"
import { setFormDataMap } from "../../../store/reducers/misc2"
import { MuiAutocomplete } from "../../../utils/MuiFormComponents/MuiAutocomplete"
import { downloadAllArchive } from "../../../utils/download"
import { establishmentSiteModel } from "../../../utils/siteModelUtilities"

function QrCodePage() {
  const { establishment } = useSelector((state) => state.establishment)
  const { name, numberOfTables, _id: establishmentId } = establishment
  const [qrCodes, setQrCodes] = useState([])
  const [printWidth, setPrintWidth] = useState(150)
  const { t } = useTranslation()
  const [loginQrCodeData, setLoginQrCodeData] = useState(null)
  const [homeQrCodeData, setHomeQrCodeData] = useState(null)

  useEffect(() => {
    ;(async () => {
      setLoginQrCodeData(await generateQR(`${window.location.origin}/login`))
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      setHomeQrCodeData(
        await generateQR(
          `${window.location.origin}/establishment/${establishment._id}`
        )
      )
    })()
  }, [])

  useEffect(() => {
    generateQRCodes(numberOfTables)
  }, [numberOfTables])

  const generateQR = async (text) => {
    try {
      return await QRCode.toDataURL(text)
    } catch (err) {
      console.error(err)
      return null
    }
  }

  const generateQRCodes = async (numberOfTables) => {
    const qrCodes = []

    for (let tableNumber = 1; tableNumber <= numberOfTables; tableNumber++) {
      const url = `${window.location.origin}${establishmentPath}${establishmentId}?tableNumber=${tableNumber}`
      const qrCodeData = await generateQR(url)
      qrCodes.push({ tableNumber, data: qrCodeData, url })
    }
    setQrCodes(qrCodes)
  }

  const handleQRCodeClick = (qrCodeData, tableNumber) => {
    downloadBase64File(qrCodeData, tableNumber)
    // Implement the printing logic here
    return
    const printWindow = window.open("", "_blank")
    printWindow.document.open()
    printWindow.document.write(`
      <html>
        <head>
          <title>Print QR Code Table ${qrCodeData.tableNumber}</title>
        </head>
        <body>
        <div
           style="display : flex; flex-direction : column ; text-align : center;width : ${printWidth}px"
          >
          <img src="${qrCodeData.data}" alt="QR Code for Table ${qrCodeData.tableNumber}" />
          <h4 >QR Code for Table ${qrCodeData.tableNumber}</h4>
          </div>

        </body>
      </html>
    `)
    printWindow.document.close()
    printWindow.print()
    // printWindow.close();
  }

  const { formDataMap } = useSelector((state) => state.misc2)
  const { selectedQRCodes = [] } = formDataMap
  if (establishmentSiteModel() === "clothing")
    return (
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Tooltip title={t("home")}>
          <Stack
            sx={hoverScale({ scale: 10, border: "1px solid gray" })}
            alignItems="center"
            onClick={
              () => downloadBase64File(homeQrCodeData, `${t("home")}`)
            }
            style={{ cursor: "pointer" }}
          >
            <img
              src={homeQrCodeData}
              alt={`${t("home")}`}
            />
            <span>{t("home")}</span>
          </Stack>
        </Tooltip>
      </Stack>
    )

  return (
    <>
      {/* <Typography sx={{ mb: 2 }}>
        {t("set-print-width-then-left-click-on-the-qr-code-to-print")}
      </Typography> */}
      <Stack direction="row" justifyContent="center" alignItems="center">
        <MuiAutocomplete
          column={{
            multiple: true,
            accessorKey: "selectedQRCodes",
            options: generateArrayFrom1ToX(numberOfTables),
          }}
        />
        <CustomButton
          bgcolor="green"
          helperText="you-must-select-at-least-one-table"
          disabled={!selectedQRCodes.length}
          onClick={() => downloadAllArchive(name + " QR codes")}
        >
          download
        </CustomButton>
        <CustomButton
          onClick={() => {
            setFormDataMap({
              selectedQRCodes: generateArrayFrom1ToX(numberOfTables),
            })
            setTimeout(() => {
              downloadAllArchive(name + " QR codes")
              setFormDataMap({ selectedQRCodes: [] })
            }, 1000)
          }}
        >
          download-all
        </CustomButton>
      </Stack>
      {/* <TextField
        label={t("print-width")}
        type="number"
        fullWidth
        value={printWidth}
        inputProps={{
          min: 100,
          max: 300,
        }}
        onChange={(e) => {
          const value = e.target.value

          setPrintWidth(value)
        }}
      /> */}
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Tooltip title={t("login")}>
          <Stack
            sx={hoverScale({ scale: 10, border: "1px solid gray" })}
            alignItems="center"
            onClick={
              () => downloadBase64File(loginQrCodeData, `${t("login")}`)
              // handleQRCodeClick(qrCodeData, qrCodeData.tableNumber)
            }
            style={{ cursor: "pointer" }}
          >
            <img
              // className={
              //   selectedQRCodes.includes(qrCodeData.tableNumber) ? "dl" : ""
              // }
              src={loginQrCodeData}
              alt={`${t("login")}`}
            />
            <span>{t("login")}</span>
          </Stack>
        </Tooltip>
      </Stack>
      <Stack
        justifyContent="center"
        direction="row"
        alignItems="center"
        flexWrap={"wrap"}
      >
        {qrCodes.map((qrCodeData, index) => (
          <Tooltip key={index} title={qrCodeData.url}>
            <Stack
              sx={hoverScale({ scale: 10, border: "1px solid gray" })}
              alignItems="center"
              onClick={
                () =>
                  downloadBase64File(
                    qrCodeData,
                    `${t("qr-code-for-table")} ${qrCodeData.tableNumber}`
                  )
                // handleQRCodeClick(qrCodeData, qrCodeData.tableNumber)
              }
              style={{ cursor: "pointer" }}
            >
              <img
                className={
                  selectedQRCodes?.includes(qrCodeData.tableNumber) ? "dl" : ""
                }
                src={qrCodeData.data}
                alt={`${t("qr-code-for-table")} ${qrCodeData.tableNumber}`}
              />
              <span>
                {t("table")} {qrCodeData.tableNumber}{" "}
              </span>
            </Stack>
          </Tooltip>
        ))}
      </Stack>
    </>
  )
}

export default QrCodePage
function generateArrayFrom1ToX(x) {
  const result = []
  for (let i = 1; i <= x; i++) {
    result.push(i)
  }
  return result
}

function downloadBase64File(base64Data, fileName) {
  const linkSource = base64Data
  const downloadLink = document.createElement("a")
  downloadLink.href = linkSource
  downloadLink.download = fileName + ".png"
  downloadLink.click()
}
