import { useMemo, useState } from "react" // Import useState
import { useTranslation } from "react-i18next"
import Lightbox from "yet-another-react-lightbox"
import "yet-another-react-lightbox/styles.css"
import { ImgPath } from "../../../../components/ImagePath"
import { baseURL } from "../../../../store/axiosFunctions"
import OrangeButton from "../../../../components/Buttons/orangeButton/OrangeButton"
import { useSelector } from "react-redux"

function SpecialSection() {
  const { t } = useTranslation()
  const products = useSelector((state) =>
    state.product.products.filter((product) => product.special)
  )

  const [activeTab, setActiveTab] = useState(0)
  const [open, setOpen] = useState(false)

  const imagesForLightBox = useMemo(() => {
    return products.map((product) => {
      return { src: baseURL + product.images?.[0] }
    })
  }, [products])

  if (!products.length) return <></>
  return (
    <section id="specials" className="specials">
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={imagesForLightBox}
      />
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>{t("specials.title")}</h2>
          <p>{t("specials.checkSpecials")}</p>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay={100}>
          <div className="col-lg-3">
            <ul className="nav nav-tabs flex-column">
              {products.map((product, key) => (
                <li key={key} className="nav-item custom-hover-10">
                  <a
                    className={`nav-link ${activeTab === key && "active"}`}
                    onClick={() => setActiveTab(key)} // Set activeTab when clicked
                  >
                    {product.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-lg-9 mt-4 mt-lg-0">
            <div className="tab-content">
              {products
                .filter((product) => product.special)
                .map((product, key) => (
                  <div
                    key={key}
                    className={`tab-pane ${activeTab === key && "active"}`}
                    id={"tab-" + (key + 1)}
                  >
                    <div className="row">
                      <div className="col-lg-8 details order-2 order-lg-1">
                        <h3>{product.name}</h3>
                        <p className="text-hover-1">{product.description}</p>
                        {!product.available && (
                          <strong>{t("specials.unavailable")}</strong>
                        )}
                      </div>
                      <div className="col-lg-4 text-center order-1 order-lg-2">
                        <ImgPath
                          src={product.image}
                          alt=""
                          className="custom-hover-20 img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <OrangeButton
          data-aos="fade-up"
          onClick={() => setOpen(true)}
          style={{ margin: "auto", marginTop : "1rem" }}
        >
          specials-showcase
        </OrangeButton>
      </div>
    </section>
  )
}

export default SpecialSection
