import { useEffect, useState } from "react"
import { ClothingScriptJs } from "../ClothingScriptJs"
import EditCMS from "../../../components/EditCMS"
import { useSelector } from "react-redux"
import { base64OrPath } from "utils/convertToBase64"
import { Link } from "react-router-dom"
import { t } from "i18next"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, FreeMode, Navigation, Thumbs, Zoom } from "swiper/modules"
export function Hero() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  useEffect(() => {
    ClothingScriptJs()
  }, [])
  const { establishment } = useSelector((state) => state.establishment)
  // return (
  //   <section className="section-slide">
  //     <div className="wrap-slick1">
  //       <div className="slick1">
  //         <Swiper
  //           style={{
  //             "--swiper-navigation-color": "#fff",
  //             "--swiper-pagination-color": "#fff",
  //           }}
  //           // autoplay={{
  //           //   delay: 4000,
  //           //   disableOnInteraction: true,
  //           // }}
  //           zoom={true}
  //           loop={true}
  //           spaceBetween={10}
  //           navigation={true}
  //           thumbs={{ swiper: thumbsSwiper }}
  //           modules={[FreeMode, Navigation, Thumbs, Autoplay, Zoom]}
  //           // className="imageProductCarouselSwiper"
  //         >
  //           {establishment?.gallery.map((image, key) => {
  //             const fieldSubTitle = "hero-subTitle-" + key
  //             const fieldTitle = "hero-title-" + key
  //             return (
  //               <SwiperSlide key={key}>
  //                 <div
  //                   className="item-slick1"
  //                   style={{
  //                     backgroundImage: `url(${base64OrPath(image)})`,
  //                   }}
  //                 >
  //                   <div className="container h-full">
  //                     <div className="flex-col-l-m h-full p-t-100 p-b-30 respon5">
  //                       <div
  //                         className="layer-slick1 animated visible-false"
  //                         data-appear="fadeInDown"
  //                         data-delay={0}
  //                       >
  //                         <span className="ltext-101 cl2 respon2">
  //                           <EditCMS field={fieldSubTitle} />
  //                         </span>
  //                       </div>
  //                       <div
  //                         className="layer-slick1 animated visible-false"
  //                         data-appear="fadeInUp"
  //                         data-delay={800}
  //                       >
  //                         <h2 className="ltext-201 cl2 p-t-19 p-b-43 respon1">
  //                           <EditCMS field={fieldTitle} />
  //                         </h2>
  //                       </div>
  //                       <div
  //                         className="layer-slick1 animated visible-false"
  //                         data-appear="zoomIn"
  //                         data-delay={1600}
  //                       >
  //                         <Link
  //                           to="./shop"
  //                           className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04"
  //                         >
  //                           {t("shop-now")}
  //                         </Link>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </SwiperSlide>
  //             )
  //           })}
  //         </Swiper>
  //       </div>
  //     </div>
  //   </section>
  // )
  return (
    <section className="section-slide">
      <div className="wrap-slick1">
        <div className="slick1">
          {establishment?.gallery.map((image, key) => {
            const fieldSubTitle = "hero-subTitle-" + key
            const fieldTitle = "hero-title-" + key
            return (
              <div
                key={key}
                className="item-slick1"
                style={{
                  backgroundImage: `url(${base64OrPath(image)})`,
                }}
              >
                <div className="container h-full">
                  <div className="flex-col-l-m h-full p-t-100 p-b-30 respon5">
                    <div
                      className="layer-slick1 animated visible-false"
                      data-appear="fadeInDown"
                      data-delay={0}
                    >
                      <span className="ltext-101 cl2 respon2">
                        <EditCMS field={fieldSubTitle} />
                      </span>
                    </div>
                    <div
                      className="layer-slick1 animated visible-false"
                      data-appear="fadeInUp"
                      data-delay={800}
                    >
                      <h2 className="ltext-201 cl2 p-t-19 p-b-43 respon1">
                        <EditCMS field={fieldTitle} />
                      </h2>
                    </div>
                    <div
                      className="layer-slick1 animated visible-false"
                      data-appear="zoomIn"
                      data-delay={1600}
                    >
                      <Link
                        to="./shop"
                        className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04"
                      >
                        {t("shop-now")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
          {/* <div
              className="item-slick1"
              style={{
                backgroundImage: carouselImage[selectedImages[1]],
              }}
            >
              <div className="container h-full">
                <div className="flex-col-l-m h-full p-t-100 p-b-30 respon5">
                  <div
                    className="layer-slick1 animated visible-false"
                    data-appear="rollIn"
                    data-delay={0}
                  >
                    <span className="ltext-101 cl2 respon2">Men New-Season</span>
                  </div>
                  <div
                    className="layer-slick1 animated visible-false"
                    data-appear="lightSpeedIn"
                    data-delay={800}
                  >
                    <h2 className="ltext-201 cl2 p-t-19 p-b-43 respon1">
                      Jackets &amp; Coats
                    </h2>
                  </div>
                  <div
                    className="layer-slick1 animated visible-false"
                    data-appear="slideInUp"
                    data-delay={1600}
                  >
                    <a
                      href="product.html"
                      className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04"
                    >
                      Shop Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="item-slick1"
              style={{
                backgroundImage: carouselImage[selectedImages[2]],
              }}
            >
              <div className="container h-full">
                <div className="flex-col-l-m h-full p-t-100 p-b-30 respon5">
                  <div
                    className="layer-slick1 animated visible-false"
                    data-appear="rotateInDownLeft"
                    data-delay={0}
                  >
                    <span className="ltext-101 cl2 respon2">
                      Men Collection 2018
                    </span>
                  </div>
                  <div
                    className="layer-slick1 animated visible-false"
                    data-appear="rotateInUpRight"
                    data-delay={800}
                  >
                    <h2 className="ltext-201 cl2 p-t-19 p-b-43 respon1">
                      New arrivals
                    </h2>
                  </div>
                  <div
                    className="layer-slick1 animated visible-false"
                    data-appear="rotateIn"
                    data-delay={1600}
                  >
                    <a
                      href="product.html"
                      className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04"
                    >
                      Shop Now
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
        </div>
      </div>
    </section>
  )
}
