// third-party
import { applyMiddleware, configureStore, isAsyncThunkAction } from '@reduxjs/toolkit'


import thunk from "redux-thunk";

// project import
import reducers from './reducers'

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({
  reducer: reducers
}, applyMiddleware(thunk))

const { dispatch } = store

export { dispatch, store}

