import CancelIcon from "@mui/icons-material/Cancel"
import { Chip } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import CRUDTable from "../../../components/crudTable/CRUDTable"
import { setSelectedRow } from "../../../store/actions/misc"
import {
  deleteEcommerceOrder,
  setEcommerceOrders,
  updateEcommerceOrder,
} from "../../../store/reducers/ecommerceOrder"
import {
  CheckCircleIcon,
  LocalShippingIcon,
  PrintIcon,
} from "../../../utils/MuiIcons"
import OrderDetails from "../../clothSite/components/OrderDetails"
import exportPDF from "../reservation/exportPDF"
import { tradSentence } from "../../../utils/tradSentence"
import dayjs from "dayjs"
import { useEffect, useMemo, useState } from "react"
import { formatPhoneNumberWithSpaces } from "../../../utils/formatText"
import { generateQR } from "../../../utils/qrCode"
import { moneyCurrency } from "../../../config"
const EcommerceOrderPage = () => {
  const { ecommerceOrders } = useSelector((state) => state.ecommerceOrder)
  const { establishment } = useSelector((state) => state.establishment)
  const { t } = useTranslation()
  const [selectedTable, setSelectedTable] = useState(null)

  useEffect(() => {
    ;(async () => {
      if (selectedTable) {
        const { number, buyerData, price } = selectedTable
        exportPDF({
          text: [
            "Sous total: " + price + moneyCurrency,
            "Frais de livraison: " + establishment.deliveryFee + moneyCurrency,
            "Prix total: " +
              (price + establishment.deliveryFee) +
              moneyCurrency,
          ],

          fileTitle: tradSentence(number),
          title: [
            dayjs().format("LLL"),
            `Numéro de commande: ${number}`,
            "Adresse de facturation:",
            `${buyerData.firstName} ${buyerData.lastName}`,
            `${buyerData.postalCode} ${buyerData.address}`,
            formatPhoneNumberWithSpaces(buyerData.phoneNumber),
            buyerData.email,
          ],
          image: await generateQR(
            `${window.location.origin}/establishment/${establishment._id}`
          ),
          html: "#myTab",
        })
        setSelectedTable(null)
      }
    })()
  }, [selectedTable])

  const columns = [
    {
      accessorKey: "price",
      filterVariant: "range",
      filterFn: "between",
      size: 100,
      currency: true,
    },
    {
      accessorKey: "status",
      Cell: ({ cell }) => {
        const status = cell.getValue("status")
        var customColor = null

        const statusColors = {
          canceled: "error",
          // confirmed: "purple",
          waiting: "info",
          delivering: "warning",
          delivered: "success",
        }
        var color = statusColors[status] || "primary"

        return (
          <Chip label={t(status)} sx={{ bgcolor: customColor }} color={color} />
        )
      },
    },
  ]

  return (
    <div>
      {selectedTable && (
        <table style={{ display: "none" }} id="myTab">
          <thead>
            <tr>
              <th>Image</th>
              <th>Produit</th>
              <th>Quantité</th>
              <th>Couleur</th>
              <th>Taille</th>
              <th>Prix</th>
            </tr>
          </thead>
          <tbody>
            {selectedTable.cart.map((cart, key) => {
              const { images, name, quantity, color, size, price } = cart
              return (
                <tr key={key}>
                  <td>
                    <img src={images[0]} alt="Image" />
                  </td>
                  <td>{name}</td>
                  <td>{quantity}</td>
                  <td>{color}</td>
                  <td>{size}</td>
                  <td>{price}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
      <CRUDTable
        allowMobileUI={true}
        displayData={true}
        columns={columns}
        rows={ecommerceOrders}
        fetchFn={setEcommerceOrders}
        deleteFn={deleteEcommerceOrder}
        enableIntervalPicker
        removedFieldConfig={["sizes", "colors", "productInCartId", "extra"]}
        addedActionMap={[
          {
            Icon: () => PrintIcon,
            title: () => "print",
            actionFn: (row) => setSelectedTable(row),
            color: () => "cyan",
            confirm: false,
          },
          {
            condition: (row) =>
              row?.status !== "delivered" && row?.status !== "canceled",
            Icon: () => CancelIcon,
            title: () => "cancel",
            actionFn: (row) =>
              updateEcommerceOrder(row._id, { status: "canceled" }),
            color: () => "red",
            confirm: false,
          },
          {
            condition: (row) =>
              row?.status !== "delivered" && row?.status !== "confirmed",
            Icon: () => CheckCircleIcon,
            title: () => "confirm",
            actionFn: (row) =>
              updateEcommerceOrder(row._id, { status: "confirmed" }),
            color: () => "purple",
            confirm: false,
          },
          {
            condition: (row) => row?.status === "confirmed",
            title: () => "delivered",
            Icon: () => LocalShippingIcon,
            actionFn: (row) =>
              updateEcommerceOrder(row._id, { status: "delivered" }),
            color: () => "green",
            confirm: false,
          },
        ]}
        rowStyle={(row) => {
          {
            bgcolor: row.status === "canceled" ? "#FFCCCC" : "inherit"
          }
        }}
        DisplayDataMapElement={<OrderDetails />}
      />
    </div>
  )
}

export default EcommerceOrderPage
