import { dispatch } from ".."
import { AUTH } from "../actionTypes"
import * as API from "../axiosFunctions"

export const signIn = async (formData) => {
  const data = await API.signIn(formData)
  dispatch({
    type: AUTH,
    payload: data,
  })
}
export const resetPassword = async (formData) => {
  try {
    await API.resetPassword(formData)
  } catch (error) {
    console.log(error)
  }
}
export const updateSelfUser = async (formData) => {
  try {
    const authData = JSON.parse(localStorage.getItem("localUserProfile")) || {}

    const data = await API.updateSelfUser(formData)
    dispatch({ type: AUTH, payload: { ...authData, ...data } })
  } catch (error) {
    return  Promise.reject(error)
  }
}
export const logout = () => {
  dispatch({ type: actionType.SET_ORDERS, payload: [] })
  dispatch({ type: "LOGOUT" })
}
