{
  /* //! -------------- Blue ------------------ */
}
const darkBlueGradient = "linear-gradient(to right, #00395d, #8f8f8c)"
const darkBlueGradient2 =
  "linear-gradient(rgba(34, 42, 69, 0.96), rgba(34, 42, 69, 0.96))"
const darkBlueGradientLight =
  "linear-gradient(rgba(34, 42, 69, 0.90), rgba(34, 42, 69, 0.90))"
const blueGradient = "linear-gradient(135deg, #00b0ff, #0063b5)"

{
  /* //! -------------- Green ----------------- */
}
const greenGradient = "linear-gradient(to top, #0ba360 0%, #3cba92 100%)"
const lightGreenGradient = "linear-gradient(to right, #8CC63F, #B1D96B)"
const darkGreenGradient = "linear-gradient(to right, #006400, #003300)"

{
  /* //! --------------- Red ------------------ */
}
const redGradient = "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)"
const orangeGradient = "linear-gradient(120deg, #ff6600 0%, #ff9933 100%)"
{
  /* //! ---------- White & Black ------------- */
}
const grayGradient = "linear-gradient(to right, #888, #ddd)"

{
  /* //! ------------- Purple ----------------- */
}
const purpleRedGradient = "linear-gradient(120deg, #a6c0fe 0%, #f68084 100%)"
const purpleGradient = "linear-gradient(to bottom, #8e44ad, #5d2c91)"

export {
  darkBlueGradient,
  darkBlueGradient2,
  blueGradient,
  greenGradient,
  darkGreenGradient,
  redGradient,
  purpleRedGradient,
  orangeGradient,
  grayGradient,
  purpleGradient,
  lightGreenGradient,
  darkBlueGradientLight,
}
