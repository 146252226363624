import { createSlice } from "@reduxjs/toolkit"
import { axiosAPI } from "../axiosFunctions"
import { dispatch } from ".."

const initialState = {
  categories: [],
}

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategoriesReducer(state, action) {
      state.categories = action.payload
    },
    addCategoryReducer(state, action) {
      state.categories.unshift(action.payload)
    },

    updateCategoryReducer(state, action) {
      state.categories = state.categories.map((document) =>
        document._id === action.payload._id ? action.payload : document
      )
    },

    deleteCategoryReducer(state, action) {
      state.categories = state.categories.filter(
        (document) => document._id !== action.payload
      )
    },
  },
})

const {
  setCategoriesReducer,
  addCategoryReducer,
  updateCategoryReducer,
  deleteCategoryReducer,
} = categorySlice.actions

export const setCategories = (query) => {
  axiosAPI
    .get("/category/", { params: query })
    .then((data) => dispatch(setCategoriesReducer(data)))
}

export const addCategory = (body) => {
  axiosAPI
    .post("/category/", body)
    .then((data) => dispatch(addCategoryReducer(data)))
}

export const updateCategory = (id, body) => {
  axiosAPI
    .patch(`/category/${id}`, body)
    .then((data) => dispatch(updateCategoryReducer(data)))
}

export const deleteCategory = (id) => {
  axiosAPI
    .delete(`/category/${id}`)
    .then((_) => dispatch(deleteCategoryReducer(id)))
}

export default categorySlice.reducer
