import { useSelector } from "react-redux"
import CRUDTable from "../../../components/crudTable/CRUDTable"
import {
  addBlog,
  deleteBlog,
  setBlogs,
  updateBlog,
} from "../../../store/reducers/blog.js"
import { establishmentSiteModel } from "../../../utils/siteModelUtilities"
function BlogPage() {
  const { blogs } = useSelector((state) => state.blog)

  const blogColumns = {
    clothing: [
      {
        accessorKey: "title",
        required: true,
      },
      {
        accessorKey: "description",
        type: "customText",
        required: true,
        hiddenColumn: true,
      },
      {
        accessorKey: "text",
        type: "customText",
        required: true,
        hiddenColumn: true,
      },
      {
        accessorKey: "image",
        type: "images",
        required: true,
      },
    ],
  }

  return (
    <CRUDTable
      tableName="blog"
      columns={blogColumns[establishmentSiteModel()]}
      rows={blogs}
      fetchFn={setBlogs}
      createFn={addBlog}
      updateFn={updateBlog}
      deleteFn={deleteBlog}
    />
  )
}

export default BlogPage
