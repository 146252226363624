import { useTranslation } from "react-i18next"; // Import the translation hook
import { ImgPath } from "../../../../components/ImagePath";
import { useSelector } from "react-redux"

function GallerySection() {
  const { establishment } = useSelector((state) => state.establishment)
  const { t } = useTranslation(); // Use the translation hook

  return (
    <section id="gallery" className="gallery">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>{t("galleryPage.title")}</h2> {/* Translate the title */}
          <p>{t("galleryPage.photosDescription")}</p>{" "}
          {/* Translate the description */}
        </div>
      </div>
      <div className="container-fluid" data-aos="fade-up" data-aos-delay={100}>
        <div className="row g-0">
          {establishment?.gallery?.map((image, key) => (
            <div key={key} className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="gallery-item">
                <a className="gallery-lightbox" data-gall="gallery-item">
                  <ImgPath width={"100%"} src={image} alt="" className="img-fluid" />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default GallerySection;
