import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"

const navigationMap = {
  SUPERVISOR: "/dashboard/establishments",
  Gérant: "/dashboard/default",
  Serveur: "/dashboard/order",
}

function ProtectedRoutes({ roles, status, children, siteModel }) {
  const { authData } = useSelector((state) => state.auth)
  const { establishment } = useSelector((state) => state.establishment)
  const userRole = authData?.role
  const destination = navigationMap[userRole]
  const hasAccess = roles ? roles.includes(userRole) : true

  if (!authData && status === "CONNECTED") return <Navigate to="/auth/login" />

  if (
    siteModel &&
    establishment?.siteModel &&
    !siteModel.includes(establishment?.siteModel)
  )
    return <Navigate to="/auth/login" />

  if ((authData && status === "NOT_CONNECTED") || !hasAccess)
    return <Navigate to={destination} />

  return children
}

export default ProtectedRoutes
