import CancelIcon from "@mui/icons-material/Cancel"
import { Chip, Paper } from "@mui/material"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import CRUDTable from "../../../components/crudTable/CRUDTable"
import { setEcommerceOrdersClient, updateEcommerceOrderClient } from "../../../store/reducers/ecommerceOrder"
import OrderDetails from "../components/OrderDetails"
function EcommerceClientOrdersPage() {
  const { token } = useParams()
  useEffect(() => {
    // Insert Token
    if (token) {
      localStorage.setItem("clientEcommerceOrderToken", token)
      setEcommerceOrdersClient()
    }
  }, [])

  const { ecommerceOrders } = useSelector((state) => state.ecommerceOrder)

  const { t } = useTranslation()

  const columns = [
    {
      accessorKey: "price",
      filterVariant: "range",
      filterFn: "between",
      size: 100,
      currency: true,
    },
    {
      accessorKey: "status",
      Cell: ({ cell }) => {
        const status = cell.getValue("status")
        var customColor = null

        const statusColors = {
          canceled: "error",
          // confirmed: "purple",
          waiting: "info",
          delivering: "warning",
          delivered: "success",
        }
        var color = statusColors[status] || "primary"

        return (
          <Chip label={t(status)} sx={{ bgcolor: customColor }} color={color} />
        )
      },
    },
  ]

  return (
    <Paper sx={{ p: 2, minHeight: "90vh" }}>
      <h2 style={{ margin: "2rem 0" }}>{t("yourOrder")}</h2>
      <CRUDTable
        allowMobileUI={true}
        columns={columns}
        rows={ecommerceOrders}
        enableIntervalPicker
        removedFieldConfig={["sizes", "colors", "productInCartId", "extra"]}
        addedActionMap={[
          {
            condition: (row) =>
              row?.status !== "delivered" && row?.status !== "canceled",
            Icon: () => CancelIcon,
            title: () => "cancel",
            actionFn: (row) =>
              updateEcommerceOrderClient(row._id, { status: "canceled" }),
            color: () => "red",
          },
        ]}
        rowStyle={(row) => {
          {
            bgcolor: row.status === "canceled" ? "#FFCCCC" : "inherit"
          }
        }}
        DisplayDataMapElement={<OrderDetails />}
      />
    </Paper>
  )
}

export default EcommerceClientOrdersPage
